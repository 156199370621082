/* eslint-disable max-len */
import React from 'react';

const VehicleIcon = () => {
  return (
    <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 10.9086H8.75V13.0904H0V10.9086Z" />
      <path d="M2.18735 6.5453H8.74988V8.7271H2.18735V6.5453Z" />
      <path d="M28 11.8229L25.5073 4.36365H19.25V0H2.18735V4.36365H4.37481V2.18186H17.0621V13.0908H25.8121V19.6363H23.4369C22.9853 18.3667 21.7697 17.4545 20.3434 17.4545C18.9171 17.4545 17.702 18.3668 17.2499 19.6363H8.56194C8.11038 18.3667 6.89474 17.4545 5.46848 17.4545C5.08514 17.4545 4.71714 17.5208 4.37474 17.6418V15.2727H2.18728V20.7272C2.18728 22.532 3.65968 24 5.46854 24C6.89434 24 8.10994 23.0878 8.56201 21.8182H17.2507C17.7022 23.0878 18.9179 24 20.3441 24C21.7704 24 22.9855 23.0878 23.4376 21.8182H27.9996L28 11.8229ZM5.46868 21.8182C4.86555 21.8182 4.37494 21.3289 4.37494 20.7274C4.37494 20.1258 4.86557 19.6365 5.46868 19.6365C6.07178 19.6365 6.56241 20.1258 6.56241 20.7274C6.56241 21.3289 6.07178 21.8182 5.46868 21.8182ZM20.3433 21.8182C19.7402 21.8182 19.2496 21.3289 19.2496 20.7274C19.2496 20.1258 19.7402 19.6365 20.3433 19.6365C20.9464 19.6365 21.4371 20.1258 21.4371 20.7274C21.4371 21.3289 20.9464 21.8182 20.3433 21.8182ZM19.2496 6.54531H23.9298L25.3881 10.909H19.2496V6.54531Z" />
    </svg>
  );
};

export default VehicleIcon;
