import React from 'react';
import { FaRegClipboard } from 'react-icons/fa';

import AgencyIcon from '../../assets/svg/menu/AgencyIcon';
import CashManagementIcon from '../../assets/svg/menu/CashManagementIcon';
import CatalogIcon from '../../assets/svg/menu/CatalogIcon';
import CustomerIcon from '../../assets/svg/menu/CustomerIcon';
import DashboardIcon from '../../assets/svg/menu/DashboardIcon';
import DriverIcon from '../../assets/svg/menu/DriverIcon';
import FeedbackIcon from '../../assets/svg/menu/FeedbackIcon';
import InboundOrdersIcon from '../../assets/svg/menu/InboundOrdersIcon';
import OutboundOrdersIcon from '../../assets/svg/menu/OutboundOrdersIcon';
import PickupManagementIcon from '../../assets/svg/menu/PickupManagementIcon';
import RouteIcon from '../../assets/svg/menu/RouteIcon';
import TripsIcon from '../../assets/svg/menu/TripsIcon';
import UsersIcon from '../../assets/svg/menu/UsersIcon';
import VehicleIcon from '../../assets/svg/menu/VehicleIcon';
import WardsIcon from '../../assets/svg/menu/WardsIcon';
import { AUTHORITY } from '../../shared/constants/Constants';

const nav = [
  {
    title: 'Dashboard',
    icon: <DashboardIcon />,
    url: '/',
  },
  {
    title: 'Customers',
    icon: <CustomerIcon />,
    url: '/customers',
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    title: 'Inbound Orders',
    icon: <InboundOrdersIcon />,
    url: '/orders/inbound',
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    title: 'Outbound Orders',
    icon: <OutboundOrdersIcon />,
    url: '/orders/outbound',
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    title: 'Catalog',
    icon: <CatalogIcon />,
    url: '/catalog',
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    title: 'Pickup Management',
    icon: <PickupManagementIcon />,
    url: '/pickup-management',
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    title: 'Reports',
    icon: <FaRegClipboard />,
    url: '/reports',
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    title: 'Customers',
    icon: <CustomerIcon />,
    url: '/bulk-waste-generator',
    authorities: [
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.FINANCE_MANAGER,
      AUTHORITY.BWG_OPERATION_MANAGER,
      AUTHORITY.AGENCY_ADMIN,
    ],
  },
  {
    title: 'Agency Management',
    icon: <AgencyIcon />,
    url: '/agency-management',
    authorities: [
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.FINANCE_MANAGER,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
  {
    title: 'Wards',
    icon: <WardsIcon />,
    url: '/ward-management',
    authorities: [
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.AGENCY_ADMIN,
      AUTHORITY.FINANCE_MANAGER,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
  {
    title: 'Drivers',
    icon: <DriverIcon />,
    url: '/drivers-management',
    authorities: [
      AUTHORITY.AGENCY_ADMIN,
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
  {
    title: 'Vehicles',
    icon: <VehicleIcon />,
    url: '/vehicles-management',
    authorities: [
      AUTHORITY.AGENCY_ADMIN,
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
  {
    title: 'Route',
    icon: <RouteIcon />,
    url: '/route',
    authorities: [
      AUTHORITY.AGENCY_ADMIN,
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
  {
    title: 'Trips',
    icon: <TripsIcon />,
    url: '/trips',
    authorities: [
      AUTHORITY.AGENCY_ADMIN,
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
  // {
  //   title: 'Fee Management',
  //   icon: <FeeManagementIcon />,
  //   url: '/bwg-cash-management',
  //   authorities: [
  //     AUTHORITY.MUNICIPAL_ADMIN,
  //     AUTHORITY.FINANCE_MANAGER,
  //     AUTHORITY.BWG_OPERATION_MANAGER,
  //   ],
  // },
  {
    title: 'Reports',
    icon: <FaRegClipboard />,
    url: '/bwg-reports',
    authorities: [
      AUTHORITY.AGENCY_ADMIN,
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
  {
    title: 'Feedback',
    icon: <FeedbackIcon />,
    url: '/complaints',
    authorities: [
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.BWG_OPERATION_MANAGER,
      AUTHORITY.AGENCY_ADMIN,
    ],
  },
  {
    title: 'User Management',
    icon: <UsersIcon />,
    url: '/users-management',
    noAuthorities: [AUTHORITY.FINANCE_MANAGER],
    authorities: [
      AUTHORITY.FRANCHISE_USER,
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
  {
    title: 'Cash Management',
    icon: <CashManagementIcon />,
    url: '/cash-management',
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
];

export default nav;
