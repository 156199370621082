import { flatMap } from 'lodash';
import { useSelector } from 'react-redux';

export default function useCatalog() {
  const { items, categories } = useSelector(({ catalog }) => catalog);
  return {
    items,
    categories,
    itemsFlat: flatMap(items),
    categoriesFlat: flatMap(categories),
  };
}
