import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';

const initialState = {
  items: null,
  categories: null,
};

const CatalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = keyBy(action.payload, 'id');
    },
    setCategories: (state, action) => {
      state.categories = keyBy(action.payload, 'id');
    },
  },
});
export const catalogActions = CatalogSlice.actions;
export default CatalogSlice.reducer;
