import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accountUsers: null,
};

const AccountUserSlice = createSlice({
  name: 'accountUser',
  initialState,
  reducers: {
    setAccountUserList: (state, action) => {
      return { accountUsers: action.payload };
    },
  },
});
export const accountsUserActions = AccountUserSlice.actions;
export default AccountUserSlice.reducer;
