/* eslint-disable max-len */
import React from 'react';

const FeedbackIcon = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">
      <path
        d="M33.9,8.3h-5.7V5.4H31c0.5,0,1-0.3,1.2-0.7c0.3-0.4,0.3-1,0-1.4C32,2.9,31.5,2.6,31,2.6h-4.3
	c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1v4.3H11.1c-2.3,0-4.4,0.9-6,2.5s-2.5,3.8-2.5,6v12.8c0,0.4,0.1,0.7,0.4,1S3.7,31,4,31
	h15.6V41c0,0.4,0.1,0.7,0.4,1s0.6,0.4,1,0.4h5.7c0.4,0,0.7-0.1,1-0.4s0.4-0.6,0.4-1V31H41c0.4,0,0.7-0.1,1-0.4s0.4-0.6,0.4-1V16.8
	c0-2.3-0.9-4.4-2.5-6S36.1,8.3,33.9,8.3L33.9,8.3z M16.8,28.2H5.4V16.8c0-2,1.1-3.9,2.8-4.9s3.9-1,5.7,0s2.8,2.9,2.8,4.9V28.2z
	 M25.3,39.6h-2.8V31h2.8V39.6z M39.6,28.2H19.7V16.8c0-2.1-0.8-4.1-2.2-5.7h7.9v4.3c0,0.5,0.3,1,0.7,1.2c0.4,0.3,1,0.3,1.4,0
	s0.7-0.7,0.7-1.2v-4.3h5.7c1.5,0,3,0.6,4,1.7c1.1,1.1,1.7,2.5,1.7,4V28.2z"
      />
    </svg>
  );
};

export default FeedbackIcon;
