import { ApiEndpoints, FilterParamMap } from '../../../../shared/constants';
import { transformParams, transformResponse } from '../../../../shared/utils/Helper';
import { backendApi } from '../../base.config';

export const wardsApi = backendApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getWards: builder.query({
      query: (_params) => ({
        url: ApiEndpoints.BWG.WARDS,
        _params,
      }),
      providesTags: ['wards'],
    }),
    getRouteWards: builder.query({
      query: (params) => ({
        url: ApiEndpoints.BWG.ROUTE_WARDS,
        params,
      }),
      transformResponse,
    }),
  }),
});

export const { useGetWardsQuery, useLazyGetWardsQuery, useLazyGetRouteWardsQuery } = wardsApi;
