/* eslint-disable max-len */
import React from 'react';

const WardsIcon = () => {
  return (
    <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.9785 26.0052L20.7861 14.04C20.7014 13.699 20.3906 13.4431 20.0233 13.4431H12.3673C13.8081 10.6863 15.0229 7.87258 15.0229 6.30944C15.0229 2.84209 12.2261 0 8.75126 0C5.27647 0 2.47959 2.81372 2.47959 6.30944C2.47959 7.87263 3.69427 10.6861 5.13522 13.4431H4.00529C3.63797 13.4431 3.32722 13.6988 3.24246 14.04L0.0216745 26.0052C-0.0348825 26.2326 0.0216747 26.4883 0.162969 26.6874C0.304263 26.8864 0.55847 27 0.784486 27H23.2156C23.4698 27 23.6958 26.8864 23.8371 26.6874C23.9784 26.4885 24.0348 26.2326 23.9784 26.0052H23.9785ZM8.75132 1.59167C11.3222 1.59167 13.441 3.69487 13.441 6.30959C13.441 7.73063 11.859 11.3401 9.17518 16.0011C9.06226 16.2285 8.83623 16.2569 8.7515 16.2569C8.66676 16.2569 8.46891 16.2285 8.32782 16.0011C5.67219 11.3401 4.062 7.73063 4.062 6.30959C4.0616 3.69487 6.1804 1.59167 8.75128 1.59167H8.75132ZM4.59845 15.0347H5.98279C6.32174 15.6317 6.63251 16.2284 6.97167 16.7684C7.33899 17.4221 8.0169 17.8199 8.75152 17.8199C9.48599 17.8199 10.1641 17.4221 10.5314 16.7684C10.8421 16.2001 11.1811 15.6317 11.5202 15.0347H13.6391L11.492 20.4347L3.07331 20.8042L4.59845 15.0347ZM2.62075 22.3958L12.0565 21.9979C12.3673 21.9979 12.6499 21.7988 12.7628 21.4864L13.3843 19.9232L15.7291 21.259L16.4072 25.38L1.82977 25.3798L2.62075 22.3958ZM18.0174 25.4083L17.2264 20.662C17.1982 20.4346 17.0569 20.2073 16.8309 20.0937L13.9775 18.4453L15.3335 15.0348H19.4016L22.1703 25.4084L18.0174 25.4083Z" />
      <path d="M11.7741 6.30954C11.7741 4.63276 10.4182 3.2684 8.7512 3.2684C7.08446 3.2684 5.72827 4.63255 5.72827 6.30954C5.72827 7.98632 7.08426 9.35067 8.7512 9.35067C10.4182 9.35048 11.7741 7.98632 11.7741 6.30954ZM7.31052 6.30954C7.31052 5.51375 7.96023 4.86012 8.75126 4.86012C9.54228 4.86012 10.192 5.51375 10.192 6.30954C10.192 7.10532 9.54228 7.75895 8.75126 7.75895C7.96044 7.7306 7.31052 7.10532 7.31052 6.30954Z" />
    </svg>
  );
};

export default WardsIcon;
