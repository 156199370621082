/* eslint-disable max-len */
import React from 'react';

const InboundOrdersIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 23" xmlSpace="preserve">
      <g>
        <path d="M10.6,22.7l-7-15.2l-1.6,3.9l-1.8-0.8l2.5-6l1.3-0.5l6.5,2.5L9.6,8.4L5.4,6.8L11,18.9l6.8-8.5l8,10.9l-1.6,1.2l-6.4-8.8L10.6,22.7z M26,2H0V0h26V2z" />
      </g>
    </svg>
  );
};

export default InboundOrdersIcon;
