import { RiMenuFoldLine } from '@react-icons/all-files/ri/RiMenuFoldLine';
import { RiMenuUnfoldLine } from '@react-icons/all-files/ri/RiMenuUnfoldLine';
import React, { useRef, useState } from 'react';
import {
  Button,
  Col,
  Image,
  ListGroup,
  Navbar,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import { FaCaretDown, FaSignOutAlt, FaUnlockAlt, FaUser } from 'react-icons/fa';

import Logo from '../../assets/img/logo.png';
import { useAccount } from '../../hooks/hooks';
import { PromptModal } from '../../shared/components';
import { CanShow } from '../../shared/components/GenericComponent';
import UserChangePassword from '../User/UserChangePassword';
import UserProfileModal from '../User/UserProfileModal';

const AppHeader = ({ handleToggleSidebar, collapsed }) => {
  const [showProfile, setShowProfile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { accountDetails, logout } = useAccount();
  const [showConfirmationSignOut, setShowConfirmationSignOut] = useState(false);
  const target = useRef(null);

  return (
    <Navbar className="AppHeader" fixed="top" expand="lg">
      <Row style={{ width: '103%' }}>
        <Col>
          <div className="vertical-center" style={{ left: '2%' }}>
            <Button
              variant="link"
              className="pl-0 text-dark float-left pr-3"
              onClick={handleToggleSidebar}>
              {collapsed ? (
                <RiMenuUnfoldLine className="text-xlarge" />
              ) : (
                <RiMenuFoldLine className="text-xlarge" />
              )}
            </Button>
            <a href="/">
              <Image src={Logo} className="float-left app-logo" alt="Company Logo" />
            </a>
            <Navbar.Brand href="#home" className="float-left mt-2 app-name">
              <div className="business-name float-left font-regular" style={{ color: '#707070' }} />
            </Navbar.Brand>
          </div>
        </Col>
        <Col>
          <OverlayTrigger
            trigger="click"
            placement="bottom-end"
            rootClose
            target={target.current}
            overlay={
              <Popover id="popover-contained">
                <Popover.Content>
                  <ListGroup variant="flush" defaultActiveKey="#link1">
                    <ListGroup.Item
                      onClick={() => {
                        document.body.click();
                        setShowProfile(true);
                      }}
                      className="text-regular"
                      action>
                      <FaUser className="mr-2" />
                      Profile
                    </ListGroup.Item>
                    <ListGroup.Item
                      onClick={() => {
                        document.body.click();
                        setShowPassword(true);
                      }}
                      className="text-regular"
                      action>
                      <FaUnlockAlt className="mr-2" />
                      Change Password
                    </ListGroup.Item>
                    <ListGroup.Item
                      onClick={() => {
                        document.body.click();
                        setShowConfirmationSignOut(true);
                      }}
                      className="text-regular"
                      action>
                      <FaSignOutAlt className="mr-2" />
                      Sign Out
                    </ListGroup.Item>
                  </ListGroup>
                </Popover.Content>
              </Popover>
            }>
            <span className="pointer font-semibold  vertical-center" style={{ right: '1.5%' }}>
              {accountDetails?.firstName}
              <FaCaretDown className="ml-2" />
            </span>
          </OverlayTrigger>
        </Col>
      </Row>
      <CanShow show={showProfile}>
        <UserProfileModal show={showProfile} setShow={setShowProfile} account={accountDetails} />
      </CanShow>
      <CanShow show={showPassword}>
        <UserChangePassword
          show={showPassword}
          setShow={setShowPassword}
          account={accountDetails}
        />
      </CanShow>
      <CanShow show={showConfirmationSignOut}>
        <PromptModal
          title="Confirm Logout"
          text="Are you sure you want to logout?"
          dismissBtnText="Cancel"
          successBtnText="Confirm"
          successBtnClass="btn-primary"
          onSuccess={async () => {
            await logout();
            setShowConfirmationSignOut(false);
          }}
          show={showConfirmationSignOut}
          onDismiss={() => setShowConfirmationSignOut(false)}
        />
      </CanShow>
    </Navbar>
  );
};

AppHeader.propTypes = {};

export default AppHeader;
