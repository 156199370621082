import './UserProfileModal.scss';

import { useFormik } from 'formik';
import React from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useAccount, useAccountPassword } from '../../hooks/hooks';
import { FormField } from '../../shared/components';
import ButtonLoading from '../../shared/components/Buttons/ButtonLoading';
import { hasError } from '../../shared/utils/Helper';

const UserProfileModal = (props) => {
  const { show, setShow } = { ...props };
  const [{ loading: passLoading }, { doUpdateAccount }] = useAccountPassword();
  const { accountDetails, loading, reFetchAccount } = useAccount();

  const formik = useFormik({
    initialValues: {
      name: accountDetails?.name || '',
      mobile: accountDetails?.mobile || '',
      email: accountDetails?.email || '',
    },
    validationSchema: () => {
      return Yup.lazy((values) => {
        return Yup.object().shape({
          name: Yup.string().required('Name is required.'),
        });
      });
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => saveUser(values),
  });

  const saveUser = async (values) => {
    const res = await doUpdateAccount(values);
    const error = hasError(res);
    if (error) {
      toast.error(error.message);
      return;
    }
    closeModal();
    toast.success('Account details updated successfully.');
    reFetchAccount();
  };

  const closeModal = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      onExit={() => formik.resetForm()}
      backdrop="static"
      size="md"
      centered>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form noValidate>
          <Form.Row>
            <Form.Group as={Col} md={12}>
              <FormField
                required
                label="Name"
                placeholder="Enter Name"
                name="name"
                formik={formik}
                {...formik.getFieldProps('name')}
              />
              <Form.Control.Feedback type="invalid">
                {formik?.touched?.name && formik?.errors?.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md={12}>
              <FormField
                required
                label="Mobile Number"
                placeholder="Enter Mobile Number"
                name="mobile"
                formik={formik}
                {...formik.getFieldProps('mobile')}
                disabled
              />
              <Form.Control.Feedback type="invalid">
                {formik?.touched?.mobile && formik?.errors?.mobile}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md={12}>
              <FormField
                required
                label="Email"
                placeholder="Email"
                name="email"
                formik={formik}
                {...formik.getFieldProps('email')}
                disabled
              />
              <Form.Control.Feedback type="invalid">
                {formik?.touched?.email && formik?.errors?.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="pr-5 pl-5 mr-3 cancel-button" onClick={closeModal}>
          Cancel
        </Button>
        <ButtonLoading
          type="submit"
          disabled={!formik?.isValid || !formik?.dirty}
          onClick={formik.handleSubmit}
          style={{ padding: '5px 50px 5px 50px' }}
          loading={passLoading}>
          Save
        </ButtonLoading>
      </Modal.Footer>
    </Modal>
  );
};

export default UserProfileModal;
