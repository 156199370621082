import { createSlice } from '@reduxjs/toolkit';
import { wardsApi } from './wards.api';

export const wardSlice = createSlice({
  name: 'wards',
  initialState: {
    wards: [],
  },
  extraReducers: (builder) => {
    builder.addMatcher(wardsApi.endpoints.getWards.matchFulfilled, (state, action) => {
      state.wards = action.payload;
    });
  },
});
