/* eslint-disable no-unneeded-ternary */
import './features/AppLayout/_AppLayout.scss';

import React, { Suspense, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route } from 'react-router-dom';

import AppHeader from './features/AppLayout/AppHeader';
import AppSidebar from './features/AppLayout/AppSidebar';
import MainContent from './features/AppLayout/MainContent';
import { useStakeholders } from './hooks';
import useAuthCheck from './hooks/useAuthCheck';
import useBwgUser from './hooks/useBwgUser';
import useResponsive from './hooks/useResponsive';
import AppAnalytics from './services/AppAnalytics';
import { Loader } from './shared/components';

const LoginPage = React.lazy(() => import('./views/LoginPage/LoginPage'));
const ResetPasswordPage = React.lazy(() => import('./views/ResetPasswordPage/ResetPasswordPage'));

function App() {
  const { isBwgUser } = useBwgUser();
  const flag = isBwgUser ? false : true;
  const [collapsed, setCollapsed] = useState(flag);
  const [toggled, setToggled] = useState(false);
  const { accountDetails, token, loading } = useAuthCheck();
  const { isMobile } = useResponsive();

  useEffect(() => {
    AppAnalytics.initialize();
  }, []);

  useEffect(() => {
    if (isBwgUser) {
      setCollapsed(false);
    }
  }, [isBwgUser]);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleToggleSidebar = (value) => {
    if (!collapsed && isMobile) {
      setToggled(false);
      setCollapsed(!collapsed);
    } else {
      setToggled(value);
      setCollapsed(!collapsed);
    }
  };

  if (!token) {
    return (
      <Suspense fallback={<Loader />}>
        <Container className="h-100">
          <Route name="Login" path="/login" component={LoginPage} />
          <Route
            name="Login"
            path="/reset-password"
            exact
            render={(props) => {
              return token ? <Redirect to="/" /> : <ResetPasswordPage {...props} />;
            }}
          />
        </Container>
      </Suspense>
    );
  }

  return (
    accountDetails && (
      <div className={`app animated fadeIn  ${collapsed ? 'toggled sidebar-collapsed' : ''}`}>
        <AppHeader
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
          toggled={toggled}
          collapsed={collapsed}
        />
        <AppSidebar
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={isBwgUser ? () => undefined : handleToggleSidebar}
        />
        <MainContent />
      </div>
    )
  );
}

export default App;
