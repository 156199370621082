/* eslint-disable max-len */
import React from 'react';

const CustomerIcon = () => {
  return (
    <svg viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.09994 0C6.23451 0 3.89994 2.24475 3.89994 5.00013C3.89994 7.75531 6.23451 10.0001 9.09994 10.0001C11.9654 10.0001 14.2999 7.75531 14.2999 5.00013C14.2999 2.24475 11.9654 0 9.09994 0ZM5.84997 5.00013C5.84997 3.28039 7.31137 1.875 9.09994 1.875C10.8885 1.875 12.3499 3.28039 12.3499 5.00013C12.3499 6.71988 10.8885 8.12506 9.09994 8.12506C7.31137 8.12506 5.84997 6.71988 5.84997 5.00013Z"
      />
      <path d="M6.1749 11.8751C2.76347 11.8751 0 14.5323 0 17.8124V19.0625C0 19.5802 0.436361 20 0.975014 20C1.51345 20 1.95003 19.5802 1.95003 19.0625V17.8124C1.95003 15.5676 3.84044 13.7499 6.17501 13.7499H12.0251C14.3597 13.7499 16.2501 15.5676 16.2501 17.8124V19.0625C16.2501 19.5802 16.6867 20 17.2251 20C17.7635 20 18.2001 19.5802 18.2001 19.0625V17.8124C18.2001 14.5322 15.4365 11.8751 12.0252 11.8751H6.1749Z" />
      <path d="M18.8501 12.8125C18.8501 12.2948 19.2867 11.8751 19.8251 11.8751C23.2365 11.8751 26 14.5323 26 17.8124V19.0625C26 19.5802 25.5634 20 25.025 20C24.4866 20 24.05 19.5802 24.05 19.0625V17.8124C24.05 15.5676 22.1596 13.7499 19.825 13.7499C19.2866 13.7499 18.85 13.3302 18.85 12.8124L18.8501 12.8125Z" />
      <path d="M16.9001 0.312639C16.3616 0.312639 15.925 0.73221 15.925 1.25014C15.925 1.76786 16.3616 2.18764 16.9001 2.18764C18.5067 2.18764 19.8251 3.45535 19.8251 5.00013C19.8251 6.5447 18.5067 7.81262 16.9001 7.81262C16.3616 7.81262 15.925 8.2324 15.925 8.75012C15.925 9.26784 16.3616 9.68762 16.9001 9.68762C19.5835 9.68762 21.7751 7.58034 21.7751 5.00013C21.7751 2.41971 19.5835 0.312639 16.9001 0.312639Z" />
    </svg>
  );
};

export default CustomerIcon;
