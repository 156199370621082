import { isEmpty } from 'lodash';
import React, { Suspense, useState } from 'react';
import Helmet from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useInitApp, useReportsData } from '../../hooks/hooks';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import useBwgUser from '../../hooks/useBwgUser';
import routes from '../../routes';
import { Loader } from '../../shared/components';
import { useAuthority } from '../../shared/components/HasAuthority';

const MainContent = () => {
  const [{ loading }, { loadPostLoginConfig, loadBwgPostLoginConfig }] = useInitApp();
  const [{ dummyCustomerData }, { loadDummyCustomerData }] = useReportsData();
  const [appLoaded, setAppLoaded] = useState(false);
  const { hasAuth } = useAuthority();
  const { isBwgUser } = useBwgUser();

  useAsyncEffect(async () => {
    if (isBwgUser) {
      await loadBwgConfig();
    } else {
      await loadPartnerConfig();
    }
    setAppLoaded(true);
  }, []);

  const loadPartnerConfig = async () => {
    loadDummyCustomerData();
    await loadPostLoginConfig();
  };

  const loadBwgConfig = async () => {
    await loadBwgPostLoginConfig();
  };

  return (
    <main className="AppContent">
      <Suspense fallback={<Loader />}>
        {!loading && appLoaded && !isEmpty(routes) ? (
          <Switch>
            {routes?.map(
              (route, idx) =>
                route.component &&
                hasAuth(route?.authorities, route?.noAuthorities) && (
                  <Route
                    key={`route-${idx}`}
                    name={route.name}
                    exact={route.exact}
                    path={route.path}
                    render={(routeProps) => (
                      <>
                        <Helmet>
                          <title>{route.name} | Recykal Partner</title>
                        </Helmet>
                        <route.component {...routeProps} routes={routes} />
                      </>
                    )}
                  />
                )
            )}
            <Redirect path="/login" to="/" />
            <Route
              path="*"
              name="Not Found"
              render={() => (
                <>
                  <h2 className="mt-4 ">Page Not Found.</h2>
                  <br />
                  <p>
                    Click to <a href="/">here</a> to go Home
                  </p>
                </>
              )}
            />
          </Switch>
        ) : (
          <Loader />
        )}
      </Suspense>
    </main>
  );
};

MainContent.propTypes = {};

export default MainContent;
