import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ApiEndpoints } from '../../../shared/constants';

export const trackingApi = createApi({
  reducerPath: 'tracking',
  baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:8082' }),
  endpoints: (builder) => ({
    getTrip: builder.query({
      query: () => ({
        url: ApiEndpoints.TRACKING.GET_TRIP,
        method: 'GET',
      }),
    }),
    getTripById: builder.query({
      query: (tripId) => ({
        url: `${ApiEndpoints.TRACKING.GET_TRIP}/${tripId}`,
        method: 'GET',
      }),
    }),
    updateLatLng: builder.mutation({
      query: ({ tripId, ...body }) => ({
        url: ApiEndpoints.TRACKING.UPDATE,
        method: 'POST',
        params: { tripId },
        body,
      }),
    }),
  }),
});

export const { useGetTripQuery, useUpdateLatLngMutation, useGetTripByIdQuery } = trackingApi;
