import { ApiEndpoints, FilterParamMap } from '../../../../shared/constants';
import { transformParams, transformResponse } from '../../../../shared/utils/Helper';
import { backendApi } from '../../base.config';

export const agencyApi = backendApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getAgencies: builder.query({
      query: (_params) => ({
        url: ApiEndpoints.BWG.AGENCY_LIST,
        params: transformParams(_params, FilterParamMap.AGENCY),
      }),
      transformResponse,
      providesTags: ['agency'],
    }),
    getByAgencyId: builder.query({
      query: (id) => ({
        url: `${ApiEndpoints.BWG.AGENCY_LIST}/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: 'agency', id }],
    }),
    createAgencies: builder.mutation({
      query: (body) => ({
        url: ApiEndpoints.BWG.CREATE_AGENCY,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['agency', 'wards'],
    }),
    updateAgencies: builder.mutation({
      query: (body) => ({
        url: ApiEndpoints.BWG.AGENCY_UPDATE,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['agency'],
    }),
    deactivateAgency: builder.mutation({
      query: (id) => ({
        url: `${ApiEndpoints.BWG.AGENCY_DEACTIVATE}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['agency'],
    }),
    activateAgency: builder.mutation({
      query: (id) => ({
        url: `${ApiEndpoints.BWG.AGENCY_ACTIVATE}/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['agency'],
    }),
    addWardToAgency: builder.mutation({
      query: (body) => ({
        url: ApiEndpoints.BWG.ADD_AGENCY_WARD,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['wards'],
    }),
    removeWardFromAgency: builder.mutation({
      query: (body) => ({
        url: ApiEndpoints.BWG.REMOVE_AGENCY_WARD,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['wards'],
    }),
    getTimeSlots: builder.query({
      query: () => ({
        url: `${ApiEndpoints.BWG.TIME_SLOTS}`,
      }),
    }),
  }),
});

export const {
  useGetAgenciesQuery,
  useLazyGetAgenciesQuery,
  useUpdateAgenciesMutation,
  useCreateAgenciesMutation,
  useGetByAgencyIdQuery,
  useActivateAgencyMutation,
  useDeactivateAgencyMutation,
  useAddWardToAgencyMutation,
  useRemoveWardFromAgencyMutation,
  useLazyGetTimeSlotsQuery,
} = agencyApi;
