/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React from 'react';
import { Menu, MenuItem, ProSidebar, SidebarContent, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';

import { useAccount } from '../../hooks/hooks';
import useResponsive from '../../hooks/useResponsive';
import { HasAuthority } from '../../shared/components';
import { useAuthority } from '../../shared/components/HasAuthority';
import nav from './nav';

const AppSidebar = ({ collapsed, rtl, toggled, handleToggleSidebar }) => {
  const current = useLocation();
  const { accountDetails, token, logout, loading } = useAccount();
  const { isMobile } = useResponsive();
  const { hasAuth } = useAuthority();

  if (!current) return null;

  return (
    <ProSidebar
      image={false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      onMouseOver={collapsed ? handleToggleSidebar : () => undefined}
      onMouseOut={!collapsed ? handleToggleSidebar : () => undefined}
      breakPoint={isMobile ? 'xs' : 'md'}
      className="AppSidebar dark"
      onToggle={handleToggleSidebar}>
      <SidebarContent>
        <Menu iconShape="circle">
          {accountDetails &&
            !isEmpty(nav) &&
            nav?.map((menu, idx) =>
              hasAuth(menu?.authorities, menu?.noAuthorities) && menu.children ? (
                <SubMenu
                  key={`${menu.url}-${idx}`}
                  defaultOpen={current.pathname?.includes(menu.url)}
                  className={classNames({
                    'submenu-active': current.pathname?.includes(menu.url),
                  })}
                  title={menu.title}
                  icon={menu.icon}>
                  {menu.children?.map((m, idx2) => (
                    <HasAuthority authorities={m?.authorities} noAuthorities={m?.noAuthorities}>
                      <MenuItem
                        className="child-menu-item"
                        key={`${m.url}-${idx2}`}
                        active={current.pathname === m.url}>
                        {m.title}
                        <Link
                          to={m.url}
                          onClick={isMobile ? () => handleToggleSidebar(false) : () => undefined}
                        />
                      </MenuItem>
                    </HasAuthority>
                  ))}
                </SubMenu>
              ) : hasAuth(menu?.authorities, menu?.noAuthorities) ? (
                <MenuItem
                  key={`${menu.url}-${idx}`}
                  icon={menu.icon}
                  active={
                    current.pathname === menu.url ||
                    (menu.url !== '/' && current.pathname?.includes(menu.url))
                  }>
                  {menu.title}
                  <Link
                    to={menu.url}
                    onClick={isMobile ? () => handleToggleSidebar(false) : () => undefined}
                  />
                </MenuItem>
              ) : (
                <div />
              )
            )}
        </Menu>
      </SidebarContent>

      {/* Need to be changed later */}
      {/* <SidebarFooter style={{ textAlign: 'center' }}>
        <div
          style={{
            padding: '10px 24px',
            color: '#dcdcdc',
          }}>
          <a
            le="Read More"
            href="https://recykal.com/smart_center/"
            target="_blank"
            rel="noopener noreferrer"
            className="font-light text-center"
            style={{
              color: '#828282',
            }}> */}
      {/* v{process.env.REACT_APP_VERSION} */}
      {/* SMART CENTER
          </a>
        </div>
      </SidebarFooter> */}
    </ProSidebar>
  );
};

AppSidebar.propTypes = {};

export default AppSidebar;
