/* eslint-disable max-len */
import React from 'react';

const TripsIcon = () => {
  return (
    <svg
      version="1.1"
      fill="none"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45">
      <g>
        <path
          d="M44,28.9l-1.4-9.3l0,0c-0.5-2.4-2.5-4.1-4.9-4.1l-6.9,0v-1.9c0-2.8-2.3-5-5-5l-16.4,0c-2.3,0-4.3,1.5-4.9,3.8
		l0,0L1,28.7l0,0C1,29,0.9,29.3,0.9,29.6c0,1,0.4,1.9,1,2.5c0.6,0.7,1.5,1.1,2.6,1.1h1.9c0.1,0.8,0.5,1.5,1.1,2.1
		c0.7,0.7,1.7,1.1,2.8,1.1c1.1,0,2.1-0.4,2.8-1.1c0.2-0.2,0.3-0.4,0.5-0.6c0.1,0.2,0.3,0.4,0.5,0.5c0.7,0.7,1.7,1.1,2.8,1.1
		c1.1,0,2.1-0.4,2.8-1.1c0.6-0.6,1-1.3,1.1-2.1h8.8c0.1,0.8,0.5,1.5,1.1,2.1c0.7,0.7,1.7,1.1,2.8,1.1c1.1,0,2.1-0.4,2.8-1.1
		c0.6-0.6,1-1.3,1.1-2.1h3.4c1,0,1.9-0.4,2.5-1.1c0.6-0.6,1-1.5,1-2.5C44.1,29.4,44.1,29.2,44,28.9L44,28.9z M2.9,31.1
		c-0.4-0.4-0.6-1-0.6-1.5c0-0.2,0-0.4,0.1-0.5l0,0l3.5-16.3C6.3,11.1,7.7,10,9.3,10h16.4c1,0,1.9,0.4,2.6,1.1
		c0.7,0.7,1.1,1.6,1.1,2.6v18.1h-8.7c-0.3-1.8-2-3.2-3.9-3.2c-1.1,0-2.1,0.4-2.8,1.2c-0.2,0.2-0.3,0.4-0.5,0.6
		c-0.7-1-1.9-1.7-3.3-1.7c-1.1,0-2.1,0.4-2.8,1.2c-0.5,0.6-0.9,1.3-1.1,2.1H4.5C3.9,31.7,3.3,31.5,2.9,31.1L2.9,31.1z M12,34.3
		c-0.5,0.5-1.1,0.7-1.8,0.7c-1.4,0-2.5-1.1-2.5-2.5c0-0.7,0.3-1.3,0.7-1.8c0.5-0.5,1.1-0.8,1.8-0.8c0.7,0,1.3,0.3,1.8,0.8
		c0.5,0.5,0.8,1.1,0.8,1.8C12.8,33.2,12.5,33.8,12,34.3L12,34.3z M16.7,35c-1.4,0-2.5-1.1-2.5-2.5c0-0.7,0.3-1.3,0.7-1.8
		c0.5-0.5,1.1-0.8,1.8-0.8c0.7,0,1.3,0.3,1.8,0.8c0.5,0.5,0.7,1.1,0.8,1.7l0,0.1l0,0.1c0,0.7-0.3,1.3-0.7,1.7
		C18.1,34.7,17.4,35,16.7,35L16.7,35z M35,34.3c-0.5,0.5-1.1,0.7-1.8,0.7c-1.4,0-2.5-1.1-2.5-2.5l0-0.1v-0.2c0-0.6,0.3-1.2,0.7-1.6
		c0.5-0.5,1.1-0.8,1.8-0.8c0.7,0,1.3,0.3,1.8,0.8c0.5,0.5,0.8,1.1,0.8,1.8C35.8,33.2,35.5,33.8,35,34.3L35,34.3z M42.1,31.1
		c-0.4,0.4-0.9,0.6-1.6,0.6h-3.4c-0.3-1.8-2-3.2-3.9-3.2c-1,0-1.8,0.4-2.5,0.9V16.9h6.9c1.8,0,3.2,1.2,3.6,2.9l1.4,9.3l0,0
		c0,0.1,0,0.3,0,0.4C42.7,30.2,42.5,30.7,42.1,31.1L42.1,31.1z"
        />
        <path
          d="M14.4,25c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.6,0-1.2-0.3-1.6-0.7c-0.4-0.4-0.6-1-0.6-1.5
		c0-0.4,0.1-0.7,0.3-1.1c0.2-0.3,0.1-0.8-0.3-1c-0.3-0.2-0.8-0.1-1,0.3c-0.3,0.6-0.5,1.2-0.5,1.8c0,0.9,0.4,1.8,1,2.5
		C12.5,24.6,13.4,25,14.4,25L14.4,25z"
        />
        <path
          d="M19.1,23.7c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c1,0,1.9-0.4,2.6-1.1c0.6-0.7,1-1.5,1-2.5
		c0-0.6-0.2-1.2-0.5-1.8c-0.2-0.3-0.6-0.4-1-0.3c-0.3,0.2-0.4,0.6-0.3,1c0.2,0.4,0.3,0.7,0.3,1.1c0,0.6-0.2,1.1-0.6,1.5
		C20.2,23.4,19.7,23.6,19.1,23.7L19.1,23.7z"
        />
        <path
          d="M13.9,16.6c0.3,0.2,0.8,0.1,1-0.3c0.2-0.4,0.5-0.6,0.8-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1.1,0.3
		c0.3,0.2,0.6,0.4,0.8,0.8c0.2,0.3,0.6,0.4,1,0.3c0.3-0.2,0.4-0.6,0.3-1c-0.3-0.6-0.8-1-1.4-1.3c-0.5-0.3-1.1-0.4-1.7-0.4
		c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.3-1,0.7-1.4,1.3C13.5,16,13.6,16.4,13.9,16.6L13.9,16.6z"
        />
      </g>
    </svg>
  );
};

export default TripsIcon;
