import React, { Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PageContainer from '../../hoc/PageContainer';
import { Loader } from '../../shared/components';

const InboundOrderPage = React.lazy(() => import('./InboundOrderPage'));
const OutboundOrderPage = React.lazy(() => import('./OutboundOrderPage'));
const OrderDetailsPage = React.lazy(() => import('./OrderDetailsPage'));
const CreateOrderPage = React.lazy(() => import('../Inbound/CreateOrderPage'));

const OrdersRoutes = (props) => {
  const { path, url } = useRouteMatch();
  const routes = [
    {
      name: 'Create Pickup Order',
      exact: true,
      path: `${path}/inbound/create-pickup-order`,
      component: (routeProps) => <CreateOrderPage {...routeProps} isPickup />,
    },
    {
      name: 'Create Sale Order',
      exact: true,
      path: `${path}/outbound/create-sale-order`,
      component: (routeProps) => <CreateOrderPage {...routeProps} isPickup={false} />,
    },
    {
      name: 'Inbound Order Details',
      exact: true,
      path: `${path}/inbound/:id/view`,
      component: OrderDetailsPage,
    },
    {
      name: 'Outbound Order Details',
      exact: true,
      path: `${path}/outbound/:id/view`,
      component: OrderDetailsPage,
    },
    {
      name: 'Inbound Orders',
      exact: false,
      path: `${path}/inbound`,
      component: InboundOrderPage,
    },

    {
      name: 'Outbound Orders',
      exact: false,
      path: `${path}/outbound`,
      component: OutboundOrderPage,
    },
  ];
  return (
    <PageContainer>
      <Switch>
        {routes?.map(
          (route, idx) =>
            route.component && (
              <Route
                key={`route1-${idx}`}
                name={route.name}
                exact={route.exact}
                path={`${route.path}`}
                render={(routeProps) => (
                  <Suspense fallback={<Loader />}>
                    <route.component {...routeProps} routes={[...routes, ...props?.routes]} />
                  </Suspense>
                )}
              />
            )
        )}
      </Switch>
    </PageContainer>
  );
};

export default OrdersRoutes;
