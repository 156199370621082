import React from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

const DateViewToggle = ({ selectedDateView, onChange }) => {
  const dateViews = [
    { name: 'Daily', value: 'date' },
    { name: 'Monthly', value: 'month' },
  ];

  return (
    <ButtonGroup toggle style={{ display: 'contents' }}>
      {dateViews.map((view, idx) => (
        <ToggleButton
          key={idx}
          size="lg"
          type="radio"
          variant="outline-success"
          name="dateView"
          value={view.value}
          checked={selectedDateView === view.value}
          onChange={(e) => onChange(e.currentTarget.value)}>
          {view.name}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
};

export default DateViewToggle;
