import { createSlice } from '@reduxjs/toolkit';

import { formattedTimeSlots } from '../../../../shared/utils/Helper';
import { agencyApi } from './agency.api';

export const agencySlice = createSlice({
  name: 'agency',
  initialState: {
    agencies: [],
    timeSlots: [],
  },
  extraReducers: (builder) => {
    builder.addMatcher(agencyApi.endpoints.getAgencies.matchFulfilled, (state, { payload }) => {
      state.agencies = payload.dataList;
    });
    builder.addMatcher(agencyApi.endpoints.getTimeSlots.matchFulfilled, (state, { payload }) => {
      state.timeSlots = formattedTimeSlots(payload);
    });
  },
});
