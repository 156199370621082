import CleverTap from './CleverTap';

const AppAnalytics = {
  initialize() {
    CleverTap.initialize();
  },

  identity(accountDetails) {
    CleverTap.profile({
      Name: accountDetails.firstName, // String
      Identity: accountDetails?.id, // String or number
      Email: accountDetails.email, // Email address of the user
      Phone: `91${accountDetails.mobile}`, // Phone (with the country code)
    });
  },

  track(eventName, data) {
    CleverTap.track(`WEB_${eventName}`, data);
  },

  pageView() {},
};

export default AppAnalytics;
