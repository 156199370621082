import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accountDetails: null,
  deviceInfo: null,
  recyclers: [],
  users: [],
  pickupExecutives: [],
};

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.accountDetails = action.payload;
    },
    setDeviceInfo: (state, action) => {
      state.deviceInfo = action.payload;
    },
    setRecyclers: (state, action) => {
      state.recyclers = action.payload;
    },
    setWasteCollectors: (state, action) => {
      state.wasteCollectors = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setPickupExecutives: (state, action) => {
      state.pickupExecutives = action.payload;
    },
  },
});

export const userActions = UserSlice.actions;
export default UserSlice.reducer;
