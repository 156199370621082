/* eslint-disable no-shadow */
import axios from 'axios';
import { includes, isEmpty } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';

import AppAnalytics from '../services/AppAnalytics';
import {
  acceptUserPolicy,
  addFavoriteItem,
  addNewBulkCustomers,
  attachDocument,
  changeDocumentType,
  changePassword,
  createPasswordFromLink,
  deleteDocument,
  doCreateLocation,
  doCreateOrderNotesById,
  doCreatePickupOrder,
  doCreateSaleOrder,
  doCreateTransportLogistics,
  doCreateUser,
  doDeleteDownloadRecord,
  doDeleteOrder,
  doDeleteUploadRecord,
  doGetAllEmployeeList,
  doGetAttendanceByDepartment,
  doGetAttendanceStats,
  doImportRateCards,
  doUpdateItemLocationPrice,
  doUpdateLocation,
  doUpdateManageRateStatus,
  doUpdateOrderAction,
  doUpdateTimeSlots,
  doUpdateTimeSlotsCapacity,
  doUpdateTransportLogistics,
  doUpdateUser,
  doUpdateUsersForLocation,
  doUpdateZipCodes,
  downloadCustomersData,
  fetchAccount,
  fetchAvailableStockStats,
  fetchBagContaminatedTrend,
  fetchBagsComparisonOverview,
  fetchBagStats,
  fetchBagsWith1User,
  fetchBagTxns,
  fetchBagWeightTrend,
  fetchCashDashboard,
  fetchCorporateCollectionStats,
  fetchDownloadRecords,
  fetchEprDeliveriesStats,
  fetchEprInvoicesStats,
  fetchEprOrderStats,
  fetchMarketplaceStats,
  fetchMaterialByBuyer,
  fetchMaterialBySource,
  fetchMaterialTrends,
  fetchOrderDashboard,
  fetchOrderTrendsTrends,
  fetchPartnerItems,
  fetchPrivacyPolicy,
  fetchStockAdjustedStats,
  fetchStockStacks,
  fetchTrendStats,
  fetchUploadRecords,
  fetchUploadRecordsById,
  fetchUploadSample,
  fetchUserPolicy,
  fetchUsers,
  fetchWasteCollectorsDashboard,
  getAdvancePayment,
  getAllLedgers,
  getAttendanceCounts,
  getAttendanceReportList,
  getBulkGeneratorsForInbound,
  getCatalogCategories,
  getCustomerAdvance,
  getCustomerDetails,
  getCustomerLocation,
  getCustomersData,
  getFavoriteItemsData,
  getFavoritePatnerItems,
  getGeneratedCustomerId,
  getInboundItems,
  getInvoiceProforma,
  getInwardReportCSVReportList,
  getInwardReportList,
  getItemDetails,
  getItemsForLocation,
  getLocationBalance,
  getLocationforID,
  getLocations,
  getLocationsByBG,
  getLogisticsVehicle,
  getManageRateLocation,
  getOnlyItemDetails,
  getOpeningBalanceList,
  getOpeningStockList,
  getOrderById,
  getOrderChangeLogsById,
  getOrderLogisticsById,
  getOutboundItems,
  getPaymentCSVReportList,
  getPaymentsReportList,
  getPaymentSummeryReportList,
  getPickupOrderNotesById,
  getProcessStock,
  getPurchaseOrders,
  getRateCardsFilter,
  getSalesReportList,
  getSarveksenAddInfo,
  getSarveksenProfileAttributes,
  getSarveksenProfileWaste,
  getSearchItems,
  getSignedUrl,
  getStockCSVReportList,
  getStockReportList,
  getTimeSlots,
  getTrackVisits,
  getTranporters,
  getUsersForCenter,
  getUsersForLocation,
  getVehicles,
  getWardsForCenter,
  getWasteCollectorCSVReportList,
  getWasteCollectorReportList,
  initAppApis,
  initDummyCustomerDataApis,
  initPostAuthApis,
  login,
  postAddCash,
  removePartnerItem,
  resetPassword,
  updateAccount,
  updateAssignExecutive,
  updateBasicInfo,
  updateContactPersonInfo,
  updateCustomerAddress,
  updatedSarveksenAddInfo,
  updateLocationDetailsInfo,
  updateMakePayment,
  updateOrderPaymentStatus,
  updateToNetwork,
} from '../services/backend-api';
import { ErrorCode, FilterParamMap } from '../shared/constants';
import { AUTHORITY } from '../shared/constants/Constants';
import { transformParams } from '../shared/utils/Helper';
import {
  useLazyGetAgenciesQuery,
  useLazyGetTimeSlotsQuery,
} from '../store/apis/bwg/agency/agency.api';
import { useLazyGetWardsQuery } from '../store/apis/bwg/wards/wards.api';
import { authActions } from '../store/Auth/auth.reducer';
import { catalogActions } from '../store/Catalog/catalog.reducer';
import { configActions } from '../store/Config/config.reducer';
import { locationActions } from '../store/Location/location.reducer';
import { userActions } from '../store/User/user.reducer';
import useAsyncEffect from './useAsyncEffect';

/** =====================================================
 * ===================== AUTH HOOK ===================
 * ===================================================== */
export function useLogin() {
  const dispatch = useDispatch();
  const [{ error, loading, value }, doLogin] = useAsyncFn(async (payload) => {
    const response = await login(payload);
    dispatch(authActions.setToken(response.id_token));
    return response;
  }, []);
  return [{ error, loading, value }, doLogin];
}

export function useRequestPassword() {
  const [
    { resetPasswordError, requestPasswordLoading, requestPasswordValue },
    doResetPassword,
  ] = useAsyncFn(async (payload) => {
    const response = await resetPassword(payload);
    return response;
  }, []);

  const [
    { createPasswordError, createPasswordLoading, createPasswordValue },
    doCreatePassword,
  ] = useAsyncFn(async (payload) => {
    const response = await createPasswordFromLink(payload);
    return response;
  });
  return [
    {
      resetPasswordError,
      requestPasswordLoading,
      requestPasswordValue,
      createPasswordError,
      createPasswordLoading,
      createPasswordValue,
    },
    { doResetPassword, doCreatePassword },
  ];
}

export function usePrivacyPolicy() {
  const [privacyPolicyData, doFetchPrivacyPolicy] = useAsyncFn(async (payload) => {
    const response = await fetchPrivacyPolicy(payload);
    return response;
  }, []);

  const [userPolicyData, doFetchUserPolicy] = useAsyncFn(async (payload) => {
    const response = await fetchUserPolicy(payload);
    return response;
  }, []);

  const [acceptUserPolicyData, doAcceptUserPolicy] = useAsyncFn(async (payload) => {
    const response = await acceptUserPolicy(payload);
    return response;
  });
  return [
    {
      privacyPolicyData,
      userPolicyData,
      acceptUserPolicyData,
    },
    {
      doFetchPrivacyPolicy,
      doFetchUserPolicy,
      doAcceptUserPolicy,
    },
  ];
}

/** =====================================================
 * =================== ACCOUNT HOOK =================
 * ===================================================== */
export function useAccount() {
  const { accountDetails, token } = useSelector(
    ({ user: { accountDetails }, auth: { token } }) => ({
      accountDetails,
      token: token || localStorage.getItem('userToken'),
    })
  );

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useAsyncEffect(async () => {
    setLoading(true);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    if (!accountDetails || !loading) {
      reFetchAccount();
    }
  }, [token]);

  const reFetchAccount = async () => {
    try {
      const { newToken, ...account } = token ? await fetchAccount() : null;
      if (newToken) {
        dispatch(authActions.setToken(newToken));
      }
      const isBWGUser =
        !isEmpty(account) &&
        (account?.profile?.municipalCorp ||
          includes(account?.authorities, AUTHORITY.AGENCY_ADMIN) ||
          includes(account?.authorities, AUTHORITY.AGENCY_USER) ||
          includes(account?.authorities, AUTHORITY.FINANCE_MANAGER));
      // optimized code for all  BWG Role
      if (isBWGUser) {
        // Common role for BWG
        account?.authorities.push(AUTHORITY.ALL_BWG_USERS);
        // For Municipal Admin(Commissioner)
        if (includes(account?.authorities, AUTHORITY.FRANCHISE_ADMIN)) {
          account?.authorities.push(AUTHORITY.MUNICIPAL_ADMIN);
        }
        // For operation role
        if (includes(account?.authorities, AUTHORITY.PARTNER_OPERATIONS)) {
          account?.authorities.push(AUTHORITY.BWG_OPERATION_MANAGER);
        }
      }
      setLoading(false);
      dispatch(userActions.setUserDetails(account));
      account && AppAnalytics.identity(account);
    } catch (error) {
      if (error?.data?.errorCode === ErrorCode.AUTHORIZATION_FAILURE) {
        axios.defaults.headers.common.Authorization = ``;
        dispatch(authActions.setToken(null));
      }
    }
  };

  const logout = () => {
    dispatch(authActions.setToken(null));
    dispatch(authActions.logout());
  };

  return {
    accountDetails,
    logout,
    token,
    loading,
    skanWithBag: accountDetails?.profile?.skanWithBag,
    reFetchAccount,
  };
}

/** =====================================================
 * =================== ACCOUNT PASSWORD =================
 * ===================================================== */
export function useAccountPassword() {
  const [updateState, doUpdateAccount] = useAsyncFn(async (payload) => {
    const response = await updateAccount(payload);
    return response;
  });

  const [getState, doGetAccount] = useAsyncFn(async () => {
    const res = await fetchAccount();
    return res;
  });

  const [updatePassState, doUpdatePassword] = useAsyncFn(async (payload) => {
    const res = await changePassword(payload);
    return res;
  });

  return [
    {
      error: updatePassState.error || getState.error || getState.error,
      loading: updatePassState.loading || getState.loading || getState.loading,
      value: updatePassState.value || getState.value || getState.value,
    },
    { doUpdateAccount, doGetAccount, doUpdatePassword },
  ];
}

/** =====================================================
 * ================= LOAD INIT APP DATA =================
 * ===================================================== */
export function useInitApp() {
  const dispatch = useDispatch();
  const [getAgencies, { isLoading: agencyLoading }] = useLazyGetAgenciesQuery();
  const [getTimeSlots, { isLoading: timeLoading }] = useLazyGetTimeSlotsQuery();
  const [getWards, { isLoading, wardLoading }] = useLazyGetWardsQuery();
  const [preState, loadPreLoginConfig] = useAsyncFn(async (payload) => {
    const response = await initAppApis();
    if (!isEmpty(response)) {
      dispatch(configActions.setAdminConfig(response[0]?.data));
      dispatch(configActions.setBusinessTypes(response[1]?.data));
      dispatch(configActions.setCities(response[2]?.data));
      dispatch(configActions.setTheme(response[3]?.data));
    }
    return response;
  }, []);

  const [postState, loadPostLoginConfig] = useAsyncFn(async (payload) => {
    const response = await initPostAuthApis();
    if (!isEmpty(response)) {
      dispatch(configActions.setWards(response[0]?.data));
      dispatch(catalogActions.setItems(response[1]?.data));
      dispatch(locationActions.setLocations(response[2]?.data));
      dispatch(catalogActions.setCategories(response[3]?.data));
      dispatch(userActions.setUsers(response[4]?.data));
      dispatch(configActions.setDepartments(response[5]?.data));
      dispatch(configActions.setDesignations(response[6]?.data));
      dispatch(userActions.setPickupExecutives(response[7]?.data));
    }
    return response;
  }, []);

  const [bwgPostState, loadBwgPostLoginConfig] = useAsyncFn(async (payload) => {
    getWards();
    getAgencies({ size: 1000 });
    await getTimeSlots();
  }, []);

  return [
    {
      error: preState.error || postState.error || bwgPostState.error,
      loading: preState.loading || postState.loading || wardLoading || timeLoading,
      value: preState.value || preState.value || bwgPostState.value,
    },
    { loadPreLoginConfig, loadPostLoginConfig, loadBwgPostLoginConfig },
  ];
}

/** =====================================================
 * =================== LOAD USER DATA =================
 * ===================================================== */
export function useUsers() {
  const [fetchState, fetchUser] = useAsyncFn(async (params) => {
    const response = await fetchUsers(params);
    return response;
  });

  const [createState, createUser] = useAsyncFn(async (payload) => {
    const response = await doCreateUser(payload);
    fetchUser();
    return response;
  });

  const [updateState, updateUser] = useAsyncFn(async (payload) => {
    const response = await doUpdateUser(payload);
    fetchUser();
    return response;
  });

  return [
    {
      error: fetchState.error || createState.error || updateState.error,
      loading: fetchState.loading || createState.loading || updateState.loading,
      value: fetchState.value || createState.value || updateState.value,
    },
    { fetchUser, createUser, updateUser },
  ];
}

/** =====================================================
 * =================== LOAD DASHBOARD DATA =================
 * ===================================================== */
export function useDashboardData() {
  const [{ error, loading, value: locationsList }, doFetchLocations] = useAsyncFn(
    async (payload) => {
      const response = await getLocations(payload);
      const data = response?.data;
      data.unshift({ id: 'ALL', name: 'All' });
      return data;
    },
    []
  );

  const [
    {
      error: materialStockStackError,
      loading: materialStockStackLoading,
      value: materialStockStackData,
    },
    doFetchMaterialStockStack,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchStockStacks(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    { error: cashDashboardError, loading: cashDashboardLoading, value: cashDashboardData },
    doFetchCashDashboard,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchCashDashboard(payload);
    const data = response?.data;

    return data;
  }, []);

  const [
    {
      error: stockAdjustedStatsError,
      loading: stockAdjustedStatsLoading,
      value: stockAdjustedStatsData,
    },
    doFetchStockAdjustedStats,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchStockAdjustedStats(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    {
      error: availableStockStatsError,
      loading: availableStockStatsLoading,
      value: availableStockStatsData,
    },
    doFetchAvailableStockStats,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchAvailableStockStats(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    { error: trendStatsError, loading: trendStatsLoading, value: trendStatsData },
    doFetchTrendStats,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchTrendStats(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    { error: orderDashboardError, loading: orderDashboardLoading, value: orderDashboardData },
    doFetchOrderDashboard,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchOrderDashboard(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    {
      error: wasteCollectorsDashboardError,
      loading: wasteCollectorsDashboardLoading,
      value: wasteCollectorsDashboardData,
    },
    doFetchWasteCollectorsDashboard,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchWasteCollectorsDashboard(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    { error: materialTrendsError, loading: materialTrendsLoading, value: materialTrends },
    doFetchMaterialTrends,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchMaterialTrends(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    { error: orderTrendsError, loading: orderTrendsLoading, value: orderTrends },
    doFetchOrderTrends,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchOrderTrendsTrends(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    { error: materialBySourceError, loading: materialBySourceLoading, value: materialBySourceData },
    doFetchMaterialBySource,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchMaterialBySource(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    { error: materialByBuyerError, loading: materialByBuyerLoading, value: materialByBuyerData },
    doFetchMaterialByBuyer,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchMaterialByBuyer(payload);
    const data = response?.data;
    return data;
  }, []);

  return [
    {
      error,
      loading,
      locationsList,
      materialStockStackError,
      materialStockStackLoading,
      materialStockStackData,
      cashDashboardError,
      cashDashboardLoading,
      cashDashboardData,
      stockAdjustedStatsError,
      stockAdjustedStatsLoading,
      stockAdjustedStatsData,
      availableStockStatsError,
      availableStockStatsLoading,
      availableStockStatsData,
      trendStatsError,
      trendStatsLoading,
      trendStatsData,
      orderDashboardError,
      orderDashboardLoading,
      orderDashboardData,
      wasteCollectorsDashboardError,
      wasteCollectorsDashboardLoading,
      wasteCollectorsDashboardData,
      materialTrendsError,
      materialTrendsLoading,
      materialTrends,
      orderTrendsError,
      orderTrendsLoading,
      orderTrends,
      materialBySourceError,
      materialBySourceLoading,
      materialBySourceData,
      materialByBuyerError,
      materialByBuyerLoading,
      materialByBuyerData,
    },
    {
      doFetchLocations,
      doFetchMaterialStockStack,
      doFetchCashDashboard,
      doFetchStockAdjustedStats,
      doFetchAvailableStockStats,
      doFetchTrendStats,
      doFetchOrderDashboard,
      doFetchWasteCollectorsDashboard,
      doFetchMaterialTrends,
      doFetchOrderTrends,
      doFetchMaterialBySource,
      doFetchMaterialByBuyer,
    },
  ];
}

/** =====================================================
 * =================== LOAD REPORTS DATA =================
 * ===================================================== */
export function useEPR() {
  const [orderStatState, doFetchEprOrderStats] = useAsyncFn(async (params) => {
    const response = await fetchEprOrderStats(params);
    const data = response?.data;
    return data;
  }, []);

  const [delStatState, doFetchEprDeliveryStats] = useAsyncFn(async (params) => {
    const response = await fetchEprDeliveriesStats(params);
    const data = response?.data;
    return data;
  }, []);

  const [invcStatState, doFetchEprInvoicesStats] = useAsyncFn(async (params) => {
    const response = await fetchEprInvoicesStats(params);
    const data = response?.data;
    return data;
  }, []);

  return [
    {
      loading: orderStatState.loading || delStatState.loading || invcStatState.loading,
      error: orderStatState.error || delStatState.error || invcStatState.error,
      orderStats: orderStatState.value,
      deliveryStats: delStatState.value,
      invoicesStats: invcStatState.value,
    },
    { doFetchEprOrderStats, doFetchEprDeliveryStats, doFetchEprInvoicesStats },
  ];
}

/** =====================================================
 * =================== MARKETPLACE HOOK =================
 * ===================================================== */
export function useMarketplace() {
  const [fetchStatsState, doFetchMarketplaceStats] = useAsyncFn(async (params) => {
    const response = await fetchMarketplaceStats(params);
    const data = response?.data;

    return data;
  }, []);

  return [
    {
      loading: fetchStatsState.loading,
      error: fetchStatsState.error,
      value: fetchStatsState.value,
    },
    { doFetchMarketplaceStats },
  ];
}

/** =====================================================
 * ================= COLLECTION DASHBOARD =================
 * ===================================================== */
export function useCorporateCollection() {
  const [statsState, doGetCollectionStats] = useAsyncFn(async (params) => {
    const response = await fetchCorporateCollectionStats(params);
    return response;
  });
  const [bagState, doGetBagCollectionStats] = useAsyncFn(async (params) => {
    const lastWeekDates = {
      toDate: moment().startOf('day').toISOString(),
      fromDate: moment().subtract(6, 'd').endOf('day').toISOString(),
    };
    const apis = [
      fetchBagStats(params),
      fetchBagWeightTrend(lastWeekDates),
      fetchBagsWith1User(lastWeekDates),
      fetchBagContaminatedTrend(lastWeekDates),
      fetchBagsComparisonOverview(),
    ];
    const response = await Promise.allSettled(apis).then((res) => ({
      bagStats: res[0]?.value?.data || [],
      bagWeightTrend: res[1]?.value?.data || [],
      bagWith1User: res[2]?.value?.data || [],
      bagContaminated: res[3]?.value?.data || [],
      comparisonTrend: res[4]?.value?.data || [],
    }));
    return response;
  });

  return [
    {
      value: statsState.value || bagState.value,
      error: statsState.error || bagState.error,
      loading: statsState.loading || bagState.loading,
    },
    { doGetCollectionStats, doGetBagCollectionStats },
  ];
}

/** =====================================================
 * =================== LOAD REPORTS DATA =================
 * ===================================================== */
export function useReportsData() {
  const dispatch = useDispatch();
  const [
    { error: inwardReportError, loading: inwardReportLoading, value: inwardReportList },
    doFetchInwardReport,
  ] = useAsyncFn(async (payload) => {
    const response = await getInwardReportList(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    {
      error: inwardReportCSVReportError,
      loading: inwardReportCSVReportLoading,
      value: inwardReportCSVReportList,
    },
    doFetchInwardReportCSVReport,
  ] = useAsyncFn(async (payload) => {
    const response = await getInwardReportCSVReportList(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    {
      error: wasteCollectorReportError,
      loading: wasteCollectorReportLoading,
      value: wasteCollectorReportList,
    },
    doFetchWasteCollectorReport,
  ] = useAsyncFn(async (payload) => {
    const response = await getWasteCollectorReportList(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    {
      error: wasteCollectorCSVReportError,
      loading: wasteCollectorCSVReportLoading,
      value: wasteCollectorCSVReportList,
    },
    doFetchWasteCollectorCSVReport,
  ] = useAsyncFn(async (payload) => {
    const response = await getWasteCollectorCSVReportList(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    { error: stockReportError, loading: stockReportLoading, value: stockReportList },
    doFetchStockReport,
  ] = useAsyncFn(async (payload) => {
    const response = await getStockReportList(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    { error: openingStockError, loading: openingStockLoading, value: openingStockList },
    doFetchOpeningStock,
  ] = useAsyncFn(async (payload) => {
    const response = await getOpeningStockList(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    { error: stockCSVReportError, loading: stockCSVReportLoading, value: stockCSVReportList },
    doFetchStockCSVReport,
  ] = useAsyncFn(async (payload) => {
    const response = await getStockCSVReportList(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    { error: paymentReportError, loading: paymentReportLoading, value: paymentReportList },
    doFetchPaymentsReport,
  ] = useAsyncFn(async (payload) => {
    const response = await getPaymentsReportList(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    { error: salesReportError, loading: salesReportLoading, value: salesReportList },
    doFetchSalesReport,
  ] = useAsyncFn(async (payload) => {
    const response = await getSalesReportList(payload);
    const data = response?.data;
    return data;
  }, []);

  const [dummyCustomerData, loadDummyCustomerData] = useAsyncFn(async (payload) => {
    const response = await initDummyCustomerDataApis();
    if (!isEmpty(response)) {
      dispatch(userActions.setRecyclers(response[0]?.data));
      dispatch(userActions.setWasteCollectors(response[1]?.data));
    }
    return response;
  }, []);

  const [
    { error: attendanceReportError, loading: attendanceReportLoading, value: attendanceReportList },
    doFetchAttendanceReport,
  ] = useAsyncFn(async (payload) => {
    const response = await getAttendanceReportList(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    { error: attendanceCountsError, loading: attendanceCountsLoading, value: attendanceCounts },
    doFetchAttendanceCounts,
  ] = useAsyncFn(async (payload) => {
    const response = await getAttendanceCounts(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    { error: allEmployeesError, loading: allEmployeesLoading, value: allEmployeesList },
    doFetchAllEmployee,
  ] = useAsyncFn(async (payload) => {
    const response = await doGetAllEmployeeList(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    {
      error: paymentSummeryReportError,
      loading: paymentSummeryReportLoading,
      value: paymentSummeryReportList,
    },
    doFetchPaymentReportReport,
  ] = useAsyncFn(async (payload) => {
    const response = await getPaymentSummeryReportList(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    {
      error: openingBalanceReportError,
      loading: openingBalanceReportLoading,
      value: openingBalanceReportList,
    },
    doFetchOpeningBalanceReport,
  ] = useAsyncFn(async (payload) => {
    const response = await getOpeningBalanceList(payload);
    const data = response?.data;
    return data;
  }, []);

  const [
    {
      error: paymentSummeryCSVReportError,
      loading: paymentSummeryCSVReportLoading,
      value: paymentSummeryCSVReportList,
    },
    doFetchPaymentReportCSVReport,
  ] = useAsyncFn(async (payload) => {
    const response = await getPaymentCSVReportList(payload);
    const data = response?.data;
    return data;
  }, []);

  return [
    {
      inwardReportError,
      inwardReportLoading,
      inwardReportList,
      inwardReportCSVReportError,
      inwardReportCSVReportLoading,
      inwardReportCSVReportList,
      wasteCollectorReportError,
      wasteCollectorReportLoading,
      wasteCollectorReportList,
      wasteCollectorCSVReportError,
      wasteCollectorCSVReportLoading,
      wasteCollectorCSVReportList,
      stockReportError,
      stockReportLoading,
      stockReportList,
      openingStockError,
      openingStockLoading,
      openingStockList,
      stockCSVReportError,
      stockCSVReportLoading,
      stockCSVReportList,
      paymentReportError,
      paymentReportLoading,
      paymentReportList,
      salesReportError,
      salesReportLoading,
      salesReportList,
      dummyCustomerData,
      attendanceReportError,
      attendanceReportLoading,
      attendanceReportList,
      attendanceCountsError,
      attendanceCountsLoading,
      attendanceCounts,
      paymentSummeryReportError,
      paymentSummeryReportLoading,
      paymentSummeryReportList,
      openingBalanceReportError,
      openingBalanceReportLoading,
      openingBalanceReportList,
      paymentSummeryCSVReportError,
      paymentSummeryCSVReportLoading,
      paymentSummeryCSVReportList,
      allEmployeesError,
      allEmployeesLoading,
      allEmployeesList,
    },
    {
      doFetchInwardReport,
      doFetchInwardReportCSVReport,
      doFetchWasteCollectorReport,
      doFetchWasteCollectorCSVReport,
      doFetchStockReport,
      doFetchOpeningStock,
      doFetchStockCSVReport,
      doFetchPaymentsReport,
      doFetchSalesReport,
      loadDummyCustomerData,
      doFetchAttendanceReport,
      doFetchAttendanceCounts,
      doFetchPaymentReportReport,
      doFetchOpeningBalanceReport,
      doFetchPaymentReportCSVReport,
      doFetchAllEmployee,
    },
  ];
}

/** =====================================================
 * =================== LOAD PICK-UP DATA =================
 * ===================================================== */
export function useLocations() {
  const [
    { error: getLocationsError, loading: getLocationsLoading, value: locationsList },
    doGetLocations,
  ] = useAsyncFn(async (params) => {
    const response = await getLocations(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);

  const [
    { error: getLocationByIdError, loading: getLocationByIdLoading, value: locationById },
    getLocationById,
  ] = useAsyncFn(async (params) => {
    const response = await getLocationforID(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);

  const [
    {
      error: getUsersForLocationError,
      loading: getUsersForLocationLoading,
      value: usersForLocation,
    },
    doGetUsersforLocation,
  ] = useAsyncFn(async (params) => {
    const response = await getUsersForLocation(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);
  const [
    { error: getTimeSlotsError, loading: getTimeSlotsLoading, value: timeSlotsList },
    doGetTimeSlots,
  ] = useAsyncFn(async (params) => {
    const response = await getTimeSlots(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);

  const [
    { error: getCenterUsersError, loading: getCenterUsersLoading, value: centerUsersList },
    doGetCenterUsers,
  ] = useAsyncFn(async (params) => {
    const response = await getUsersForCenter(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);

  const [
    { error: getLocationItemsError, loading: getLocationItemsLoading, value: LocationItemsList },
    doGetLocationItems,
  ] = useAsyncFn(async (params) => {
    const response = await getItemsForLocation(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);

  const [
    { error: getWardsForCenterError, loading: getWardsForCenterLoading, value: WardsForCenterList },
    doGetWardsForCenter,
  ] = useAsyncFn(async (params) => {
    const response = await getWardsForCenter(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);

  const [
    { error: updateSlotError, loading: UpdateSlotloading, value: updateSlotValue },
    updateSlot,
  ] = useAsyncFn(async (payload) => {
    const response = await doUpdateTimeSlots(payload);
    getTimeSlots();
    return response;
  }, []);

  const [
    {
      error: updateSlotCapacityError,
      loading: updateSlotCapacityloading,
      value: updateSlotCapacityValue,
    },
    updateSlotCapacity,
  ] = useAsyncFn(async (payload) => {
    const response = await doUpdateTimeSlotsCapacity(payload);

    return response;
  }, []);

  const [
    { error: UpdateLocationError, loading: UpdateLocationLoading, value: updateLocationValue },
    updateLocation,
  ] = useAsyncFn(async (payload) => {
    const response = await doUpdateLocation(payload);
    getLocations();
    return response;
  }, []);

  const [
    { error: updateZipcodeError, loading: updateZipcodeLoading, value: updateZipcodeValue },
    updateZipCode,
  ] = useAsyncFn(async (payload) => {
    const response = await doUpdateZipCodes(payload);

    return response;
  }, []);

  const [
    { error: createLocationError, loading: createLocationLoading, value: createLocationValue },
    createLocation,
  ] = useAsyncFn(async (payload) => {
    const response = await doCreateLocation(payload);

    return response;
  }, []);

  const [
    {
      error: UpdateLocationUsersError,
      loading: UpdateLocationUsersLoading,
      value: updateLocationUsersValue,
    },
    updateLocationUsers,
  ] = useAsyncFn(async (payload) => {
    const response = await doUpdateUsersForLocation(payload);

    return response;
  }, []);

  return [
    {
      getLocationsError,
      getLocationsLoading,
      locationsList,

      getLocationByIdError,
      getLocationByIdLoading,
      locationById,

      getUsersForLocationError,
      getUsersForLocationLoading,
      usersForLocation,

      getTimeSlotsError,
      getTimeSlotsLoading,
      timeSlotsList,

      getCenterUsersError,
      getCenterUsersLoading,
      centerUsersList,

      updateSlotError,
      UpdateSlotloading,
      updateSlotValue,

      updateSlotCapacityError,
      updateSlotCapacityloading,
      updateSlotCapacityValue,

      UpdateLocationError,
      UpdateLocationLoading,
      updateLocationValue,

      updateZipcodeError,
      updateZipcodeLoading,
      updateZipcodeValue,

      UpdateLocationUsersError,
      UpdateLocationUsersLoading,
      updateLocationUsersValue,

      getLocationItemsError,
      getLocationItemsLoading,
      LocationItemsList,

      getWardsForCenterError,
      getWardsForCenterLoading,
      WardsForCenterList,

      createLocationError,
      createLocationLoading,
      createLocationValue,
    },
    {
      doGetLocations,
      doGetTimeSlots,
      getLocationById,
      doGetLocationItems,

      doGetUsersforLocation,
      doGetCenterUsers,
      doGetWardsForCenter,
    },
    { updateSlot, updateLocation, updateLocationUsers, updateZipCode, updateSlotCapacity },
    { createLocation },
  ];
}

/** =====================================================
 * =================== LOAD Cash DATA =================
 * ===================================================== */

export function useCash() {
  const [fetchCash, doFetchCash] = useAsyncFn(async (params) => {
    const response = await getLocationBalance(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  });

  const [fetchLedger, doFetchLedger] = useAsyncFn(async (params) => {
    const response = await getAllLedgers(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  });

  const [createCash, doCreateCash] = useAsyncFn(async (payload) => {
    const response = await postAddCash(payload);
    return response;
  });

  const [fetchAdvance, doFetchAdvance] = useAsyncFn(async (params) => {
    const response = await getAdvancePayment(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);

  const [fetchLCustomerAdvance, doFetchCustomerAdvance] = useAsyncFn(async (params) => {
    const response = await getCustomerAdvance(params);
    // const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    const rowIndexCount = data.map((i, index) => {
      return index;
    });
    const totalCount = rowIndexCount.length;

    return { data, totalCount };
  });

  return [
    {
      cashValue: fetchCash.value,
      ledgerValue: fetchLedger.value,
      advanceValue: fetchAdvance.value,
      CustomerValue: fetchLCustomerAdvance.value,
      loading: createCash.loading || fetchAdvance.loading,
    },
    {
      doFetchCash,
      doFetchLedger,
      doCreateCash,
      doFetchAdvance,
      doFetchCustomerAdvance,
    },
  ];
}

/**--------------------------------------------
 * ============ Attendance HOOK =========
 * -------------------------------------------*/
export const useAttendance = () => {
  const [attStatState, getAttendanceStats] = useAsyncFn(async (params) => {
    const response = await doGetAttendanceStats(params);
    return response;
  }, []);

  const [attDptStatState, getAttendanceByDepartment] = useAsyncFn(async (params) => {
    const response = await doGetAttendanceByDepartment(params);
    return response;
  }, []);

  return [
    {
      error: attStatState.error || attDptStatState.error,
      loading: attStatState.loading || attDptStatState.loading,
      deptData: attDptStatState.value,
      attendanceData: attStatState.value,
    },
    { getAttendanceStats, getAttendanceByDepartment },
  ];
};

/**--------------------------------------------
 * ============ Inbound API =========
 * -------------------------------------------*/
export const useInbound = () => {
  const [
    { error: getBulkGeneratorsError, loading: getBulkGeneratorsLoading, value: bulkGeneratorsList },
    doGetBulkGenerators,
  ] = useAsyncFn(async (params) => {
    const response = await getBulkGeneratorsForInbound(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);

  const [
    { error: getLocationsByBGError, loading: getLocationsByBGLoading, value: locationsByBGList },
    doGetLocationsByBG,
  ] = useAsyncFn(async (params) => {
    const response = await getLocationsByBG(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);

  const [
    { error: getInboundItemsError, loading: getInboundItemsLoading, value: inboundItemsList },
    doGetInboundItems,
  ] = useAsyncFn(async (params) => {
    const response = await getInboundItems(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);

  const [
    { error: createPickupError, loading: createPickupLoading, value: createPickupValue },
    createPickupOrder,
  ] = useAsyncFn(async (payload) => {
    const response = await doCreatePickupOrder(payload);

    return response;
  }, []);

  return [
    {
      getBulkGeneratorsError,
      getBulkGeneratorsLoading,
      bulkGeneratorsList,
      getLocationsByBGError,
      getLocationsByBGLoading,
      locationsByBGList,
      getInboundItemsError,
      getInboundItemsLoading,
      inboundItemsList,
      createPickupError,
      createPickupLoading,
      createPickupValue,
    },
    { doGetBulkGenerators, doGetLocationsByBG, doGetInboundItems },
    { createPickupOrder },
  ];
};

/** =====================================================
 * =================== Customers =================
 * ===================================================== */

export function useCustomers() {
  const [fetchCustomer, doFetchCustomer] = useAsyncFn(async (params) => {
    const response = await getCustomersData(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  });

  const [downloadCustomer, doDownloadCustomer] = useAsyncFn(async (params) => {
    const response = await downloadCustomersData(params);
    const data = response?.data || [];
    return data;
  });

  const [fetchLogVehicle, doFetchLogVehicle] = useAsyncFn(async (params) => {
    const response = await getLogisticsVehicle(params);
    return response;
  });

  const [fetchCustomerDetails, doFetchCustomerDetails] = useAsyncFn(async (params) => {
    const response = await getCustomerDetails(params);
    const data = response?.data || [];
    return data;
  });

  const [fetchTrackVisits, doGetTrackVisits] = useAsyncFn(async (params) => {
    const response = await getTrackVisits(params);
    const data = response?.data || [];
    return data;
  });

  const [fetchPurchaseOrders, doFetchPurchaseOrders] = useAsyncFn(async (params) => {
    const response = await getPurchaseOrders(params);
    const data = response?.data || [];
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    return { data, totalCount };
  });

  const [fetchCustomerLocation, doFetchCustomerLocation] = useAsyncFn(async (params) => {
    const response = await getCustomerLocation(params);
    const data = response?.data || [];
    const rowIndexCount = data.map((i, index) => {
      return index;
    });
    const totalCount = rowIndexCount.length;
    return { data, totalCount };
  });

  const [updatedBasicInfo, doUpdatedBasicInfo] = useAsyncFn(async (payload) => {
    const response = await updateBasicInfo(payload);
    return response;
  }, []);

  const [updateContactInfo, doUpdateContactInfo] = useAsyncFn(async (payload) => {
    const response = await updateContactPersonInfo(payload);
    return response;
  }, []);

  const [updateLocationDetails, doUpdateLocationDetails] = useAsyncFn(async (payload) => {
    const response = await updateLocationDetailsInfo(payload);
    return response;
  }, []);

  const [updatedAddressInfo, doUpdatedAddressInfo] = useAsyncFn(async (payload) => {
    const response = await updateCustomerAddress(payload);
    return response;
  }, []);

  const [fetchProfileAttributes, doFetchProfileAttributes] = useAsyncFn(async (params) => {
    const response = await getSarveksenProfileAttributes(params);
    const data = response?.data || [];
    return data;
  });

  const [fetchProfileWaste, doFetchProfileWaste] = useAsyncFn(async (params) => {
    const response = await getSarveksenProfileWaste(params);
    const data = response?.data || [];
    return data;
  });

  const [fetchSarveksenAddInfo, doFetchSarveksenAddInfo] = useAsyncFn(async (id) => {
    const response = await getSarveksenAddInfo(id);
    const data = response?.data || [];
    return data;
  });

  const [updateSarveksenAddInfo, doUpdateSarveksenAddInfo] = useAsyncFn(async (payload) => {
    const response = await updatedSarveksenAddInfo(payload);
    return response;
  }, []);

  const [updateNewBulkGenerator, doUpdateNewBulkGenerator] = useAsyncFn(async (payload) => {
    const response = await addNewBulkCustomers(payload);
    return response;
  });

  const [fetchFavoriteItemsData, doFetchFavoriteItemsData] = useAsyncFn(async (params) => {
    const response = await getFavoriteItemsData(params);
    const data = response?.data || [];
    const rowIndexCount = data.map((i, index) => {
      return index;
    });
    const totalCount = rowIndexCount.length;
    return { data, totalCount };
  });

  const [updateFavoriteItem, doUpdateFavoriteItem] = useAsyncFn(async (payload) => {
    const response = await addFavoriteItem(payload);
    return response;
  }, []);

  const [fetchFavoritePatnerItems, doFetchFavoritePatnerItems] = useAsyncFn(async (params) => {
    const response = await getFavoritePatnerItems(params);
    const data = response?.data || [];
    const rowIndexCount = data.map((i, index) => {
      return index;
    });
    const totalCount = rowIndexCount.length;
    return { data, totalCount };
  });

  const [generateCustomerId, doGenerateCustomerId] = useAsyncFn(async (params) => {
    const response = await getGeneratedCustomerId(params);
    const data = response?.data || [];
    return data;
  });

  const [addToNetwork, doAddToNetwork] = useAsyncFn(async (payload) => {
    const response = await updateToNetwork(payload);
    return response;
  }, []);
  return [
    {
      customerValue: fetchCustomer.value,
      customerLoading: fetchCustomer.loading,
      LogisticsValue: fetchLogVehicle.value,
      CustomerDetailsValue: fetchCustomerDetails.value,
      CustomerDetailsLoading: fetchCustomerDetails.loading,
      PurchaseOrdersValue: fetchPurchaseOrders,
      CustomerLocationValue: fetchCustomerLocation,
      CustomerBasicInfoValue: updatedBasicInfo,
      CustomerContactValue: updateContactInfo,
      CustomerBankDetailsValue: updateLocationDetails,
      CustomerAddressValue: updatedAddressInfo,
      SarveksenProfileAttributesValue: fetchProfileAttributes.value,
      SarveksenProfileAttributesLoading: fetchProfileAttributes.loading,
      SarveksenProfileWasteValue: fetchProfileWaste.value,
      SarveksenProfileWasteLoading: fetchProfileWaste.loading,
      SarveksenAddInfoValue: fetchSarveksenAddInfo.value,
      SarveksenAddInfoLoading: fetchSarveksenAddInfo.loading,
      updateSarveksenAddInfoValue: updateSarveksenAddInfo.value,
      updateSarveksenAddInfoLoading: updateSarveksenAddInfo.loading,
      NewBulkGeneratorValue: updateNewBulkGenerator,
      FavoriteItemDataValue: fetchFavoriteItemsData,
      FavoriteItemValue: updateFavoriteItem,
      patnerItemValue: fetchFavoritePatnerItems,
      GeneratedIdValue: generateCustomerId,
      trackVisitsValue: fetchTrackVisits,
      addToNetworkValue: addToNetwork,
      downloadCustomerValue: downloadCustomer.value,
      downloadCustomerLoading: downloadCustomer.loading,
    },
    {
      doFetchCustomer,
      doFetchLogVehicle,
      doFetchCustomerDetails,
      doFetchPurchaseOrders,
      doFetchCustomerLocation,
      doUpdatedBasicInfo,
      doUpdatedAddressInfo,
      doUpdateContactInfo,
      doUpdateLocationDetails,
      doFetchProfileAttributes,
      doFetchProfileWaste,
      doFetchSarveksenAddInfo,
      doUpdateSarveksenAddInfo,
      doUpdateNewBulkGenerator,
      doFetchFavoriteItemsData,
      doUpdateFavoriteItem,
      doFetchFavoritePatnerItems,
      doGenerateCustomerId,
      doGetTrackVisits,
      doAddToNetwork,
      doDownloadCustomer,
    },
  ];
}

/**--------------------------------------------
 * ============ Orders API =========
 * -------------------------------------------*/

export const useOrders = () => {
  const [fetchOrderState, fetchOrders] = useAsyncFn(async (_params, url) => {
    const params = transformParams(_params, FilterParamMap.ORDER);
    const response = await axios.get(url, { params });
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);

  const [fetchPOState, fetchPurchaseOrders] = useAsyncFn(async (params, url) => {
    const response = await axios.get(url, params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);

  const [fetchSOState, fetchSalesOrders] = useAsyncFn(async (_params, url) => {
    const params = transformParams(_params, FilterParamMap.ORDER);

    const response = await axios.get(url, params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);

  return [
    {
      error: fetchOrderState.error || fetchPOState.error || fetchSOState.error,
      loading: fetchOrderState.loading || fetchPOState.loading || fetchSOState.loading,
      value: fetchOrderState.value || fetchPOState.value || fetchSOState.value,
    },
    { fetchPurchaseOrders, fetchSalesOrders, fetchOrders },
  ];
};

export const useOrderDetails = () => {
  const [
    { error: getOrderByIdError, loading: getOrderByIdLoading, value: orderById },
    doGetOrderById,
  ] = useAsyncFn(async (params) => {
    const response = await getOrderById(params);

    const data = response?.data || [];
    return { data };
  }, []);

  const [
    { error: getOrderLogisticsError, loading: getOrderLogisticsLoading, value: orderLogistics },
    doGetOrderLogistics,
  ] = useAsyncFn(async (params) => {
    const response = await getOrderLogisticsById(params);

    const data = response?.data || [];
    return { data };
  }, []);

  const [
    { error: getOrderChangeLogsError, loading: getOrderChangeLogsLoading, value: orderChangeLogs },
    doGetOrderChangeLogs,
  ] = useAsyncFn(async (params) => {
    const response = await getOrderChangeLogsById(params);

    const data = response?.data || [];
    return { data };
  }, []);

  const [
    {
      error: getPickupOrderNotesError,
      loading: getPickupOrderNotesLoading,
      value: pickupOrderNotes,
    },
    doGetPickupOrderNotes,
  ] = useAsyncFn(async (params) => {
    const response = await getPickupOrderNotesById(params);
    const data = response?.data || [];
    return { data };
  }, []);

  const [
    { error: getTransportersError, loading: getTransportersLoading, value: transportersList },
    doGetTransporters,
  ] = useAsyncFn(async (params) => {
    const response = await getTranporters(params);

    const data = response?.data || [];
    return { data };
  }, []);

  const [
    { error: getVehiclesError, loading: getVehiclesLoading, value: vehiclesList },
    doGetVehicles,
  ] = useAsyncFn(async (params) => {
    const response = await getVehicles(params);

    const data = response?.data || [];
    return { data };
  }, []);

  const [
    { error: createLogisticsError, loading: createLogisticsLoading, value: createLogisticsValue },
    createLogistics,
  ] = useAsyncFn(async (payload) => {
    const response = await doCreateTransportLogistics(payload);

    return response;
  }, []);

  const [
    { error: updateLogisticsError, loading: updateLogisticsLoading, value: updateLogisticsValue },
    updateLogistics,
  ] = useAsyncFn(async (payload) => {
    const response = await doUpdateTransportLogistics(payload);

    return response;
  }, []);

  const [
    {
      error: createOrderNotesError,
      loading: createOrderNotesLoading,
      value: createOrderNotesValue,
    },
    createOrderNotes,
  ] = useAsyncFn(async (payload) => {
    // payload format == {payload:data,params:{orderId:1234}}
    const response = await doCreateOrderNotesById(payload);
    return response;
  }, []);

  const [
    {
      error: updateOrderActionError,
      loading: updateOrderActionLoading,
      value: updateOrderActionValue,
    },
    updateOrderAction,
  ] = useAsyncFn(async (payload) => {
    // payload format == {payload:data,params:{action:"change-time-slot"},contentType:"text/plain"}
    const response = await doUpdateOrderAction(payload);
    return response;
  }, []);

  const [
    { error: deleteOrderError, loading: deleteOrderLoading, value: deleteOrderValue },
    deleteOrder,
  ] = useAsyncFn(async (payload) => {
    const response = await doDeleteOrder(payload);
    return response;
  }, []);

  const [assignExecutiveValue, doAssignExecutive] = useAsyncFn(async (payload) => {
    const response = await updateAssignExecutive(payload);
    return response;
  }, []);

  const [verifyInvoiceValue, doVerifyInvoice] = useAsyncFn(async (payload) => {
    const response = await updateOrderPaymentStatus(payload);
    return response;
  }, []);

  const [makePaymentValue, doMakePayment] = useAsyncFn(async (payload) => {
    const response = await updateMakePayment(payload);
    return response;
  }, []);

  const [
    { error: getProcessStockError, loading: getProcessStockLoading, value: processStock },
    doGetProcessStock,
  ] = useAsyncFn(async (params) => {
    const response = await getProcessStock(params);
    const data = response?.data || [];
    return data;
  }, []);

  return [
    {
      getOrderByIdError,
      getOrderByIdLoading,
      orderById,
      getOrderLogisticsError,
      getOrderLogisticsLoading,
      orderLogistics,
      getOrderChangeLogsLoading,
      getOrderChangeLogsError,
      orderChangeLogs,
      getPickupOrderNotesError,
      getPickupOrderNotesLoading,
      pickupOrderNotes,
      getTransportersError,
      getTransportersLoading,
      transportersList,
      getVehiclesError,
      getVehiclesLoading,
      vehiclesList,
      assignExecutiveValue,
      verifyInvoiceValue,
      makePaymentValue,
      createLogisticsError,
      createLogisticsLoading,
      createLogisticsValue,
      updateLogisticsError,
      updateLogisticsLoading,
      updateLogisticsValue,
      createOrderNotesError,
      createOrderNotesLoading,
      createOrderNotesValue,
      updateOrderActionError,
      updateOrderActionValue,
      updateOrderActionLoading,
      deleteOrderError,
      deleteOrderLoading,
      deleteOrderValue,
      getProcessStockError,
      getProcessStockLoading,
      processStock,
    },
    {
      doGetOrderById,
      doGetOrderLogistics,
      doGetOrderChangeLogs,
      doGetPickupOrderNotes,
      doGetTransporters,
      doGetVehicles,
      doAssignExecutive,
      doVerifyInvoice,
      doMakePayment,
      doGetProcessStock,
    },
    { createLogistics, updateLogistics, createOrderNotes, updateOrderAction, deleteOrder },
  ];
};

/** =====================================================
 * ================= COLLECTION DASHBOARD =================
 * ===================================================== */
export const useBags = () => {
  const [bagTxnState, doFetchBagTxns] = useAsyncFn(async (params) => {
    const response = await fetchBagTxns(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);

  return [
    { error: bagTxnState.error, value: bagTxnState.value, loading: bagTxnState.loading },
    { doFetchBagTxns },
  ];
};

/** =====================================================
 * =================== LOAD OUTBOUND DATA =================
 * ===================================================== */

export const useOutbound = () => {
  const [
    { error: getOutboundItemsError, loading: getOutboundItemsLoading, value: outboundItemsList },
    doGetOutboundItems,
  ] = useAsyncFn(async (params) => {
    const response = await getOutboundItems(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  }, []);

  const [
    {
      error: createOutboundOrderError,
      loading: createOutboundOrderLoading,
      value: createOutboundOrderValue,
    },
    createOutboundOrder,
  ] = useAsyncFn(async (payload) => {
    const response = await doCreateSaleOrder(payload);
    return response;
  }, []);

  return [
    {
      getOutboundItemsError,
      getOutboundItemsLoading,
      outboundItemsList,
      createOutboundOrderError,
      createOutboundOrderLoading,
      createOutboundOrderValue,
    },
    { doGetOutboundItems },
    { createOutboundOrder },
  ];
};

/**
 * Document Hooks
 * @return Array
 */
export function useDocument() {
  const [{ error: signedError, loading: singing, value: singedValue }, doGetSignedUrl] = useAsyncFn(
    async (id) => {
      const response = await getSignedUrl(id);

      return response;
    }
  );

  const [
    { error: deleteError, loading: deleting, value: deleteValue },
    doDeleteDocument,
  ] = useAsyncFn(async (params) => {
    const response = await deleteDocument(params);
    return response;
  });

  const [
    { error: attachError, loading: attaching, value: attachValue },
    doAttachDocument,
  ] = useAsyncFn(async (id) => {
    const response = await attachDocument(id);
    return response;
  });

  const [
    { error: changeDocError, loading: changingDoc, value: changeDocValue },
    doChangeDocType,
  ] = useAsyncFn(async (payload) => {
    const response = await changeDocumentType(payload);
    return response;
  });

  const [fetchInvoiceProforma, doGetInvoiceProforma] = useAsyncFn(async (id) => {
    const response = await getInvoiceProforma(id);
    const data = response?.data || [];
    return data;
  });

  return [
    {
      changeDocError,
      changingDoc,
      changeDocValue,
      deleteError,
      deleting,
      deleteValue,
      signedError,
      singing,
      singedValue,
      attachError,
      attaching,
      attachValue,
      fetchInvoiceProforma,
    },
    { doDeleteDocument, doAttachDocument, doGetSignedUrl, doChangeDocType, doGetInvoiceProforma },
  ];
}

/** =====================================================
 * =================== Catalog =================
 * ===================================================== */

export function useCatalogList() {
  const [fetchPartners, doFetchPartnerItems] = useAsyncFn(async (params) => {
    const response = await fetchPartnerItems(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  });

  const [LocationStatusValue, updateLocationStatus] = useAsyncFn(async (payload) => {
    const response = await doUpdateManageRateStatus(payload);
    return response;
  }, []);

  const [fetchItemDetails, doFetchItemDetails] = useAsyncFn(async (params) => {
    const response = await getItemDetails(params);
    const data = response?.data || [];
    return data;
  });

  const [fetchOnlyItemDetails, doFetchOnlyItemDetails] = useAsyncFn(async (params) => {
    const response = await getOnlyItemDetails(params);
    const data = response?.data || [];
    return data;
  });

  const [fetchLocationList, doFetchLocationList] = useAsyncFn(async (params) => {
    const response = await getManageRateLocation(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  });

  const [deleteItem, doDeleteItem] = useAsyncFn(async (params) => {
    const response = await removePartnerItem(params);
    const data = response?.data || [];
    return data;
  });

  const [LocationPriceValue, updateLocationPrice] = useAsyncFn(async (payload) => {
    const response = await doUpdateItemLocationPrice(payload);
    return response;
  }, []);

  const [fetchCatalogCategories, doFetchCatalogCategories] = useAsyncFn(async (params) => {
    const response = await getCatalogCategories(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  });

  const [uploadRecords, doFetchUploadRecords] = useAsyncFn(async (params) => {
    const response = await fetchUploadRecords(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  });

  const [uploadRecordsById, doFetchUploadRecordsById] = useAsyncFn(async (params) => {
    const response = await fetchUploadRecordsById(params);
    return response;
  });

  const [downloadRecords, doFetchDownloadRecords] = useAsyncFn(async (params) => {
    const response = await fetchDownloadRecords(params);
    const totalCount = Number(response?.headers['x-total-count']) || 0;
    const data = response?.data || [];
    return { data, totalCount };
  });

  const [uploadSample, doFetchUploadSample] = useAsyncFn(async (payload) => {
    const response = await fetchUploadSample(payload);
    return response;
  }, []);

  const [uploadRecord, deleteUploadRecord] = useAsyncFn(async (payload) => {
    const response = await doDeleteUploadRecord(payload);
    return response;
  }, []);

  const [downloadRecord, deleteDownloadRecord] = useAsyncFn(async (payload) => {
    const response = await doDeleteDownloadRecord(payload);
    return response;
  }, []);

  const [fetchSearchItems, doFetchSearchItems] = useAsyncFn(async (params) => {
    const response = await getSearchItems(params);
    const data = response?.data || [];
    return data;
  });

  const [fetchRateCards, doFetchRateCards] = useAsyncFn(async (params) => {
    const response = await getRateCardsFilter(params);
    return response;
  });

  const [importRateCards, onImportRateCards] = useAsyncFn(async (payload) => {
    const response = await doImportRateCards(payload);
    return response;
  }, []);

  return [
    {
      partnerItemsValue: fetchPartners.value,
      partnerItemsLoading: fetchPartners.loading,
      itemDetailsValue: fetchItemDetails.value,
      itemDetailsLoading: fetchItemDetails.loading,
      locationItemPriceValue: LocationStatusValue.value,
      fetchLocationListValue: fetchLocationList.value,
      fetchLocationListLoading: fetchLocationList.loading,
      deleteItemValue: deleteItem.value,
      locationPriceValue: LocationPriceValue.value,
      locationPriceLoading: LocationPriceValue.loading,
      fetchCatalogCategoriesValue: fetchCatalogCategories.value,
      fetchOnlyItemDetailsValue: fetchOnlyItemDetails.value,
      uploadRecordsValue: uploadRecords.value,
      uploadRecordsLoading: uploadRecords.loading,
      downloadRecordsValue: downloadRecords.value,
      downloadRecordsLoading: downloadRecords.loading,
      uploadSample,
      uploadRecord,
      downloadRecord,
      fetchSearchItemsValue: fetchSearchItems.value,
      fetchRateCardsValue: fetchRateCards.value,
      fetchRateCardsLoading: fetchRateCards.loading,
      uploadRecordsById,
      importRateCards,
    },
    {
      doFetchPartnerItems,
      doFetchItemDetails,
      updateLocationStatus,
      doFetchLocationList,
      doDeleteItem,
      updateLocationPrice,
      doFetchCatalogCategories,
      doFetchOnlyItemDetails,
      doFetchUploadRecords,
      doFetchDownloadRecords,
      doFetchUploadSample,
      deleteUploadRecord,
      deleteDownloadRecord,
      doFetchSearchItems,
      doFetchRateCards,
      doFetchUploadRecordsById,
      onImportRateCards,
    },
  ];
}
