import './PromptModal.scss';

import classNames from 'classnames';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import { CountDownTimer } from '../CountDownTimer';
import { CanShow } from '../GenericComponent';

/**
 * It's a modal that displays a title, text, and two buttons
 * @param props - {
 * @returns A modal component that can be used to prompt the user for a yes or no answer.
 */
const PromptModal = (props) => {
  const {
    show,
    onHide,
    title,
    text,
    hideDismissBtn,
    hideSuccessBtn,
    dismissBtnText,
    successBtnText,
    dismissBtnClass,
    successBtnClass,
    onDismiss,
    onSuccess,
    variant,
    size = 'md',
    showCountDown = false,
    countDownTime = 5,
  } = props;

  const [disableConfirm, setDisableConfirm] = useState(showCountDown);

  return (
    <Modal show={show} onHide={onDismiss} className="PromptModal" centered size={size}>
      <Modal.Header closeButton>
        <Modal.Title>{title || ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre>{text}</pre>
      </Modal.Body>
      <Modal.Footer>
        {!hideDismissBtn && (
          <Button
            className={classNames('btn-wide', dismissBtnClass)}
            variant="secondary"
            onClick={onDismiss}>
            {dismissBtnText || 'No'}
          </Button>
        )}
        {!hideSuccessBtn && (
          <>
            <Button
              disabled={disableConfirm}
              className={classNames('btn-wide', successBtnClass)}
              variant={variant || 'primary'}
              onClick={onSuccess}>
              {successBtnText || 'Yes'}
            </Button>
            <CanShow show={showCountDown && disableConfirm}>
              <CountDownTimer
                onCounterStop={() => setDisableConfirm(false)}
                countDownTime={countDownTime}
              />
            </CanShow>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default PromptModal;
