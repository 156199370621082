/* eslint-disable max-len */
import React from 'react';

const OutboundOrdersIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 23">
      <g>
        <path d="M15.4,22.7l-7.2-8.9l-6.4,8.8l-1.6-1.2l8-10.9l6.8,8.5l5.6-12.1l-4.2,1.6l-0.7-1.9l6.5-2.5l1.3,0.5l2.5,6l-1.8,0.8l-1.6-3.9L15.4,22.7z M26,2H0V0h26V2z" />
      </g>
    </svg>
  );
};

export default OutboundOrdersIcon;
