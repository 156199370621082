import classnames from 'classnames';
import React from 'react';
import { Spinner } from 'react-bootstrap';

const overlayStyle = {
  justifyContent: 'center',
  position: 'absolute',
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  top: 0,
  alignItems: 'center',
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.1)',
  zIndex: 2,
};
const Loader = ({ className, variant, size, hideText, text, overlay }) => {
  return (
    <div
      style={overlay ? overlayStyle : {}}
      className={classnames('animate__animated animate__fadeIn pt-3 text-center', className)}>
      <Spinner
        as="span"
        animation="border"
        size={size || 'lg'}
        role="status"
        variant={variant || 'primary'}
        aria-hidden="true"
      />
      <h5 className="mt-2">{!hideText && (text || 'Loading')}</h5>
    </div>
  );
};

export default Loader;
