import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { SYMBOL } from '../utils/paisa';
import CurrencyInput from './CurrancyInput';

const FormField = ({
  hide,
  formik,
  name,
  children,
  errorMessage,
  label,
  required,
  labelClass,
  ...props
}) => {
  return (
    !hide && (
      <>
        {label && (
          <Form.Label className={labelClass}>
            {label} {required && <span style={{ color: 'red' }}>*</span>}
          </Form.Label>
        )}
        {children || (
          <FormControl
            name={name}
            isInvalid={!!formik?.errors[name] && formik?.touched[name]}
            {...props}
            autoComplete="off"
          />
        )}
      </>
    )
  );
};

const AmountField = ({
  formik,
  name,
  children,
  onChange,
  className,
  value,
  invalidPrice,
  ...props
}) => {
  function handleChange(event, a, b) {
    const amount = event.target.value?.replace(/[^0-9.]/gi, '') || '';
    const _event = {
      ...event,
      target: { ...event.target, name: event.target.name, value: amount },
    };
    if (formik) {
      formik.setFieldValue(name, amount);
    }
    // eslint-disable-next-line no-unused-expressions
    onChange && onChange(_event);
  }

  const isInvalid = invalidPrice || (!!formik?.errors[name] && formik?.touched[name]);
  return (
    <>
      <FormField isInvalid={isInvalid} name={name} {...props}>
        <CurrencyInput
          name={name}
          className={classNames('form-control', {
            'is-invalid': isInvalid,
          })}
          placeholder={`${SYMBOL}0.00`}
          onChange={handleChange}
          value={value || ''}
          {...props}
        />
      </FormField>
    </>
  );
};

FormField.propTypes = {};

export { FormField, AmountField };
