import { intersection, isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useStakeholders } from '../../hooks';

const HasAuthority = (props) => {
  const { hasAuth } = useAuthority();
  const { authorities, noAuthorities, failureComponent, hasEdit = true, children } = props;

  return <>{hasAuth(authorities, noAuthorities, hasEdit) ? children : failureComponent || null}</>;
};

HasAuthority.propTypes = {
  authorities: PropTypes.array,
  noAuthorities: PropTypes.array,
  failureComponent: PropTypes.any,
};

export default HasAuthority;

/**
 * Hook useful to check whether user has given permission, auth or roles
 * @return {{hasAuth: (function(*=, *=): boolean|boolean), hasPermission: (function(*=): boolean),
 * hasPro: (function(): boolean), hasRoles: (function(*=): boolean)}}
 */
export const useAuthority = () => {
  const { currentAccount } = useStakeholders();
  const { authorities, readOnly } = currentAccount || {};
  const hasAuth = (auth, noAuthorities, hasEdit) => {
    return (
      (isEmpty(auth) || intersection(authorities, auth)?.length > 0) &&
      (isUndefined(hasEdit) || hasEdit === !readOnly) &&
      (isEmpty(noAuthorities) || intersection(authorities, noAuthorities)?.length <= 0)
    );
  };

  return { hasAuth };
};
