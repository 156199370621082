import { FaArrowLeft } from '@react-icons/all-files/fa/FaArrowLeft';
import classNames from 'classnames';
import React from 'react';
import { Badge, Button, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import downloadImg from '../../assets/img/download_icon.svg';
import resetImg from '../../assets/img/reset_icon.svg';
import { useAppConfig, useCatalog } from '../../hooks';
import useResponsive from '../../hooks/useResponsive';
import { toRupees } from '../utils/Helper';
import IconButton from './Buttons/IconButton';

const OverlayCell = ({ id, overlay, hideOverlay, children, ...restProps }) => {
  if (hideOverlay) return children;
  return (
    <OverlayTrigger
      trigger={['hover', 'hover']}
      key="bottom"
      placement="top"
      rootClose
      overlay={
        <Popover id={id}>
          <Popover.Content>{overlay}</Popover.Content>
        </Popover>
      }
      {...restProps}>
      {children}
    </OverlayTrigger>
  );
};
const CheckboxCell = (props) => {
  const {
    getToggleSelectionProps,
    isCheckboxDisabled,
    isAllSelected,
    setAllSelected,
    ...restProps
  } = props;
  return (
    <div>
      <input {...getToggleSelectionProps()} type="checkbox" {...restProps} />
    </div>
  );
};

const Region = ({ cityId, stateId, className, hideOverlay = false }) => {
  const { citiesMap, statesMap } = useAppConfig();
  const city = citiesMap ? citiesMap[cityId] || statesMap[stateId] : {};
  const overlay = <div>{[city?.name, city?.parent?.name].filter(Boolean).join(', ') || 'NA'}</div>;
  return (
    <OverlayCell hideOverlay={hideOverlay} overlay={overlay}>
      <span className={className}>
        {[city?.name, city?.parent?.code]?.filter(Boolean)?.join(', ') || 'NA'}
      </span>
    </OverlayCell>
  );
};

const Amount = (props) => {
  if (props.isSaleInfo && props.value) {
    props.setLatestAmount(props.value);
    props.setIsSaleInfo(false);
  }

  const { value = 0, prefix = '₹', className, postfix, mini = true, decimal = 2, ...rest } = props;
  return (
    <span className="align-items-baseline">
      {value < 0 && '-'}
      {prefix && <span style={{ zoom: mini ? 0.6 : 1, fontFamily: 'arial' }}>{prefix}</span>}
      <span className={classNames('amount', className)} {...rest}>
        {toRupees(Math.abs(value), false, decimal)}
      </span>
      {postfix && <span style={{ zoom: mini ? 0.6 : 1 }}>{postfix}</span>}
    </span>
  );
};

const Items = ({ value }) => {
  const { items } = useCatalog();
  const itemArr = items !== null ? value?.map(({ itemId }) => items[itemId]) : [];
  return (
    <div>
      {itemArr[0]?.name}{' '}
      {itemArr.length > 1 && (
        <OverlayCell overlay={<div>{itemArr.map((a) => a.name)?.join(', ')}</div>}>
          <Badge className="rounded">+{itemArr?.length - 1}</Badge>
        </OverlayCell>
      )}
    </div>
  );
};

const StatePrices = ({ value, className }) => {
  return (
    <div>
      <OverlayCell
        placement="bottom-start"
        overlay={
          <div style={{ width: '180px' }}>
            <h6 className="mb-2">Local Rates</h6>
            {value.map((res, idx) => (
              <div>
                <Row>
                  <Col md="7" className="float-left">
                    {res?.states?.map((a, j) => (
                      <span key={j} variant="primary" className="mr-2">
                        <Region stateId={a?.id} />
                        {j < res?.states?.length - 1 ? ', ' : ' '}
                        <br />
                      </span>
                    ))}
                  </Col>
                  <Col md="5" className="float-right">
                    <Amount style={{ color: '#1792c7' }} value={Number(res?.price)} />
                  </Col>
                </Row>
                {idx < value.length - 1 && <hr className={className} />}
              </div>
            ))}
          </div>
        }>
        <Badge className="rounded">{value?.length}</Badge>
      </OverlayCell>
    </div>
  );
};

const States = ({ value, displayCount = 2 }) => {
  const { statesMap } = useAppConfig();
  const stateArr = statesMap !== null ? value?.map((stateId) => statesMap[stateId]) : [];
  const staticStates = stateArr?.slice(0, displayCount);
  const overlayStates = stateArr?.slice(displayCount, stateArr.length);
  return (
    <div>
      {staticStates?.map((a, j) => a?.name).join(', ')}{' '}
      {stateArr?.length > displayCount && (
        <OverlayCell overlay={<div>{overlayStates?.map((a, j) => a?.name).join(', ')}</div>}>
          <Badge className="rounded">+{overlayStates?.length}</Badge>
        </OverlayCell>
      )}
    </div>
  );
};

const Address = ({ value, short = false, className }) => {
  const address = [value?.flatBuilding, value?.landmark, value?.street].filter(Boolean).join(', ');
  // address = startCase(capitalize(address));
  return short ? (
    <OverlayCell hideOverlay={!address} placement="bottom" overlay={<div>{address}</div>}>
      <div>
        <Region className={className} hideOverlay cityId={value?.regionId || value?.region?.id} />
      </div>
    </OverlayCell>
  ) : (
    <OverlayCell hideOverlay={!address} placement="bottom" overlay={<div>{address}</div>}>
      <div className={className}>{address || 'NA'}</div>
    </OverlayCell>
  );
};

const BackButton = ({ className }) => {
  const history = useHistory();
  return (
    <Button
      className={classNames('p-0', className)}
      style={{ width: '45px' }}
      size="sm"
      variant="link"
      onClick={() => history.goBack()}>
      <FaArrowLeft /> Back
    </Button>
  );
};

const RefreshButton = ({ onClick = () => undefined, iconTitle = 'Refresh', isDisable = false }) => {
  return (
    <IconButton
      iconImg={<img src={resetImg} alt="Refresh" />}
      onClick={onClick}
      iconTitle={iconTitle}
      isDisable={isDisable}
    />
  );
};

const DownloadButton = ({
  onClick = () => undefined,
  iconTitle = 'Download',
  isDisable = false,
}) => {
  return (
    <IconButton
      iconImg={<img src={downloadImg} alt="Download" />}
      onClick={onClick}
      iconTitle={iconTitle}
      isDisable={isDisable}
    />
  );
};

const CanShow = React.memo(({ show, children, fallbackComponent }) => {
  return show ? children : fallbackComponent || null;
});

const HasMobile = ({ children }) => {
  const { isMobile } = useResponsive();
  return isMobile ? children : null;
};

const HasDesktop = ({ children }) => {
  const { isMobile } = useResponsive();
  return !isMobile ? children : null;
};

export const DelayBadge = ({ color, title }) => (
  <span className="px-2">
    <Badge
      variant="dark"
      style={{
        backgroundColor: color,
        width: 16,
        height: 16,
        borderRadius: 50,
      }}>
      &nbsp;
    </Badge>
    <span className="px-2" style={{ verticalAlign: 'middle' }}>
      {title}
    </span>
  </span>
);

export {
  CheckboxCell,
  Region,
  OverlayCell,
  Amount,
  Items,
  Address,
  BackButton,
  CanShow,
  HasMobile,
  HasDesktop,
  States,
  StatePrices,
  RefreshButton,
  DownloadButton,
};
