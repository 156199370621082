import { keyBy } from 'lodash';
import { useSelector } from 'react-redux';

export default function useStakeholders() {
  const {
    currentAccount,
    isCurrentUser,
    accountUsers,
    recyclers,
    users,
    wasteCollectors,
    wasteCollectorsMap,
    pickupExecutives,
  } = useSelector(({ user, customer, kamUser, accountUser }) => {
    return {
      currentAccount: user.accountDetails,
      accountUsers: keyBy(accountUser?.accountUsers, 'userId'),
      isCurrentUser: (id) => id === currentAccount?.id,
      recyclers: keyBy(user?.recyclers, 'userId'),
      wasteCollectors: user?.wasteCollectors,
      wasteCollectorsMap: keyBy(user?.wasteCollectors, 'userId'),
      users: { ...keyBy(user?.users, 'userId'), [user?.accountDetails?.id]: user.accountDetails },
      pickupExecutives: user?.pickupExecutives,
    };
  });

  return {
    currentAccount,
    isCurrentUser,
    accountUsers,
    recyclers,
    users,
    wasteCollectors,
    wasteCollectorsMap,
    pickupExecutives,
  };
}
