import './assets/scss/style.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryParamProvider } from 'use-query-params';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';

ReactDOM.render(
  <>
    <ToastContainer autoClose={3000} position="top-center" hideProgressBar closeOnClick />
    <ReduxProvider store={store}>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <App />
        </QueryParamProvider>
      </BrowserRouter>
    </ReduxProvider>
  </>,
  document.getElementById('root')
);

// use to remove old version service worker
navigator?.serviceWorker?.getRegistrations()?.then((registrations) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const registration of registrations) {
    registration?.unregister();
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
