import React from 'react';

import { AUTHORITY } from './shared/constants/Constants';
import OrdersRoutes from './views/Orders';

const UsersManagementPage = React.lazy(() => import('./views/UsersPage/UsersManagementPage'));
const HomePage = React.lazy(() => import('./views/Home/HomePage'));
const ReportsPage = React.lazy(() => import('./views/ReportsPage/ReportsPage'));
const CashManagement = React.lazy(() => import('./views/CashManagement/CashManagement'));
const CashPaymentView = React.lazy(() => import('./features/Cash/CashPaymentView'));
const PickupManagementRouting = React.lazy(() =>
  import('./views/PickupManagement/PickupManagementRouting')
);
const CustomerDetails = React.lazy(() => import('./views/Customer/CustomerDetails'));
const IndividualGeneratorTabs = React.lazy(() =>
  import('./views/Customer/IndividualGeneratorTabs')
);
const WasteCollectorTabs = React.lazy(() => import('./views/Customer/WasteCollectorTabs'));
const RecyclerTabs = React.lazy(() => import('./views/Customer/RecyclerTabs'));
const AggregatorTabs = React.lazy(() => import('./views/Customer/AggregatorTabs'));
const CustomerManagement = React.lazy(() => import('./views/Customer/CustomerManagement'));
const AggregatorBusinessModal = React.lazy(() =>
  import('./views/Customer/AggregatorBusinessModal')
);
const RecyclerBusinessModal = React.lazy(() => import('./views/Customer/RecyclerBusinessModal'));
const CatalogPage = React.lazy(() => import('./views/Catalog/CatalogPage'));
const ViewItemDetailsPage = React.lazy(() => import('./views/Catalog/ViewItemDetailsPage'));
const EditItemDetailsPage = React.lazy(() => import('./views/Catalog/EditItemDetailsPage'));
const AgencyManagementRouting = React.lazy(() =>
  import('./views/AgencyManagement/AgencyManagementRouting')
);
const VehiclesRouting = React.lazy(() => import('./views/VehiclesPage/VehiclesRouting'));
const DriversRouting = React.lazy(() => import('./views/DriversPage/DriversRouting'));
const WardsRouting = React.lazy(() => import('./views/WardsPage/WardsRouting'));
const BulkWasteGeneratorRouting = React.lazy(() =>
  import('./views/BulkWasteGenerator/BulkWasteGeneratorRouting')
);
const BWGCashManagementRouting = React.lazy(() =>
  import('./views/BWGCashManagement/BWGCashManagementRouting')
);
const RoutesRouting = React.lazy(() => import('./views/RoutesPage/RoutesRouting'));
const TripsRouting = React.lazy(() => import('./views/TripsPage/TripsRouting'));
const ComplaintsRouting = React.lazy(() => import('./views/Complaints/FeedbackRouting'));
const BWGReportsPage = React.lazy(() => import('./views/BWGReportsPage/BWGReportsPage'));

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: HomePage,
    exact: true,
  },
  {
    path: '/customers/aggregators/add',
    name: 'Customers',
    component: AggregatorBusinessModal,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/customers/recyclers/add',
    name: 'Customers',
    component: RecyclerBusinessModal,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/customers/bulk-generators/:id',
    name: 'Customers',
    component: CustomerDetails,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/customers/individual-generators/:id',
    name: 'Customers',
    component: IndividualGeneratorTabs,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/customers/waste-collectors/:id',
    name: 'Customers',
    component: WasteCollectorTabs,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/customers/aggregators/:id',
    name: 'Customers',
    component: AggregatorTabs,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/customers/recyclers/:id',
    name: 'Customers',
    component: RecyclerTabs,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/customers',
    name: 'Customers',
    component: CustomerManagement,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/reports',
    name: 'Reports',
    component: ReportsPage,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/pickup-management',
    name: 'Pickup Management',
    component: PickupManagementRouting,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/cash-management',
    name: 'Customers',
    component: CashManagement,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/advancepayment/view',
    name: 'Customers',
    component: CashPaymentView,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/orders',
    name: 'Orders',
    component: OrdersRoutes,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/catalog/my-items/add-update-price/:itemId',
    name: 'View Item',
    component: EditItemDetailsPage,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/catalog/my-items/item-detail/:itemId',
    name: 'Edit Item',
    component: ViewItemDetailsPage,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: CatalogPage,
    noAuthorities: [AUTHORITY.SUPER_ADMIN, AUTHORITY.ALL_BWG_USERS],
  },
  {
    path: '/bulk-waste-generator',
    name: 'Bulk Waste Generator',
    component: BulkWasteGeneratorRouting,
    authorities: [
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.FINANCE_MANAGER,
      AUTHORITY.BWG_OPERATION_MANAGER,
      AUTHORITY.AGENCY_ADMIN,
    ],
  },
  {
    path: '/agency-management',
    name: 'Agency',
    component: AgencyManagementRouting,
    authorities: [
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.FINANCE_MANAGER,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
  {
    path: '/drivers-management',
    name: 'Drivers',
    component: DriversRouting,
    authorities: [
      AUTHORITY.AGENCY_ADMIN,
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
  {
    path: '/vehicles-management',
    name: 'Vehicles',
    component: VehiclesRouting,
    authorities: [
      AUTHORITY.AGENCY_ADMIN,
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
  {
    path: '/ward-management',
    name: 'Wards',
    component: WardsRouting,
    authorities: [
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.AGENCY_ADMIN,
      AUTHORITY.FINANCE_MANAGER,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
  {
    path: '/bwg-cash-management',
    name: 'BWG Cash Management',
    component: BWGCashManagementRouting,
    authorities: [
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.FINANCE_MANAGER,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },

  {
    path: '/bwg-reports',
    name: 'BWG Reports',
    component: BWGReportsPage,
    authorities: [
      AUTHORITY.AGENCY_ADMIN,
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
  {
    path: '/route',
    name: 'Routes',
    component: RoutesRouting,
    authorities: [
      AUTHORITY.AGENCY_ADMIN,
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
  {
    path: '/trips',
    name: 'Trips',
    component: TripsRouting,
    authorities: [
      AUTHORITY.AGENCY_ADMIN,
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
  {
    path: '/complaints',
    name: 'Feedback',
    component: ComplaintsRouting,
    authorities: [
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.BWG_OPERATION_MANAGER,
      AUTHORITY.AGENCY_ADMIN,
    ],
  },
  {
    path: '/users-management',
    name: 'User Management',
    component: UsersManagementPage,
    noAuthorities: [AUTHORITY.FINANCE_MANAGER],
    authorities: [
      AUTHORITY.FRANCHISE_USER,
      AUTHORITY.MUNICIPAL_ADMIN,
      AUTHORITY.BWG_OPERATION_MANAGER,
    ],
  },
];

export default routes;
