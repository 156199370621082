import axios from 'axios';
import QueryString from 'qs';

import { FilterParamMap } from '../shared/constants';
import ApiEndpoints from '../shared/constants/ApiEndpoints';
import { createQueryString, transformParams } from '../shared/utils/Helper';

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.headers.common.appCode = process.env.REACT_APP_CODE;

axios.interceptors.response.use(
  (response) =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response.status === 401 && error.config.url !== ApiEndpoints.AUTH.LOGIN) {
      // auto logout
      // localStorage.clear();
      localStorage.removeItem('userToken');
      setTimeout(() => window.location.reload(), 500);
    }
    throw error?.response;
  }
);

axios.interceptors.request.use((request) => {
  return request;
});

export async function login(payload) {
  const endpoint = ApiEndpoints.AUTH.LOGIN;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function resetPassword(payload) {
  const endpoint = ApiEndpoints.AUTH.REQUEST_RESET_PASSWORD;
  const response = (
    await axios.post(endpoint, payload, {
      headers: { 'Content-Type': 'text/plain' },
    })
  ).data;
  return response;
}

export async function createPasswordFromLink(payload) {
  const endpoint = ApiEndpoints.AUTH.CREATE_PASSWORD;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function fetchPrivacyPolicy(_params) {
  const endpoint = ApiEndpoints.AUTH.PRIVACY_POLICY;
  const response = (await axios.get(endpoint)).data;
  return response;
}

export async function fetchUserPolicy(_params) {
  const endpoint = ApiEndpoints.AUTH.USER_POLICY;
  const response = (await axios.get(endpoint)).data;
  return response;
}

export async function acceptUserPolicy(payload) {
  const endpoint = ApiEndpoints.AUTH.ACCEPT_USER_POLICY;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function fetchAccount() {
  const endpoint = ApiEndpoints.USER.ACCOUNT;
  const response = await axios.get(endpoint);
  const newToken = response.headers?.authorization;
  if (newToken) {
    return { ...response?.data, newToken };
  }
  return response?.data;
}

export async function changePassword(payload) {
  const endpoint = ApiEndpoints.USER.CHANGE_PASSWORD;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function updateAccount(payload) {
  const endpoint = ApiEndpoints.USER.ACCOUNT;
  const response = (await axios.put(endpoint, payload)).data;
  return response;
}

export async function fetchCitiesByPincode(params) {
  const endpoints = ApiEndpoints.CITY.ZIPCODE;
  return axios.get(endpoints, { params });
}

export async function initAppApis() {
  const endpoints = [
    axios.get(ApiEndpoints.INIT.ADMIN_CONFIG),
    axios.get(ApiEndpoints.INIT.BUSINESS_TYPES),
    axios.get(ApiEndpoints.INIT.GET_CITIES),
    axios.get(ApiEndpoints.INIT.THEME),
  ];
  const response = await axios.all(endpoints);
  return response;
}

const recyclerParams = {
  'dummyCustomer.equals': false,
  'myNetwork.equals': true,
  size: 1000,
  page: 0,
  sort: 'user.createdDate,desc',
  'businessTypeId.equals': 18,
  'canBuy.equals': true,
  'canSale.equals': false,
};

const wasteCollectorParams = {
  'dummyCustomer.equals': false,
  'myNetwork.equals': true,
  totalCount: 0,
  size: 1000,
  page: 0,
  sort: 'user.createdDate,desc',
  'canBuy.equals': false,
  'canSale.equals': true,
  'wasteCollectors.equals': true, // waste-collectors
};

export async function initPostAuthApis() {
  const endpoints = [
    axios.get(ApiEndpoints.INIT.PARTNER_WARDS),
    axios.get(ApiEndpoints.CATALOG.GET_ITEMS),
    axios.get(ApiEndpoints.LOCATION.GET, { params: { size: 10000 } }),
    axios.get(ApiEndpoints.CATALOG.GET_CATEGORIES, { params: { onlyRootCategory: true } }),
    axios.get(ApiEndpoints.USERS.FETCH, { params: { size: 1000 } }),
    axios.get(ApiEndpoints.INIT.DEPARTMENTS, { params: { size: 10000 } }),
    axios.get(ApiEndpoints.INIT.DESIGNATIONS, { params: { size: 10000 } }),
    axios.get(ApiEndpoints.INIT.PICKUP_EXECUTIVE),
  ];
  const response = await axios.all(endpoints);
  return response;
}

export async function initDummyCustomerDataApis() {
  const endpoints = [
    axios.get(ApiEndpoints.CUSTOMERS.GET, { params: recyclerParams }),
    axios.get(ApiEndpoints.CUSTOMERS.GET, { params: wasteCollectorParams }),
  ];
  const response = await axios.all(endpoints);
  return response;
}

/**
 * DOCUMENT APIS
 */
export async function getSignedUrl(fileDetails, type) {
  const url = ApiEndpoints.DOCUMENT.SIGNED_URL_STAKEHOLDER;
  return (await axios.post(url, fileDetails)).data;
}

export async function deleteDocument(params) {
  const endpoints = ApiEndpoints.DOCUMENT.DELETE_DOC;
  const queryString = createQueryString(params);
  const URL = `${endpoints}${queryString}`;
  return axios.put(URL);
}

export async function attachDocument(fileDetails) {
  const url =
    fileDetails.entity === 'RATECARD'
      ? ApiEndpoints.DOCUMENT.ATTACH_DOCUMENT_CATALOG
      : ApiEndpoints.DOCUMENT.ATTACH_DOCUMENT;
  return (await axios.post(url, fileDetails)).data;
}

export async function changeDocumentType(payload) {
  const url = ApiEndpoints.DOCUMENT.CHANGE_DOC_TYPE;
  return (await axios.put(url, payload)).data;
}

export async function getInvoiceProforma(id) {
  const endpoints = ApiEndpoints.DOCUMENT.PROFORMA_INVOICE;
  const url = `${endpoints}/${id}`;
  return axios.get(url, { responseType: 'blob' });
}

// =====================

export async function fetchUsers(_params) {
  const endpoints = ApiEndpoints.USERS.FETCH;
  const params = transformParams(_params, FilterParamMap.USERS);
  return axios.get(endpoints, { params });
}

export async function doCreateUser(payload) {
  const endpoints = ApiEndpoints.USERS.CREATE;
  return (await axios.post(endpoints, payload)).data;
}

export async function doUpdateUser(payload) {
  const endpoints = ApiEndpoints.USERS.UPDATE;
  return (await axios.put(endpoints, payload)).data;
}

/**
 * DASHBOARD APIS
 */
export async function getLocations(params) {
  const endpoints = ApiEndpoints.DASHBOARD.LOCATIONS;
  return axios.get(endpoints, { params });
}

export async function fetchStockStacks(params) {
  const endpoints = ApiEndpoints.DASHBOARD.MATERIAL_STOCK_STATS;
  return axios.get(endpoints, { params });
}

export async function fetchCashDashboard(params) {
  const endpoints = ApiEndpoints.DASHBOARD.CASH_DASHBOARD;
  return axios.get(endpoints, { params });
}

export async function fetchStockAdjustedStats(params) {
  const endpoints = ApiEndpoints.DASHBOARD.STOCK_ADJUSTED_STATS;
  return axios.get(endpoints, { params });
}

export async function fetchAvailableStockStats(params) {
  const endpoints = ApiEndpoints.DASHBOARD.AVAILABLE_STOCK_STATS;
  return axios.get(endpoints, { params });
}

export async function fetchTrendStats(params) {
  const endpoints = ApiEndpoints.DASHBOARD.TREND_STATS;
  return axios.get(endpoints, { params });
}

export async function fetchOrderDashboard(params) {
  const endpoints = ApiEndpoints.DASHBOARD.ORDER_DASHBOARD;
  return axios.get(endpoints, { params });
}

export async function fetchWasteCollectorsDashboard(params) {
  const endpoints = ApiEndpoints.DASHBOARD.WASTE_COLLECTORS_DASHBOARD;
  return axios.get(endpoints, { params });
}

export async function fetchMaterialTrends(params) {
  const endpoints = ApiEndpoints.DASHBOARD.MATERIAL_TRENDS;
  return axios.get(endpoints, { params });
}

export async function fetchOrderTrendsTrends(params) {
  const endpoints = ApiEndpoints.DASHBOARD.ORDER_TRENDS;
  return axios.get(endpoints, { params });
}

export async function fetchMaterialBySource(params) {
  const endpoints = ApiEndpoints.DASHBOARD.MATERIAL_BY_SOURCE;
  return axios.get(endpoints, { params });
}

export async function fetchMaterialByBuyer(params) {
  const endpoints = ApiEndpoints.DASHBOARD.MATERIAL_BY_BUYER;
  return axios.get(endpoints, { params });
}

export async function fetchMarketplaceStats(params) {
  const endpoints = ApiEndpoints.DASHBOARD.MARKETPLACE_STATS;
  return axios.get(endpoints, {
    params,
    paramsSerializer: (p) => QueryString.stringify(p, { arrayFormat: 'repeat' }),
  });
}

export async function fetchEprOrderStats(params) {
  const endpoints = ApiEndpoints.DASHBOARD.EPR_ORDER_STATS;
  return axios.get(endpoints, { params });
}

export async function fetchEprDeliveriesStats(params) {
  const endpoints = ApiEndpoints.DASHBOARD.EPR_DELIVERY_STATS;
  return axios.get(endpoints, { params });
}

export async function fetchEprInvoicesStats(params) {
  const endpoints = ApiEndpoints.DASHBOARD.EPR_INVOICES_STATS;
  return axios.get(endpoints, { params });
}

export async function fetchCorporateCollectionStats(params) {
  const endpoints = [
    axios.get(ApiEndpoints.DASHBOARD.PICKUP_ORDER_STATS, { params }),
    axios.get(ApiEndpoints.DASHBOARD.PICKUP_EXECUTION_STATS, { params }),
  ];
  return Promise.allSettled(endpoints).then(([{ value: orders }, { value: execution }]) => ({
    orders: orders?.data || [],
    execution: execution?.data || [],
  }));
}

/**
 * BAG DASHBOARD
 */
export async function fetchBagStats(params) {
  return axios.get(ApiEndpoints.DASHBOARD.BAG_STATS, { params });
}
export async function fetchBagWeightTrend(params) {
  return axios.get(ApiEndpoints.DASHBOARD.BAG_WEIGHT_TREND, { params });
}
export async function fetchBagsWith1User(params) {
  return axios.get(ApiEndpoints.DASHBOARD.USER_WITH_1BAG_TREND, { params });
}
export async function fetchBagContaminatedTrend(params) {
  return axios.get(ApiEndpoints.DASHBOARD.BAG_CONTAMINATED_TREND, { params });
}
export async function fetchBagsComparisonOverview(params) {
  return axios.get(ApiEndpoints.DASHBOARD.BAG_COMPARISON_OVERVIEW, { params });
}

/**
 * REPORTS APIS
 */
export async function getAllPartnerWards(params) {
  const endpoints = ApiEndpoints.REPORTS.PARTNER_WARDS;
  return axios.get(endpoints, { params });
}

export async function getAllPartnerItems(params) {
  const endpoints = ApiEndpoints.REPORTS.PARTNER_ITEMS;
  return axios.get(endpoints, { params });
}

export async function getInwardReportList(params) {
  const endpoints = ApiEndpoints.REPORTS.INWARD_REPORT;
  return axios.get(endpoints, { params });
}

export async function getInwardReportCSVReportList(params) {
  const endpoints = ApiEndpoints.REPORTS.INWARD_CSV_REPORT;
  return axios.get(endpoints, { params });
}

export async function getWasteCollectorReportList(params) {
  const endpoints = ApiEndpoints.REPORTS.WASTE_COLLECTOR_REPORT;
  return axios.get(endpoints, { params });
}

export async function getWasteCollectorCSVReportList(params) {
  const endpoints = ApiEndpoints.REPORTS.WASTE_COLLECTOR_CSV_REPORT;
  return axios.get(endpoints, { params });
}

export async function getStockReportList(params) {
  const endpoints = ApiEndpoints.REPORTS.STOCK_REPORT;
  return axios.get(endpoints, { params });
}

export async function getStockCSVReportList(params) {
  const endpoints = ApiEndpoints.REPORTS.STOCK_CSV_REPORT;
  return axios.get(endpoints, { params });
}

export async function getOpeningStockList(params) {
  const endpoints = ApiEndpoints.REPORTS.OPENING_STOCK;
  return axios.get(endpoints, { params });
}

export async function getPaymentsReportList(params) {
  const endpoints = ApiEndpoints.REPORTS.PAYMENTS_REPORT;
  return axios.get(endpoints, { params });
}

export async function getSalesReportList(params) {
  const endpoints = ApiEndpoints.REPORTS.SALES_REPORT;
  return axios.get(endpoints, { params });
}

export async function getAttendanceReportList(params) {
  const endpoints = ApiEndpoints.REPORTS.ATTENDANCE_REPORT;
  return axios.get(endpoints, { params });
}

export async function getAttendanceCounts(params) {
  const endpoints = ApiEndpoints.REPORTS.ATTENDANCE_COUNTS;
  return axios.get(endpoints, { params });
}

export async function doGetAllEmployeeList(params) {
  const endpoints = ApiEndpoints.REPORTS.ATTENDANCE_EMPLOYEES;
  return axios.get(endpoints, { params });
}

export async function getPaymentSummeryReportList(params) {
  const endpoints = ApiEndpoints.REPORTS.PAYMENT_SUMMERY_REPORT;
  return axios.get(endpoints, { params });
}

export async function getOpeningBalanceList(params) {
  const endpoints = ApiEndpoints.REPORTS.OPENING_BALANCE;
  return axios.get(endpoints, { params });
}

export async function getPaymentCSVReportList(params) {
  const endpoints = ApiEndpoints.REPORTS.PAYMENT_SUMMERY_CSV_REPORT;
  return axios.get(endpoints, { params });
}
/**
 * CASH APIS
 */

export async function getLocationBalance(params) {
  const endpoints = ApiEndpoints.CASH.CASH_LOCATION;
  return axios.get(endpoints, { params });
}

export async function getAllLedgers(params) {
  const endpoints = ApiEndpoints.CASH.CASH_LEDGER;
  return axios.get(endpoints, { params });
}

export async function postAddCash(payload) {
  const endpoints = ApiEndpoints.CASH.CASH_ADDCASH;
  return (await axios.post(endpoints, payload)).data;
}

export async function getAdvancePayment(_params) {
  const endpoints = ApiEndpoints.CASH.ADVANCE_PAYMENT;
  const params = transformParams(_params, FilterParamMap.CASH_ADVANCE);
  return axios.get(endpoints, { params });
}
export async function getCustomerAdvance(params) {
  const endpoints = ApiEndpoints.CASH.CUSTOMER_ADVANCE;
  const url = `${endpoints}?customerId=${params}`;
  return axios.get(url);
}

/**--------------------------------------------
 * ==================Attendance APIs =============
 * -------------------------------------------*/
export async function doGetAttendanceStats(params) {
  const endpoint = ApiEndpoints.ATTENDANCE.STATS;
  const response = await axios.get(endpoint, { params });
  return response.data;
}

export async function doGetAttendanceByDepartment(params) {
  const endpoint = ApiEndpoints.ATTENDANCE.DEPARTMENT_ATTENDANCE;
  const response = await axios.get(endpoint, { params });
  return response.data;
}

/**
 * PICKUP APIS
 */

export async function getTimeSlots(params) {
  const endpoints = ApiEndpoints.PICKUP.TIMESLOTS;
  return axios.get(endpoints, { params });
}
export async function getLocationforID(params) {
  const endpoints = ApiEndpoints.PICKUP.LOCATIONS;

  return axios.get(`${endpoints}/${params.locationId}`);
}

export async function doUpdateLocation(payload) {
  const endpoints = ApiEndpoints.PICKUP.LOCATIONS;
  return (await axios.put(endpoints, payload)).data;
}

export async function doCreateLocation(payload) {
  const endpoints = ApiEndpoints.PICKUP.LOCATIONS;
  return (await axios.post(endpoints, payload)).data;
}

export async function doUpdateTimeSlots(payload) {
  const endpoints = ApiEndpoints.PICKUP.TIMESLOTS;
  return (await axios.put(endpoints, payload)).data;
}

export async function doUpdateTimeSlotsCapacity(payload) {
  const endpoints = ApiEndpoints.PICKUP.TIMESLOTS_CAPACITY;
  return (await axios.put(endpoints, payload)).data;
}

export async function doUpdateZipCodes(payload) {
  const endpoints = ApiEndpoints.PICKUP.ZIPCODES;
  return (await axios.put(endpoints, payload)).data;
}

export async function getUsersForLocation(params) {
  const endpoints = ApiEndpoints.PICKUP.USERS;
  return axios.get(endpoints, { params });
}

export async function doUpdateUsersForLocation(payload) {
  const endpoints = ApiEndpoints.PICKUP.USERS;
  return (await axios.put(endpoints, payload)).data;
}

export async function getUsersForCenter(params) {
  const endpoints = ApiEndpoints.PICKUP.CENTER_USERS;
  return axios.get(endpoints, { params });
}

export async function getItemsForLocation(params) {
  const endpoints = ApiEndpoints.CATALOG.GET_LOCATION_ITEMS_PRICE;
  const { locationId } = params;
  delete params.locationId;
  const modifiedParams = transformParams(params, FilterParamMap.PICKUP.PRICES);
  return axios.get(`${endpoints}${locationId}`, { params: modifiedParams });
}

export async function getWardsForCenter(params) {
  const endpoints = ApiEndpoints.PICKUP.WARDS;
  return axios.get(endpoints, { params });
}

/**
 * INBOUND/OUTBOUND APIS
 */

export async function getBulkGeneratorsForInbound(params) {
  const endpoints = ApiEndpoints.CUSTOMERS.GET;
  return axios.get(endpoints, { params });
}

export async function getLocationsByBG(params) {
  const endpoints = ApiEndpoints.INBOUND.LOCATIONS_BY_BG;

  return axios.get(`${endpoints}/${params.BGId}`);
}

export async function getInboundItems(params) {
  const endpoints = ApiEndpoints.INBOUND.GET_ITEMS;
  return axios.get(endpoints, { params });
}

export async function getOutboundItems(params) {
  const endpoints = ApiEndpoints.OUTBOUND.GET_ITEMS;
  return axios.get(endpoints, { params });
}

export async function getOrderById(params) {
  const endpoints = ApiEndpoints.ORDERS.GET_ORDER;
  return axios.get(`${endpoints}/${params.orderId}`);
}

export async function getOrderLogisticsById(params) {
  const endpoints = ApiEndpoints.ORDERS.GET_ORDER;
  return axios.get(`${endpoints}/${params.orderId}/order-logistics`);
}

export async function getOrderChangeLogsById(params) {
  const endpoints = ApiEndpoints.ORDERS.GET_ORDER;
  return axios.get(`${endpoints}/${params.orderId}/change-logs`);
}

export async function getPickupOrderNotesById(params) {
  const endpoints = ApiEndpoints.ORDERS.PICKUP_ORDERS;
  return axios.get(`${endpoints}/${params.orderId}/notes`);
}

export async function getProcessStock(params) {
  const endpoints = ApiEndpoints.ORDERS.PROCESS_STOCK;
  return axios.get(endpoints, { params });
}

export async function getTranporters(params) {
  const endpoints = ApiEndpoints.INIT.TRANSPORTERS;

  return axios.get(endpoints, { params });
}

export async function getVehicles(params) {
  const endpoints = ApiEndpoints.INIT.VEHICLES;

  return axios.get(endpoints, { params });
}

export async function doCreateTransportLogistics(payload) {
  const endpoints = ApiEndpoints.ORDERS.CREATE_ORDERLOGISTICS;
  return (await axios.post(endpoints, payload)).data;
}

export async function doUpdateTransportLogistics(payload) {
  const endpoints = ApiEndpoints.ORDERS.CREATE_ORDERLOGISTICS;
  return (await axios.put(endpoints, payload)).data;
}

export async function doCreateOrderNotesById({ payload, params }) {
  const endpoints = ApiEndpoints.ORDERS.PICKUP_ORDERS;
  return (await axios.post(`${endpoints}/${params.orderId}/notes`, payload)).data;
}

export async function doUpdateOrderAction({ payload, params, contentType }) {
  const endpoints = ApiEndpoints.ORDERS.UPDATE_ORDER;
  return (
    await axios.put(`${endpoints}/${params.action}`, payload, {
      headers: {
        'Content-Type': contentType,
      },
    })
  ).data;
}

export async function doDeleteOrder({ params }) {
  const endpoints = ApiEndpoints.ORDERS.DELETE_ORDER;
  return (await axios.delete(`${endpoints}/${params.orderId}`)).data;
}

export async function doCreateSaleOrder(payload) {
  const endpoints = ApiEndpoints.OUTBOUND.CREATE_OUTBOUND_ORDER;
  return (await axios.post(endpoints, payload)).data;
}

export async function updateOrderPaymentStatus(payload) {
  const endpoints = ApiEndpoints.ORDERS.VERIFY_INVOICE;
  const response = (await axios.put(endpoints, payload)).data;
  return response;
}

/*
 * CUSTOMERS APIS
 */

export async function getCustomersData(_params) {
  const endpoints = ApiEndpoints.CUSTOMERS.GET;
  const params = transformParams(_params, FilterParamMap.CUSTOMER_BULKGENERATOR);
  return axios.get(endpoints, { params });
}

export async function downloadCustomersData(_params) {
  const endpoints = ApiEndpoints.CUSTOMERS.DOWNLOAD;
  const params = transformParams(_params, FilterParamMap.CUSTOMER_BULKGENERATOR);
  return axios.get(endpoints, { params });
}

export async function getLogisticsVehicle(params) {
  const endpoints = ApiEndpoints.CUSTOMERS.LOGISTICS_VEHICLE;
  return axios.get(endpoints, { params });
}

export async function getCustomerDetails(params) {
  const endpoints = ApiEndpoints.CUSTOMERS.CUSTOMER_ID;
  const escapeSequence = '/';
  const customerIdUrl = endpoints + escapeSequence + params;
  return axios.get(customerIdUrl);
}

export async function getPurchaseOrders(params) {
  const endpoints = ApiEndpoints.CUSTOMERS.PURCHASE_ORDER;
  return axios.get(endpoints, { params });
}

export async function getCustomerLocation(params) {
  const endpoints = ApiEndpoints.CUSTOMERS.CUSTOMER_LOCATION;
  const url = `${endpoints}/${params}`;
  return axios.get(url);
}

export async function getTrackVisits(params) {
  const uId = params.userId;
  delete params.userId;
  const endpoints = ApiEndpoints.CUSTOMERS.TRACK_VISITS;
  const url = `${endpoints}/${uId}`;
  return axios.get(url, { params });
}

export async function updateBasicInfo(payload) {
  const endpoints = ApiEndpoints.CUSTOMERS.BASIC_INFO;
  const response = (await axios.put(endpoints, payload)).data;
  return response;
}

export async function updateContactPersonInfo(payload) {
  const endpoints = ApiEndpoints.CUSTOMERS.CONTACT_PERSON_INFO;
  const response = (await axios.put(endpoints, payload)).data;
  return response;
}

export async function updateLocationDetailsInfo(payload) {
  const endpoints = ApiEndpoints.CUSTOMERS.CUSTOMER_LOCATION_UPDATE;
  const response = (await axios.put(endpoints, payload)).data;
  return response;
}
export async function updateCustomerAddress(payload) {
  const endpoints = ApiEndpoints.CUSTOMERS.CUSTOMER_UPDATE_ADDRESS;
  const response = (await axios.put(endpoints, payload)).data;
  return response;
}

export async function getSarveksenProfileAttributes(params) {
  const endpoints = ApiEndpoints.CUSTOMERS.SARVEKSEN_PROFILE_ATTRIBUTES;
  return axios.get(endpoints, { params });
}

export async function getSarveksenProfileWaste(params) {
  const endpoints = ApiEndpoints.CUSTOMERS.SARVEKSEN_PROFILE_WASTETYPE;
  return axios.get(endpoints, { params });
}

export async function getSarveksenAddInfo(id) {
  const endpoints = `${ApiEndpoints.CUSTOMERS.SARVEKSEN_ADD_INFO_GET}/${id}/additional-info`;
  return axios.get(endpoints);
}

export async function updatedSarveksenAddInfo(payload) {
  const endpoints = ApiEndpoints.CUSTOMERS.SARVEKSEN_ADD_INFO_PUT;
  const response = (await axios.put(endpoints, payload)).data;
  return response;
}
export async function addNewBulkCustomers(payload) {
  const endpoints = ApiEndpoints.CUSTOMERS.CREATE_NEW_CUSTOMER;
  const response = await axios.post(endpoints, payload);
  return response;
}

export async function getFavoriteItemsData(_params) {
  const endpoints = ApiEndpoints.CUSTOMERS.FAVORITES_ITEM_LOADTABLE;
  const params = transformParams(_params, FilterParamMap.CUSTOMERS);
  return axios.get(endpoints, { params });
}

export async function addFavoriteItem(payload) {
  const endpoints = ApiEndpoints.CUSTOMERS.FAVORITES_ITEM_PUT;
  const response = (await axios.put(endpoints, payload)).data;
  return response;
}

export async function getFavoritePatnerItems(params) {
  const endpoints = ApiEndpoints.CUSTOMERS.FAVORITES_ITEM_PATNER;
  return axios.get(endpoints, { params });
}

export async function doCreatePickupOrder(payload) {
  const endpoints = ApiEndpoints.INBOUND.CREATE_PICKUP_ORDER;
  return (await axios.post(endpoints, payload)).data;
}

export async function getGeneratedCustomerId(params) {
  const endpoints = ApiEndpoints.CUSTOMERS.GENERATE_CUSTOMID;
  return axios.get(endpoints, { params });
}

export async function updateToNetwork(payload) {
  const endpoints = ApiEndpoints.CUSTOMERS.ADD_TO_NETWORK;
  const response = (
    await axios.put(endpoints, payload, { headers: { 'Content-Type': 'application/json' } })
  ).data;
  return response;
}

export async function updateAssignExecutive(payload) {
  const endpoints = ApiEndpoints.ORDERS.ASSIGN_EXECUTIVE;
  const response = (await axios.put(endpoints, payload)).data;
  return response;
}

export async function updateMakePayment(payload) {
  const endpoints = ApiEndpoints.ORDERS.MAKE_PAYMENT;
  return (await axios.post(endpoints, payload)).data;
}

/*
 * CATALOG APIS
 */

export async function fetchPartnerItems(_params) {
  const endpoints = ApiEndpoints.CATALOG.GET_ITEMS;
  const params = transformParams(_params, FilterParamMap.CATALOG);
  return axios.get(endpoints, { params });
}

export async function doUpdateManageRateStatus(payload) {
  const endpoints = ApiEndpoints.CATALOG.POST_LOCATION_ITEMS_PRICE;
  return (await axios.post(endpoints, payload)).data;
}

export async function getItemDetails(params) {
  const endpoints = ApiEndpoints.CATALOG.ITEM_ID;
  const escapeSequence = '/';
  const itemIdUrl = endpoints + escapeSequence + params;
  return axios.get(itemIdUrl);
}

export async function getOnlyItemDetails(params) {
  const endpoints = ApiEndpoints.CATALOG.ITEM_ID;
  return axios.get(endpoints, { params });
}

export async function getSearchItems(params) {
  const endpoints = ApiEndpoints.CATALOG.SEARCH_ITEM;
  return axios.get(endpoints, { params });
}

export async function getManageRateLocation(params) {
  const endpoints = ApiEndpoints.CATALOG.GET_LOCATION_ITEMS_PRICE;
  const { locationId } = params;
  delete params.locationId;
  const modifiedParams = transformParams(params, FilterParamMap.CATALOG);
  return axios.get(`${endpoints}${locationId}`, { params: modifiedParams });
}
export async function removePartnerItem(params) {
  const endpoints = ApiEndpoints.CATALOG.DELETE_ITEM;
  const escapeSequence = '/';
  const itemIdUrl = endpoints + escapeSequence + params;
  return axios.put(itemIdUrl);
}

export async function doUpdateItemLocationPrice(payload) {
  const endpoints = ApiEndpoints.CATALOG.ITEM_LOCATION_PRICE;
  return (await axios.post(endpoints, payload)).data;
}

export async function getCatalogCategories(params) {
  const endpoints = ApiEndpoints.CATALOG.GET_CATEGORIES;
  return axios.get(endpoints, { params });
}

export async function fetchUploadRecords(_params) {
  const endpoints = ApiEndpoints.CATALOG.GET_UPLOAD_RECORDS;
  const params = transformParams(_params, FilterParamMap.CATALOG);
  return axios.get(endpoints, { params });
}

export async function fetchDownloadRecords(_params) {
  const endpoints = ApiEndpoints.CATALOG.GET_DOWNLOAD_RECORDS;
  const params = transformParams(_params, FilterParamMap.CATALOG);
  return axios.get(endpoints, { params });
}

export async function fetchUploadSample(_params) {
  const endpoints = ApiEndpoints.CATALOG.UPLOAD_SAMPLE;
  return axios.get(endpoints, {});
}

export async function doDeleteUploadRecord(id) {
  const endpoints = ApiEndpoints.CATALOG.GET_UPLOAD_RECORDS;
  return axios.delete(`${endpoints}/${id}`);
}

export async function fetchUploadRecordsById(id) {
  const endpoints = ApiEndpoints.CATALOG.GET_UPLOAD_RECORDS;
  return axios.get(`${endpoints}/${id}`);
}

export async function doDeleteDownloadRecord(id) {
  const endpoints = ApiEndpoints.CATALOG.GET_DOWNLOAD_RECORDS;
  return axios.delete(`${endpoints}/${id}`);
}

export async function getRateCardsFilter(params) {
  const endpoints = ApiEndpoints.CATALOG.RATE_CARDS_FILTER;
  const queryString = createQueryString(params);
  const URL = `${endpoints}${queryString}`;
  return axios.get(URL);
}

export async function doImportRateCards(payload) {
  const endpoints = ApiEndpoints.CATALOG.IMPORT_RATE_CARDS;
  return (await axios.post(endpoints, payload)).data;
}

export async function fetchBagTxns(_params) {
  const params = transformParams(_params, FilterParamMap.BAG_TXNS);
  const endpoints = ApiEndpoints.BAGS.BAG_TXNS;
  return axios.get(endpoints, { params });
}
