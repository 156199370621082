import { combineReducers } from '@reduxjs/toolkit';

import AccountUserReducer from './AccountsUser/accountUser.reducer';
import { backendApi } from './apis/base.config';
import { wardSlice } from './apis/bwg/wards/ward.slice';
import { agencySlice } from './apis/bwg/agency/agencySlice';
import { trackingApi } from './apis/tracking/tracking.api';
import AuthReducer from './Auth/auth.reducer';
import CatalogReducer from './Catalog/catalog.reducer';
import ConfigReducer from './Config/config.reducer';
import locationReducer from './Location/location.reducer';
import UserReducer from './User/user.reducer';

/* Combining all the reducers into one reducer. */
const appReducer = combineReducers({
  user: UserReducer,
  auth: AuthReducer,
  catalog: CatalogReducer,
  config: ConfigReducer,
  accountUser: AccountUserReducer,
  location: locationReducer,
  [backendApi.reducerPath]: backendApi.reducer,
  [trackingApi.reducerPath]: trackingApi.reducer,
  [wardSlice.name]: wardSlice.reducer,
  [agencySlice.name]: agencySlice.reducer,
});

/**
 * If the action type is 'auth/logout', then remove the userToken from localStorage and return the
 * appReducer with the state minus the userToken
 * @param state - The current state of the application.
 * @param action - The action object that was dispatched.
 * @returns The rootReducer is being returned.
 */
const rootReducer = (state, action) => {
  if (action?.type === 'auth/logout') {
    const { config } = state;
    state = { config };
    localStorage.removeItem('userToken');
  }
  return appReducer(state, action);
};

export default rootReducer;
