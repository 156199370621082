import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

/**
 * It takes a countDownTime prop and returns a span with the time left
 * @returns A component that renders a span with the time in seconds.
 */
export const CountDownTimer = ({ onCounterStop = () => undefined, countDownTime = 30 }) => {
  const interval = useRef();
  const [time, setTime] = useState(countDownTime);

  useEffect(() => {
    interval.current = setInterval(() => setTime(time - 1), 1000);
    return () => clearInterval(interval.current);
  }, [time]);

  useEffect(() => {
    if (time === 0) {
      clearInterval(interval.current);
      onCounterStop();
    }
  }, [time]);

  return (
    <span>
      Time left: <b>{time < 10 ? `0${time}` : time} </b> s
    </span>
  );
};

CountDownTimer.propTypes = {
  countDownTime: PropTypes.number,
  onCounterStop: PropTypes.func,
};
