const FilterParamMap = {
  USERS: {
    id: 'id.equals',
    firstName: 'name.contains',
    mobile: 'mobile.equals',
    email: 'email.equals',
    activated: 'active.equals',
    page: 'page',
    size: 'size',
    sort: 'sort',
    refresh: 'refresh',
  },

  CUSTOMERS: {
    sortParams: {
      itemIds: 'id',
      itemName: 'item.name',
    },
    size: 'size',
    page: 'page',
    sort: 'sort',
    refresh: 'refresh',
    itemIds: 'itemIds',
    customerId: 'customerId',
    itemName: 'itemName',
    categoryName: 'categoryName',
  },

  CUSTOMER_BULKGENERATOR: {
    id: 'id.equals',
    wardId: 'wardId.equals',
    customerType: 'customerType.equals',
    qrCode: 'qrCode.equals',
    name: 'name.contains',
    mobile: 'mobile.equals',
    email: 'email.equals',
    zipcode: 'zipcode.equals',
    agencyId: 'agencyId.equals',
    active: 'active.equals',
    businessTypeId: 'businessTypeId.equals',
    regStartDate: 'regStartDate',
    regEndDate: 'regEndDate',
    wardNo: 'wardNo.equals',
    routeId: 'routeId.equals',
    size: 'size',
    page: 'page',
    sort: 'sort',
    dummyCustomer: 'dummyCustomer.equals',
    myNetwork: 'myNetwork.equals',
    canBuy: 'canBuy.equals',
    canSale: 'canSale.equals',
    wasteCollectors: 'wasteCollectors.equals',
    generators: 'generators.equals',
    businessName: 'businessName.equals',
    vehicleNumber: 'vehicleNumber.equals',
    fromDate: 'fromDate',
    toDate: 'toDate',
    cityId: 'cityId.equals',
    q: 'q.contains',
    sortParams: {
      name: 'user.firstName',
    },
    formattedId: 'formattedId.equals',
  },

  CASH_ADVANCE: {
    locationId: 'locationId',
    q: 'q.contains',
    page: 'page',
    size: 'size',
    customerId: 'customerId',
  },

  PICKUP: {
    PRICES: {
      'item.id': 'itemId',
      'item.categoryName': 'categoryName',
      'item.name': 'itemName',
      page: 'page',
      size: 'size',
      sort: 'sort',
      refresh: 'refresh',
    },
  },

  ATTENDANCE: {
    page: 'page',
    size: 'size',
    sort: 'sort',
  },

  ORDER: {
    // to replace param for sorting
    sortParams: {
      delay: 'orderDate',
      orderDate: 'orderDate',
      pickupTime: 'pickupTime',
      statusTime: 'statusTime',
      paymentDate: 'statusTime',
    },
    // to replace param for filtering
    formattedId: 'formattedId',
    id: 'id.equals',
    status: 'status',
    page: 'page',
    size: 'size',
    sort: 'sort',
    orderType: 'orderType',
    sellerName: 'customerId',
    zipcode: 'fromLocationZipcode',
    email: 'email',
    mobile: 'mobile',
    pickupPersons: 'pickupPersonId',
    channelType: 'channelName',
    'seller.businessType.title': 'businessTypeId',
    paymentMode: 'paymentMode',
    buyerName: 'customerId',
    buyerCity: 'toLocationZipcode',
    customerId: 'customerId',
    delay: 'delayedDaysOnCreatedDt',
    createdDate: ['createdStartDate', 'createdEndDate'],
    orderDate: ['stSheduledOrDropDt', 'endSheduledOrDropDt'],
    pickupTime: ['completedStartDate', 'completedEndDate'],
    statusTime: ['statusTimeStart', 'statusTimeEnd'],
    paymentDate: ['statusTimeStart', 'statusTimeEnd'],
    paymentStatus: 'status',
  },

  CATALOG: {
    sortParams: {
      itemName: 'item.name',
      categoryName: 'item.category.name',
      itemIds: 'id',
      modifiedAt: 'document.modifiedAt',
      fileName: 'document.fileName',
      totalRows: 'totalRows',
      failedRowsCount: 'failedRowsCount',
    },
    itemName: 'itemName',
    categoryName: 'categoryName',
    itemIds: 'itemIds',
    size: 'size',
    page: 'page',
    sort: 'sort',
    refresh: 'refresh',
    onlySelected: 'onlySelected',
    totalCount: 'totalCount',
    'item.name': 'itemName',
    hsn: 'hsn',
  },

  BAG_TXNS: {
    id: 'id.equals',
    page: 'page',
    size: 'size',
    sort: 'sort',
    'bag.id': 'bag.id.equals',
    generatorId: 'generatorId.equals',
    receivedAtCenter: 'receivedAtCenter',
    contaminated: 'contaminated',
  },
  BWG: {
    agencyName: 'agencyName.contains',
    name: 'name.contains',
    mobile: 'mobile.contains',
    page: 'page',
    size: 'size',
    sort: 'sort',
    number: 'number.contains',
    agencyId: 'agencyId',
    active: 'active.equals',
    id: 'id.equals',
    preferredTimeSlotId: 'preferredTimeSlotId.equals',
    wardIdIn: 'wardIdIn',
    wardId: 'wardId.equals',
    isDry: 'isDry.equals',
    isWet: 'isWet.equals',
    all: 'all',
    routeId: 'routeId.equals',
    routeNotMapped: 'routeNotMapped.equals',
    createdStartDate: 'createdStartDate',
    createdEndDate: 'createdEndDate',
    isTrip: 'isTrip',
    isActive: 'isActive',
    mode: 'mode.equals',
    status: 'status.equals',
    month: 'month.equals',
    year: 'year.equals',
    wardNo: 'wardNo.equals',
    q: 'q.contains',
    custId: 'custId.equals',
    paymentId: 'paymentId.equals',
    tripId: 'tripId.equals',
    businessName: 'businessName.equals',
    customerId: 'customerId.equals',
    driverName: 'driverName.contains',
    driverMobile: 'driverMobile.contains',
    vehicleNumber: 'vehicleNumber.contains',
  },

  ROUTES: {
    page: 'page',
    size: 'size',
    sort: 'sort',
    name: 'name.contains',
    driver: 'driver.contains',
    vehicle: 'vehicle.contains',
    timeSlot: 'timeSlotId.equals',
    wardIds: 'wardIdsIn',
    agencyName: 'agencyName.contains',
    id: 'id.equals',
    includeArchive: 'includeArchive.equals',
    all: 'all',
    createdStartDate: 'createdStartDate',
    createdEndDate: 'createdEndDate',
  },
  TRIPS: {
    page: 'page',
    size: 'size',
    sort: 'sort',
    driver: 'driver.contains',
    vehicle: 'vehicle.contains',
    timeSlotId: 'timeSlotId.equals',
    status: 'status.equals',
    route: 'route.contains',
    agency: 'agency.contains',
    createdStartDate: 'createdStartDate',
    createdEndDate: 'createdEndDate',
  },
  TRIP_TXN: {
    page: 'page',
    size: 'size',
    sort: 'sort',
    driverName: 'driverName.contains',
    driverMobile: 'driverMobile.contains',
    vehicleNumber: 'vehicleNumber.contains',
  },
  AGENCY: {
    page: 'page',
    size: 'size',
    sort: 'sort',
    active: 'active.equals',
    wardsId: 'wardsId.equals',
    name: 'name.contains',
    agencyName: 'agencyName.contains',
  },
};

export default FilterParamMap;
