import React, { useMemo } from 'react';
import { useAuthority } from '../shared/components/HasAuthority';
import { AUTHORITY } from '../shared/constants/Constants';
import { useAccount } from './hooks';
import useStakeholders from './useStakeholders';

const useBwgUser = () => {
  const { hasAuth } = useAuthority();
  const { currentAccount: accountDetails } = useStakeholders();

  const isBwgUser = useMemo(
    () =>
      hasAuth([
        AUTHORITY.MUNICIPAL_ADMIN,
        AUTHORITY.AGENCY_ADMIN,
        AUTHORITY.AGENCY_USER,
        AUTHORITY.FINANCE_MANAGER,
        AUTHORITY.BWG_OPERATION_MANAGER,
      ]),
    [accountDetails]
  );
  return { isBwgUser };
};

export default useBwgUser;
