import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAsyncEffect } from '.';
import { authActions } from '../store/Auth/auth.reducer';
import { useAccount, useInitApp } from './hooks';

const useAuthCheck = () => {
  const history = useHistory();
  const { accountDetails, token, logout, loading: authLoading } = useAccount();
  const [{ loading: initLoading }, { loadPreLoginConfig }] = useInitApp();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const dispatch = useDispatch();
  const token1 = params.get('token');
  const loading = initLoading || authLoading;
  useAsyncEffect(async () => {
    await loadPreLoginConfig();
  }, []);

  useEffect(() => {
    if (token1) {
      dispatch(authActions.setToken(token1));
    }
  }, [token1]);

  useEffect(() => {
    // useEffect(() => {
    //   if (token) {
    //     history.push('/home');
    //   } else {
    //     history.push(`/`);
    //   }
    // }, [accountDetails, token]);
    if (!token) {
      if (window.location.pathname !== '/reset-password') {
        if (!params.get('key')) {
          history.push('/login');
        } else {
          history.push(`/reset-password?key=${params.get('key')}`);
        }
      }
      // logout();
    }
  }, [accountDetails, token]);
  return { accountDetails, token, loading };
};

export default useAuthCheck;
