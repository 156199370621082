export const MOBILE_NUMBER_REGEX = '^[6789][0-9]{9}$';
export const EMAIL_REGEX = '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$';
export const PINCODE_REGEX = '^[1-9][0-9]{5}$';
export const NAME_REGEX = '^[A-Za-z0-9 ]*$';
export const PAN_REGEX = '[A-Z]{5}[0-9]{4}[A-Z]{1}$';
export const GSTIN_REGEX = '\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}$';
export const IFSC_REGEX = '^[ ]*[A-Za-z]{4}0[A-Z0-9a-z]{6}[ ]*$';
export const UPI_REGEX = '^\\w+@\\w+$';
export const ACCOUNTNAME_REGEX = "^[a-zA-Z -']+";
export const ACCOUNTNUMBER_REGEX = '[0-9]$';
export const VEHICLE_NUMBER_REGEX =
  '^[A-Za-z]{2}\\s{0,1}[0-9]{2}\\s{0,1}[A-Za-z]{1,2}\\s{0,1}[0-9]{1,4}$';
export const WARD_NUMBER_REGEX = '^[0-9]*$';

export const bulkGeneratorTypes = [
  { id: 1, title: 'Retail', info: 'Multi/single Location Stores, Dealerships, Warehouses' },
  {
    id: 2,
    title: 'Gated Communities',
    info: 'Apartments, Housing Societies,Residential Buildings',
  },
  {
    id: 4,
    title: 'Corporate Offices',
    info: 'Facilities of Large, Medium, Small Enterprises, Co-working',
  },
  { id: 3, title: 'Hotels & Restaurants', info: 'Resorts, Quick Service Food Chains' },
  { id: 5, title: 'Educational Institutes', info: 'Schools, Colleges, Coaching Centers' },
  { id: 6, title: 'Hospitals', info: 'Clinics, Medical Laboratories, Healthcare Facilities' },
  {
    id: 7,
    title: 'Malls & Convention Centers',
    info: 'Multiplexes, Function Halls, Gaming, Recreation Centers',
  },
  { id: 9, title: 'Manufacturers', info: 'Large, MSME Factories, Industries, Workshops' },
  { id: 11, title: 'Others', info: 'Commercial setups, Businesses, Service Centers etc' },
];

export const monthNamesOptions = [
  {
    id: 1,
    name: 'January',
  },
  {
    id: 2,
    name: 'February',
  },
  {
    id: 3,
    name: 'March',
  },
  {
    id: 4,
    name: 'April',
  },
  {
    id: 5,
    name: 'May',
  },
  {
    id: 6,
    name: 'June',
  },
  {
    id: 7,
    name: 'July',
  },
  {
    id: 8,
    name: 'August',
  },
  {
    id: 9,
    name: 'September',
  },
  {
    id: 10,
    name: 'October',
  },
  {
    id: 11,
    name: 'November',
  },
  {
    id: 12,
    name: 'December',
  },
];

export const paymentStatusOptions = [
  { name: 'Pending', id: 'PENDING' },
  { name: 'Paid', id: 'PAID' },
  { name: 'Cancelled', id: 'CANCELLED' },
];

export const paymentMethodOptions = [
  { name: 'Net Banking', id: 'NB' },
  { name: 'Credit Card', id: 'CC' },
  { name: 'Debit Card', id: 'DC' },
  { name: 'CASH', id: 'CASH' },
  { name: 'UPI', id: 'UPI' },
  { name: 'NEFTRTGS', id: 'NEFTRTGS' },
];

export const FeeCollectionStatus = {
  PAID: 'Paid',
  CANCELLED: 'Cancelled',
  PENDING: 'Pending',
};

export const individualGeneratorTypes = [
  {
    id: 25,
    title: 'Commercial Establishments',
    info: '',
  },
  {
    id: 24,
    title: 'Households',
    info: '',
  },
];

export const recyclerTypes = [
  { id: 'Plastic Recyclers', title: 'Plastic Recyclers', info: '' },
  {
    id: 'Paper Recyclers',
    title: 'Paper Recyclers',
    info: '',
  },
  {
    id: 'E-Waste Recyclers',
    title: 'E-Waste Recyclers',
    info: '',
  },
  { id: 'Cement Kilns', title: 'Cement Kilns', info: '' },
  { id: 'Waste-to-Fuel Units', title: 'Waste-to-Fuel Units', info: '' },
];

export const wasteCollectorTypes = [
  { id: 10, title: 'Waste Pickers', info: '' },
  {
    id: 20,
    title: 'Individuals',
    info: '',
  },
  {
    id: 21,
    title: 'Sweeper',
    info: '',
  },
  { id: 22, title: 'Waste Hauler', info: '' },
];

export const businessTypeBadgeColorMap = {
  Retail: '#FDE6D5',
  Gated: '#FFFBC8',
  Hotels: '#F9D5DC',
  Corporate: '#FFEFD1',
  Educational: '#F8CADF',
  Hospitals: '#EFD1E8',
  Malls: '#DEDBEF',
  Manufacturers: '#CDDFF9',
  Others: '#BCE2FE',
  'Waste Pickers': '#BCE2FE',
  Individuals: '#FDE6D5',
  Sweeper: '#FDE6D5',
  'Waste Hauler': '#FDE6D5',
  'Commercial Establishments': '#FDE6D5',
  Households: '#CDDFF9',
};

export const AUTHORITY = {
  USER: 'ROLE_USER',
  SUPER_ADMIN: 'ROLE_PARTNER_SUPER_ADMIN',
  // ADMIN: 'ROLE_ADMIN',
  KEY_ACCOUNT_MANAGER: 'ROLE_KEY_ACCOUNT_MANAGER',
  ACCOUNTS: 'ROLE_MARKETPLACE_ACCOUNTS',
  BUSINESS_HEAD: 'ROLE_MARKETPLACE_BUSINESS',
  CENTER_USER: 'ROLE_CENTER_USER',
  FRANCHISE_SUPERVISOR: 'ROLE_FRANCHISE_SUPERVISOR',
  FRANCHISE_ADMIN: 'ROLE_FRANCHISE_ADMIN',
  PARTNER_OPERATIONS: 'ROLE_PARTNER_OPERATIONS',
  ATTENDANCE_SUPERVISOR: 'ROLE_ATTENDANCE_SUPERVISOR',
  SMART_SCAN_USER: 'ROLE_SMART_SCAN_USER',
  PICKUP_EXECUTIVE: 'ROLE_PICKUP_EXECUTIVE',
  // common role for Partner Panel Users
  FRANCHISE_USER: 'ROLE_FRANCHISE_USER',

  // BWG Related Roles
  // Common role for BWG
  ALL_BWG_USERS: 'ROLE_BWG_USER',
  MUNICIPAL_ADMIN: 'ROLE_BWG_MUNICIPAL_ADMIN',
  AGENCY_ADMIN: 'ROLE_AGENCY_ADMIN',
  AGENCY_USER: 'ROLE_AGENCY_USER',
  FINANCE_MANAGER: 'ROLE_FINANCE',
  BWG_OPERATION_MANAGER: 'ROLE_BWG_MUNICIPAL_USER',
  COLLECTION_EXECUTIVE: 'ROLE_SMART_CENTER_COLLECTION_EXECUTIVE', // admin
};

// Authorities for user management page
export const Authorities = [
  { title: 'Operation Manager', name: AUTHORITY.PARTNER_OPERATIONS },
  { title: 'Pickup Executive', name: AUTHORITY.PICKUP_EXECUTIVE },
  { title: 'Smart Center Executive', name: AUTHORITY.CENTER_USER },
  { title: 'Admin', name: AUTHORITY.FRANCHISE_ADMIN },
  { title: 'Smart Center Supervisor', name: AUTHORITY.FRANCHISE_SUPERVISOR },
  { title: 'Attendance Supervisor', name: AUTHORITY.ATTENDANCE_SUPERVISOR },
  { title: 'Smart Skan Executive', name: AUTHORITY.SMART_SCAN_USER },
  { title: 'Smart Skan Executive', name: AUTHORITY.SMART_SCAN_USER },
  { title: 'IOCL Executive', name: AUTHORITY.ROLE_SMART_CENTER_COLLECTION_EXECUTIVE },
];

export const BWGAuthorities = [
  // bwg related roles
  { title: 'Operation Manager', name: AUTHORITY.PARTNER_OPERATIONS },
  { title: 'Finance Manager', name: AUTHORITY.FINANCE_MANAGER },
];

export const badgeColorMap = {
  // ROLE_STARGAZE_USER: '#d5d6f0',
  ROLE_PARTNER_OPERATIONS: '#d1ebf4',
  ROLE_ATTENDANCE_SUPERVISOR: '#d5d6f0',
  ROLE_PICKUP_EXECUTIVE: '#d6f0d5',
  ROLE_CENTER_USER: '#d5d6f0',
  ROLE_FRANCHISE_SUPERVISOR: '#d5d6f0',
  ROLE_SMART_SCAN_USER: '#d1ebf4',
  ROLE_FRANCHISE_ADMIN: '#d5d6f0',
  ROLE_FINANCE: '#d5d6f0',
  VIEW_ONLY: '#fce7cb',
  ROLE_SMART_CENTER_COLLECTION_EXECUTIVE: '#d1ebf4',
};

export const tripStatus = {
  CREATED: 'Created',
  TRIP_COMPLETED: 'Trip Completed',
  TRIP_STARTED: 'Trip Started',
  CANCELLED: 'Cancelled',
};

export const tripsStatusOptions = [
  { id: 'CREATED', name: 'Created' },
  { id: 'TRIP_STARTED', name: 'Trip Started' },
  { id: 'TRIP_COMPLETED', name: 'Trips Completed' },
  { id: 'CANCELLED', name: 'Cancelled' },
];

export const transactionsStatus = {
  COLLECTED: 'Collected',
  NOT_COLLECTED: 'Not Collected',
};

export const TXN_STATUS_OPTIONS = [
  { id: 'COLLECTED', name: 'Collected' },
  { id: 'NOT_COLLECTED', name: 'Not Collected' },
];

export const locationTypeBadgeColorMap = {
  warehouse: '#d6f0d5',
  collectionCenter: '#d5d6f0',
  droplocation: '#F0D5D5',
};

export const AuthViews = {
  RESET_PASSWORD: '/views/ResetPasswordPage/RESET_PASSWORD',
  RESET_PASSWORD_FROM_LINK: '/views/ResetPasswordPage/RESET_PASSWORD_FROM_LINK',
  RESET_PASSWORD_SUCCESS: '/views/ResetPasswordPage/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FROM_LINK_SUCCESS: '/views/ResetPasswordPage/RESET_PASSWORD_FROM_LINK_SUCCESS',
};

export const PAGE_CONFIG = {
  size: 20,
};

export const WARDS_ROW_LIMIT = 20;

export const MATERIALS = [
  {
    itemName: 'All',
    category: 'Categories',
    itemId: 'ALL',
  },
  {
    itemName: 'Plastic',
    category: 'Categories',
    itemId: 'Plastic',
  },
  {
    itemName: 'Paper',
    category: 'Categories',
    itemId: 'Paper',
  },
  {
    itemName: 'Cardboard',
    category: 'Categories',
    itemId: 'Cardboard',
  },
  {
    itemName: 'Glass',
    category: 'Categories',
    itemId: 'Glass',
  },
  {
    itemName: 'Metal',
    category: 'Categories',
    itemId: 'Metal',
  },
  {
    itemName: 'E-waste',
    category: 'Categories',
    itemId: 'E-waste',
  },
  {
    itemName: 'Others',
    category: 'Categories',
    itemId: 'Others',
  },
  {
    itemName: 'HDPE Maida Bags',
    category: 'Plastic',
    itemId: 'SKUPLA101001',
  },
  {
    itemName: 'Newspaper',
    category: 'Paper',
    itemId: 'SKUPEP201001',
  },
  {
    itemName: 'Books',
    category: 'Paper',
    itemId: 'SKUPEP201002',
  },
  {
    itemName: 'White Record/Office Paper',
    category: 'Paper',
    itemId: 'SKUPEP201003',
  },
  {
    itemName: 'Cardboards',
    category: 'Cardboard',
    itemId: 'SKUPEP201004',
  },
  {
    itemName: 'Color Cartons',
    category: 'Cardboard',
    itemId: 'SKUPEP201005',
  },
  {
    itemName: 'Saree Boxes',
    category: 'Cardboard',
    itemId: 'SKUPEP201006',
  },
  {
    itemName: 'Color Record/Mixed Paper Waste',
    category: 'Paper',
    itemId: 'SKUPEP201007',
  },
  {
    itemName: 'Tissues (Kala)',
    category: 'Paper',
    itemId: 'SKUPEP201008',
  },
  {
    itemName: 'Shredded Paper',
    category: 'Paper',
    itemId: 'SKUPEP201009',
  },
  {
    itemName: 'Grey Board',
    category: 'Cardboard',
    itemId: 'SKUPEP201010',
  },
  {
    itemName: 'Shredder Paper- White',
    category: 'Paper',
    itemId: 'SKUPEP201011',
  },
  {
    itemName: 'Waste CBS',
    category: 'Cardboard',
    itemId: 'SKUPEP201012',
  },
  {
    itemName: 'Waste ATC',
    category: 'Paper',
    itemId: 'SKUPEP201013',
  },
  {
    itemName: 'Core',
    category: 'Cardboard',
    itemId: 'SKUPEP201014',
  },
  {
    itemName: 'Kraft/Duplex',
    category: 'Cardboard',
    itemId: 'SKUPEP201015',
  },
  {
    itemName: 'Carton',
    category: 'Cardboard',
    itemId: 'SKUPEP201020',
  },
  {
    itemName: 'Books (Raddi)',
    category: 'Paper',
    itemId: 'SKUPEP201021',
  },
  {
    itemName: 'Duplex',
    category: 'Paper',
    itemId: 'SKUPEP20117',
  },
  {
    itemName: 'Collect Paper',
    category: 'Paper',
    itemId: 'SKUPEP20118',
  },
  {
    itemName: 'Paper',
    category: 'Paper',
    itemId: 'SKUPEP20119',
  },
  {
    itemName: 'Large Beer Bottles',
    category: 'Glass',
    itemId: 'SKUMAG301001',
  },
  {
    itemName: 'Small Beer Bottles',
    category: 'Glass',
    itemId: 'SKUMAG301002',
  },
  {
    itemName: 'Glass Items',
    category: 'Glass',
    itemId: 'SKUMAG301003',
  },
  {
    itemName: 'Iron Scrap',
    category: 'Metal',
    itemId: 'SKUMAG301004',
  },
  {
    itemName: 'Cold Drink Cans',
    category: 'Metal',
    itemId: 'SKUMAG301005',
  },
  {
    itemName: 'Iron Oil Cans',
    category: 'Metal',
    itemId: 'SKUMAG301006',
  },
  {
    itemName: 'Aluminium Scrap',
    category: 'Metal',
    itemId: 'SKUMAG301007',
  },
  {
    itemName: 'Stainless Steel Scrap',
    category: 'Metal',
    itemId: 'SKUMAG301008',
  },
  {
    itemName: 'Copper Scrap',
    category: 'Metal',
    itemId: 'SKUMAG301009',
  },
  {
    itemName: 'Mac Wheel',
    category: 'Metal',
    itemId: 'SKUMAG301010',
  },
  {
    itemName: 'Break Shoe',
    category: 'Metal',
    itemId: 'SKUMAG301011',
  },
  {
    itemName: 'Metal Scrap',
    category: 'Metal',
    itemId: 'SKUMAG301012',
  },
  {
    itemName: 'Iron- Heavy',
    category: 'Metal',
    itemId: 'SKUMAG301013',
  },
  {
    itemName: 'Iron- Light',
    category: 'Metal',
    itemId: 'SKUMAG301014',
  },
  {
    itemName: 'Aluminium Chambers',
    category: 'Metal',
    itemId: 'SKUMAG301015',
  },
  {
    itemName: 'Copper Wire',
    category: 'Metal',
    itemId: 'SKUMAG301016',
  },
  {
    itemName: 'Copper Winding',
    category: 'Metal',
    itemId: 'SKUMAG301017',
  },
  {
    itemName: 'Car Engine Scrap',
    category: 'Metal',
    itemId: 'SKUMAG301018',
  },
  {
    itemName: 'Empty Barrel',
    category: 'Metal',
    itemId: 'SKUMAG301019',
  },
  {
    itemName: 'Aluminium Foil Containers',
    category: 'Metal',
    itemId: 'SKUMAG301020',
  },
  {
    itemName: 'Hard Aluminium',
    category: 'Metal',
    itemId: 'SKUMAG301021',
  },
  {
    itemName: 'Zinc',
    category: 'Metal',
    itemId: 'SKUMAG301022',
  },
  {
    itemName: 'Casting',
    category: 'Metal',
    itemId: 'SKUMAG301023',
  },
  {
    itemName: 'Brass',
    category: 'Metal',
    itemId: 'SKUMAG301024',
  },

  {
    itemName: 'HDPE Sugar Bags',
    category: 'Plastic',
    itemId: 'SKUPLA101002',
  },
  {
    itemName: 'Tray Damage',
    category: 'Plastic',
    itemId: 'SKUPLA101003',
  },
  {
    itemName: 'Empty Can',
    category: 'Plastic',
    itemId: 'SKUPLA101004',
  },
  {
    itemName: 'HM & PP',
    category: 'Plastic',
    itemId: 'SKUPLA101005',
  },
  {
    itemName: 'BOPP Waste',
    category: 'Plastic',
    itemId: 'SKUPLA101006',
  },
  {
    itemName: 'SMP Bags',
    category: 'Plastic',
    itemId: 'SKUPLA101007',
  },
  {
    itemName: 'Mixed Plastic Bag',
    category: 'Plastic',
    itemId: 'SKUPLA101008',
  },
  {
    itemName: 'Raffia',
    category: 'Plastic',
    itemId: 'SKUPLA10108005',
  },
  {
    itemName: 'Bundle Bandage',
    category: 'Plastic',
    itemId: 'SKUPLA10108006',
  },
  {
    itemName: 'Oil Packet',
    category: 'Plastic',
    itemId: 'SKUPLA10108007',
  },
  {
    itemName: 'Wood Plastic Composite',
    category: 'Plastic',
    itemId: 'SKUPLA10108008',
  },
  {
    itemName: 'X Ray',
    category: 'Plastic',
    itemId: 'SKUPLA10108009',
  },
  {
    itemName: 'ABS - Grinded',
    category: 'Plastic',
    itemId: 'SKUAB1010901',
  },
  {
    itemName: 'Mixed HDPE',
    category: 'Plastic',
    itemId: 'SKUHD10103001',
  },
  {
    itemName: 'Oil Plastic Bottles',
    category: 'Plastic',
    itemId: 'SKUHD10103002',
  },
  {
    itemName: 'Plastic Bumpers',
    category: 'Plastic',
    itemId: 'SKUHD10103003',
  },
  {
    itemName: 'Baled Blue Drums',
    category: 'Plastic',
    itemId: 'SKUHD10103006',
  },
  {
    itemName: 'Blue Drums',
    category: 'Plastic',
    itemId: 'SKUHD1010302',
  },
  {
    itemName: 'HDPE Aglo Gatta',
    category: 'Plastic',
    itemId: 'SKUHD10103021',
  },
  {
    itemName: 'HDPE Natural - Grinded',
    category: 'Plastic',
    itemId: 'SKUHD10103022',
  },
  {
    itemName: 'HDPE White - Grinded',
    category: 'Plastic',
    itemId: 'SKUHD10103023',
  },
  {
    itemName: 'HDPE Black - Grinded',
    category: 'Plastic',
    itemId: 'SKUHD10103024',
  },
  {
    itemName: 'HDPE Crates - Yellow',
    category: 'Plastic',
    itemId: 'SKUHD10103026',
  },
  {
    itemName: 'HDPE Crates - Black',
    category: 'Plastic',
    itemId: 'SKUHD10103027',
  },
  {
    itemName: 'HDPE Crates - Blue',
    category: 'Plastic',
    itemId: 'SKUHD1010325',
  },
  {
    itemName: 'HDPE Crates - Red',
    category: 'Plastic',
    itemId: 'SKUHD1010326',
  },
  {
    itemName: 'HDPE Bluedrum - Grinded',
    category: 'Plastic',
    itemId: 'SKUHD1010327',
  },
  {
    itemName: 'HDPE Bottles - Baled',
    category: 'Plastic',
    itemId: 'SKUHD1010328',
  },
  {
    itemName: 'HIPS - Grinded',
    category: 'Plastic',
    itemId: 'SKUHI10101101',
  },
  {
    itemName: 'Milk Covers',
    category: 'Plastic',
    itemId: 'SKULD10102001',
  },
  {
    itemName: 'Disposable Water Glasses',
    category: 'Plastic',
    itemId: 'SKULD10102002',
  },
  {
    itemName: 'Mixed LDPE',
    category: 'Plastic',
    itemId: 'SKULD10102003',
  },
  {
    itemName: 'LDPE Film Scrap - Unwashed, Grade 1, Baled',
    category: 'Plastic',
    itemId: 'SKULD10102004',
  },
  {
    itemName: 'LDPE Film Scrap Grade No 2',
    category: 'Plastic',
    itemId: 'SKULD10102005',
  },
  {
    itemName: 'Post Consumer Milk Pouches + LDPE Mix - Baled',
    category: 'Plastic',
    itemId: 'SKULD10102006',
  },
  {
    itemName: 'LDPE Film Scrap - Unwashed, Grade 1, Bagged',
    category: 'Plastic',
    itemId: 'SKULD10102007',
  },
  {
    itemName: 'LDPE Film Scrap - Washed, Grade 1, Baled',
    category: 'Plastic',
    itemId: 'SKULD10102008',
  },
  {
    itemName: 'LDPE Film Scrap - Washed, Grade 1, Bagged',
    category: 'Plastic',
    itemId: 'SKULD10102009',
  },
  {
    itemName: 'LDPE Film Scrap - Washed, Grade 2, Baled',
    category: 'Plastic',
    itemId: 'SKULD10102010',
  },
  {
    itemName: 'LDPE Film Scrap - Washed, Grade 2, Bagged',
    category: 'Plastic',
    itemId: 'SKULD10102011',
  },
  {
    itemName: 'Post Consumer Milk Pouches + LDPE Mix - Bagged',
    category: 'Plastic',
    itemId: 'SKULD10102012',
  },
  {
    itemName: 'LDPE Film - Grade 3, Unwashed, Baled',
    category: 'Plastic',
    itemId: 'SKULD10102013',
  },
  {
    itemName: 'LDPE Film - Grade 4, Unwashed, Bagged',
    category: 'Plastic',
    itemId: 'SKULD10102014',
  },
  {
    itemName: 'LDPE Film - Grade 2, Baled, Unwashed',
    category: 'Plastic',
    itemId: 'SKULD10102015',
  },
  {
    itemName: 'LDPE Film - Grade 3, Bundled, Unwashed',
    category: 'Plastic',
    itemId: 'SKULD10102016',
  },
  {
    itemName: 'LDPE Film - Grade 4, Bundled, Unwashed',
    category: 'Plastic',
    itemId: 'SKULD10102017',
  },
  {
    itemName: 'Black LDPE - Baled',
    category: 'Plastic',
    itemId: 'SKULD10102018',
  },
  {
    itemName: 'Black LDPE - Bundled',
    category: 'Plastic',
    itemId: 'SKULD10102019',
  },
  {
    itemName: 'LDPE Film - Grade 4, Baled , Unwashed',
    category: 'Plastic',
    itemId: 'SKULD10102020',
  },
  {
    itemName: 'LDPE',
    category: 'Plastic',
    itemId: 'SKULD10102022',
  },
  {
    itemName: 'LDPE (Panni)',
    category: 'Plastic',
    itemId: 'SKULD10102023',
  },
  {
    itemName: 'Milk, Water pouches - Baled',
    category: 'Plastic',
    itemId: 'SKULD1010218',
  },
  {
    itemName: 'Milk, Water pouches - Bundled',
    category: 'Plastic',
    itemId: 'SKULD1010219',
  },
  {
    itemName: 'LDPE Aglo Gatta',
    category: 'Plastic',
    itemId: 'SKULD1010221',
  },
  {
    itemName: 'MLP',
    category: 'Plastic',
    itemId: 'SKUML10107001',
  },
  {
    itemName: 'Mixed Plastic',
    category: 'Plastic',
    itemId: 'SKUMP10108001',
  },
  {
    itemName: 'White Plastic',
    category: 'Plastic',
    itemId: 'SKUMP10108002',
  },
  {
    itemName: 'Carry Bag (Panni)',
    category: 'Plastic',
    itemId: 'SKUMP10108003',
  },
  {
    itemName: 'Colour Plastic',
    category: 'Plastic',
    itemId: 'SKUMP10108004',
  },
  {
    itemName: 'Mixed Black Plastic',
    category: 'Plastic',
    itemId: 'SKUMP10108005',
  },
  {
    itemName: 'PC - Grinded',
    category: 'Plastic',
    itemId: 'SKUPC10101001',
  },
  {
    itemName: 'Mixed PET',
    category: 'Plastic',
    itemId: 'SKUPE10101001',
  },
  {
    itemName: 'Pet Bottles (90 ml Liquor)',
    category: 'Plastic',
    itemId: 'SKUPE10101002',
  },
  {
    itemName: 'PET bottle - Natural',
    category: 'Plastic',
    itemId: 'SKUPE10101003',
  },
  {
    itemName: 'PET bottle - White',
    category: 'Plastic',
    itemId: 'SKUPE10101004',
  },
  {
    itemName: 'PET Bottle Baled Scrap (Colored)',
    category: 'Plastic',
    itemId: 'SKUPE10101005',
  },
  {
    itemName: 'PET Scrap - Grinded',
    category: 'Plastic',
    itemId: 'SKUPE10101010',
  },
  {
    itemName: 'PET Scrap - Grinded',
    category: 'Plastic',
    itemId: 'SKUPE10101011',
  },
  {
    itemName: 'PET Bottle - White, First Grade',
    category: 'Plastic',
    itemId: 'SKUPE10101012',
  },
  {
    itemName: 'PET Bottle - White, Fibre Grade',
    category: 'Plastic',
    itemId: 'SKUPE10101013',
  },
  {
    itemName: 'PET bottle - Brown',
    category: 'Plastic',
    itemId: 'SKUPE1010106',
  },
  {
    itemName: 'PET bottle - Green',
    category: 'Plastic',
    itemId: 'SKUPE1010107',
  },
  {
    itemName: 'PET Scrap - Baled',
    category: 'Plastic',
    itemId: 'SKUPE1010108',
  },
  {
    itemName: 'PET Scrap - Baled',
    category: 'Plastic',
    itemId: 'SKUPE1010109',
  },
  {
    itemName: 'Mixed E-Waste',
    category: 'E-waste',
    itemId: 'SKUEWS401001',
  },
  {
    itemName: 'Mixed Mobile Kit',
    category: 'E-waste',
    itemId: 'SKUEWS401002',
  },
  {
    itemName: 'Monitors',
    category: 'E-waste',
    itemId: 'SKUCO40101001',
  },
  {
    itemName: 'CPU/Server',
    category: 'E-waste',
    itemId: 'SKUCO40101002',
  },
  {
    itemName: 'Laptops',
    category: 'E-waste',
    itemId: 'SKUCO40101003',
  },
  {
    itemName: 'Printer/Scanner/Fax',
    category: 'E-waste',
    itemId: 'SKUCO40101004',
  },
  {
    itemName: 'UPS',
    category: 'E-waste',
    itemId: 'SKUCO40101005',
  },
  {
    itemName: 'Mobile Phone - Feature Phone',
    category: 'E-waste',
    itemId: 'SKUCO40101006',
  },
  {
    itemName: 'Mobile Phone - Smartphone',
    category: 'E-waste',
    itemId: 'SKUCO40101007',
  },
  {
    itemName: 'Tablet',
    category: 'E-waste',
    itemId: 'SKUCO40101008',
  },
  {
    itemName: 'Desktop',
    category: 'E-waste',
    itemId: 'SKUCO40101009',
  },
  {
    itemName: 'External Hard Drive',
    category: 'E-waste',
    itemId: 'SKUCO40101010',
  },
  {
    itemName: 'CPU Empty Cabinets',
    category: 'E-waste',
    itemId: 'SKUCO40101011',
  },
  {
    itemName: 'Keyboard',
    category: 'E-waste',
    itemId: 'SKUCO40101012',
  },
  {
    itemName: 'SMPS',
    category: 'E-waste',
    itemId: 'SKUCO40101013',
  },
  {
    itemName: 'Power Cables & Other Cables',
    category: 'E-waste',
    itemId: 'SKUCO40101014',
  },
  {
    itemName: 'CRT Monitors',
    category: 'E-waste',
    itemId: 'SKUCO40101015',
  },
  {
    itemName: 'LCD Monitors',
    category: 'E-waste',
    itemId: 'SKUCO40101016',
  },
  {
    itemName: 'Mainframe',
    category: 'E-waste',
    itemId: 'SKUCO40101017',
  },
  {
    itemName: 'Minicomputer',
    category: 'E-waste',
    itemId: 'SKUCO40101018',
  },
  {
    itemName: 'Copying Equipment',
    category: 'E-waste',
    itemId: 'SKUCO40101019',
  },
  {
    itemName: 'Typewriters',
    category: 'E-waste',
    itemId: 'SKUCO40101020',
  },
  {
    itemName: 'FAX',
    category: 'E-waste',
    itemId: 'SKUCO40101021',
  },
  {
    itemName: 'Telex',
    category: 'E-waste',
    itemId: 'SKUCO40101022',
  },
  {
    itemName: 'Telephones',
    category: 'E-waste',
    itemId: 'SKUCO40101023',
  },
  {
    itemName: 'Pay telephones',
    category: 'E-waste',
    itemId: 'SKUCO40101024',
  },
  {
    itemName: 'Cordless telephones',
    category: 'E-waste',
    itemId: 'SKUCO40101025',
  },
  {
    itemName: 'Cellular telephones',
    category: 'E-waste',
    itemId: 'SKUCO40101026',
  },
  {
    itemName: 'Answering Systems',
    category: 'E-waste',
    itemId: 'SKUCO40101027',
  },
  {
    itemName: 'Dual Core Laptop',
    category: 'E-waste',
    itemId: 'SKULA10109002',
  },
  {
    itemName: 'I3 Generation Laptop',
    category: 'E-waste',
    itemId: 'SKULA10109003',
  },
  {
    itemName: 'I5 Laptop',
    category: 'E-waste',
    itemId: 'SKULA10109004',
  },
  {
    itemName: 'Core2Duo Laptop',
    category: 'E-waste',
    itemId: 'SKULA10109008',
  },
  {
    itemName: 'Emergency Lights',
    category: 'E-waste',
    itemId: 'SKUEL40102001',
  },
  {
    itemName: 'Water Heaters',
    category: 'E-waste',
    itemId: 'SKUEL40102002',
  },
  {
    itemName: 'Table Fans',
    category: 'E-waste',
    itemId: 'SKUEL40102003',
  },
  {
    itemName: 'Ceiling Fans',
    category: 'E-waste',
    itemId: 'SKUEL40102004',
  },
  {
    itemName: 'Battery',
    category: 'E-waste',
    itemId: 'SKUEL40102005',
  },
  {
    itemName: 'Music Systems',
    category: 'E-waste',
    itemId: 'SKUEL40102006',
  },
  {
    itemName: 'Router/Modem/Set-Top Box',
    category: 'E-waste',
    itemId: 'SKUEL40102007',
  },
  {
    itemName: 'Set-Top Box',
    category: 'E-waste',
    itemId: 'SKUEL40102008',
  },
  {
    itemName: 'Geyser',
    category: 'E-waste',
    itemId: 'SKUEL40102009',
  },
  {
    itemName: 'Doorbell',
    category: 'E-waste',
    itemId: 'SKUEL40102010',
  },
  {
    itemName: 'DVD/CD/VCR/BluRay Player',
    category: 'E-waste',
    itemId: 'SKUEL40102011',
  },
  {
    itemName: 'Hair Dryer',
    category: 'E-waste',
    itemId: 'SKUEL40102012',
  },
  {
    itemName: 'Vaccum Cleaner',
    category: 'E-waste',
    itemId: 'SKUEL40102013',
  },
  {
    itemName: 'Home Theatre',
    category: 'E-waste',
    itemId: 'SKUEL40102014',
  },
  {
    itemName: 'MP3 Player',
    category: 'E-waste',
    itemId: 'SKUEL40102015',
  },
  {
    itemName: 'Camcorder',
    category: 'E-waste',
    itemId: 'SKUEL40102016',
  },
  {
    itemName: 'Smart Watch',
    category: 'E-waste',
    itemId: 'SKUEL40102017',
  },
  {
    itemName: 'Air Purifier',
    category: 'E-waste',
    itemId: 'SKUEL40102018',
  },
  {
    itemName: 'Video Game Console',
    category: 'E-waste',
    itemId: 'SKUEL40102019',
  },
  {
    itemName: 'Projector',
    category: 'E-waste',
    itemId: 'SKUEL40102020',
  },
  {
    itemName: 'Cloth Dryer',
    category: 'E-waste',
    itemId: 'SKUEL40102021',
  },
  {
    itemName: 'Exhaust Fan/Hood',
    category: 'E-waste',
    itemId: 'SKUEL40102022',
  },
  {
    itemName: 'Humidifier',
    category: 'E-waste',
    itemId: 'SKUEL40102023',
  },
  {
    itemName: 'Inverter',
    category: 'E-waste',
    itemId: 'SKUEL40102024',
  },
  {
    itemName: 'Incubator',
    category: 'E-waste',
    itemId: 'SKUEL40102025',
  },
  {
    itemName: 'Lab Motors',
    category: 'E-waste',
    itemId: 'SKUEL40102026',
  },
  {
    itemName: 'Cash Counting Machine',
    category: 'E-waste',
    itemId: 'SKUEL40102027',
  },
  {
    itemName: 'Bar Code Printer',
    category: 'E-waste',
    itemId: 'SKUEL40102028',
  },
  {
    itemName: 'Spike Guard',
    category: 'E-waste',
    itemId: 'SKUEL40102029',
  },
  {
    itemName: 'Mixed Electrical',
    category: 'E-waste',
    itemId: 'SKUEL40102030',
  },
  {
    itemName: 'Air Cooler',
    category: 'E-waste',
    itemId: 'SKUHA40103001',
  },
  {
    itemName: 'Air Conditioners - Split',
    category: 'E-waste',
    itemId: 'SKUHA40103002',
  },
  {
    itemName: 'Air Conditioners - Window',
    category: 'E-waste',
    itemId: 'SKUHA40103003',
  },
  {
    itemName: 'Refrigerator - Single Door',
    category: 'E-waste',
    itemId: 'SKUHA40103004',
  },
  {
    itemName: 'Refrigerator - Double Door',
    category: 'E-waste',
    itemId: 'SKUHA40103005',
  },
  {
    itemName: 'Television',
    category: 'E-waste',
    itemId: 'SKUHA40103006',
  },
  {
    itemName: 'Washing Machine',
    category: 'E-waste',
    itemId: 'SKUHA40103007',
  },
  {
    itemName: 'Microwave Oven',
    category: 'E-waste',
    itemId: 'SKUHA40103008',
  },
  {
    itemName: 'Pressure Cookers',
    category: 'E-waste',
    itemId: 'SKUKA40104001',
  },
  {
    itemName: 'Gas Stove (Iron Body)',
    category: 'E-waste',
    itemId: 'SKUKA40104002',
  },
  {
    itemName: 'Gas Stove (Stainless Steel)',
    category: 'E-waste',
    itemId: 'SKUKA40104003',
  },
  {
    itemName: 'Table Top Wet Grinder',
    category: 'E-waste',
    itemId: 'SKUKA40104004',
  },
  {
    itemName: 'Mixer Grinder',
    category: 'E-waste',
    itemId: 'SKUKA40104005',
  },
  {
    itemName: 'Toasters',
    category: 'E-waste',
    itemId: 'SKUKA40104006',
  },
  {
    itemName: 'Oven/Toaster/Griller',
    category: 'E-waste',
    itemId: 'SKUKA40104007',
  },
  {
    itemName: 'Food Processor',
    category: 'E-waste',
    itemId: 'SKUKA40104008',
  },
  {
    itemName: 'Water Purifier',
    category: 'E-waste',
    itemId: 'SKUKA40104009',
  },
  {
    itemName: 'Electric Chimney',
    category: 'E-waste',
    itemId: 'SKUKA40104010',
  },
  {
    itemName: 'Blenders & Choppers',
    category: 'E-waste',
    itemId: 'SKUKA40104011',
  },
  {
    itemName: 'Dishwasher',
    category: 'E-waste',
    itemId: 'SKUKA40104012',
  },
  {
    itemName: 'Cooking Ranges',
    category: 'E-waste',
    itemId: 'SKUKA40104013',
  },
  {
    itemName: 'Popcorn Maker',
    category: 'E-waste',
    itemId: 'SKUKA40104014',
  },
  {
    itemName: 'Electric Cooker',
    category: 'E-waste',
    itemId: 'SKUKA40104015',
  },
  {
    itemName: 'Coffeemaker/Kettle',
    category: 'E-waste',
    itemId: 'SKUKA40104016',
  },
  {
    itemName: 'Electric Kettle',
    category: 'E-waste',
    itemId: 'SKUKA40104017',
  },
  {
    itemName: 'Induction Cooktop',
    category: 'E-waste',
    itemId: 'SKUKA40104018',
  },
  {
    itemName: 'Air Fryer',
    category: 'E-waste',
    itemId: 'SKUKA40104019',
  },
  {
    itemName: 'Water Cooler',
    category: 'E-waste',
    itemId: 'SKUKA40104020',
  },
  {
    itemName: 'Mixed Dry Waste',
    category: 'Others',
    itemId: 'SKUMIS501001',
  },
  {
    itemName: 'Other Electronics',
    category: 'Others',
    itemId: 'SKUMIS501002',
  },
  {
    itemName: 'Bags',
    category: 'Others',
    itemId: 'SKUMIS501003',
  },
  {
    itemName: 'Cloth (Chindi)',
    category: 'Others',
    itemId: 'SKUMIS501004',
  },
  {
    itemName: 'Gunny Bags',
    category: 'Others',
    itemId: 'SKUMIS501005',
  },
  {
    itemName: 'Tyres',
    category: 'Others',
    itemId: 'SKUMIS501006',
  },
  {
    itemName: 'Wooden Scrap',
    category: 'Others',
    itemId: 'SKUMIS501007',
  },
  {
    itemName: 'Shoe Sole',
    category: 'Others',
    itemId: 'SKUMIS501008',
  },
  {
    itemName: 'Rubber',
    category: 'Others',
    itemId: 'SKUMIS501009',
  },
  {
    itemName: 'Lead Acid Battery',
    category: 'Others',
    itemId: 'SKUMIS501010',
  },
  {
    itemName: 'Rejected',
    category: 'Others',
    itemId: 'SKUMIS50101008',
  },
  {
    itemName: 'Leather items',
    category: 'Others',
    itemId: 'SKUMIS501011',
  },
  {
    itemName: 'Butel Tube',
    category: 'Others',
    itemId: 'SKUMIS501012',
  },
  {
    itemName: 'Natural Tube',
    category: 'Others',
    itemId: 'SKUMIS501013',
  },
  {
    itemName: 'Tyre Center',
    category: 'Others',
    itemId: 'SKUMIS501014',
  },
  {
    itemName: 'Tyre Side',
    category: 'Others',
    itemId: 'SKUMIS501015',
  },
  {
    itemName: 'Rubber Dust',
    category: 'Others',
    itemId: 'SKUMIS501016',
  },
  {
    itemName: 'Styrofoam',
    category: 'Others',
    itemId: 'SKUMIS501017',
  },
  {
    itemName: 'EPE Foam',
    category: 'Others',
    itemId: 'SKUMIS501018',
  },
  {
    itemName: 'CFLs/Tubelights',
    category: 'Others',
    itemId: 'SKUMIS501019',
  },
  {
    itemName: 'Shoe',
    category: 'Others',
    itemId: 'SKUMIS501020',
  },
  {
    itemName: 'Refuse-Derived Fuel (RDF)',
    category: 'Others',
    itemId: 'SKUMIS501021',
  },
  {
    itemName: 'Tube',
    category: 'Others',
    itemId: 'SKUMIS501022',
  },
  {
    itemName: 'Mix Scrap',
    category: 'Others',
    itemId: 'SKUMIS501023',
  },
  {
    itemName: 'Curtains Blinds Scrap',
    category: 'Others',
    itemId: 'SKUMIS501024',
  },
  {
    itemName: 'Tetra Pak Packaging',
    category: 'Others',
    itemId: 'SKUMIS50111',
  },
  {
    itemName: 'Wooden Items',
    category: 'Others',
    itemId: 'SKUMIS50117',
  },
  {
    itemName: 'Aluminium Coated Plastic',
    category: 'Others',
    itemId: 'SKUMIS50118',
  },
  {
    itemName: 'Cloth Bag',
    category: 'Others',
    itemId: 'SKUMIS50119',
  },
  {
    itemName: 'Thermocol',
    category: 'Others',
    itemId: 'SKUPS10106002',
  },
  {
    itemName: 'Red Bag',
    category: 'Others',
    itemId: 'SKUCOV601001',
  },
  {
    itemName: 'Yellow Bag',
    category: 'Others',
    itemId: 'SKUCOV601002',
  },
  {
    itemName: 'Blue Bag',
    category: 'Others',
    itemId: 'SKUCOV601003',
  },
  {
    itemName: 'White Bag',
    category: 'Others',
    itemId: 'SKUCOV601004',
  },
];

export const BarChartColors = [
  '#ED9714',
  '#F6E05E',
  '#68D391',
  '#4FD1C5',
  '#63B3ED',
  '#7F9CF5',
  '#F687B3',
];

export const InwardChartColors = ['#4FD1C5', '#E09D00'];

export const OutwardChartColors = ['#78D58E', '#BFAEAE', '#EC6666'];

export const LineChartViewTypeList = [
  { name: 'Sales Value Vs Purchase Value', id: 'saleValueVsPurchaseValue' },
  { name: 'Average Sales Rate  Vs Average Purchase Rate', id: 'saleAvgVsPurchaseAvg' },
  { name: 'Sales Quantity Vs Purchase Quantity', id: 'saleQtyVsPurchaseQty' },
];

export const TimelineList = [
  { name: 'By Month', id: 'month' },
  { name: 'By Week', id: 'week' },
  { name: 'By Day', id: 'day' },
];

export const transactions = [
  { id: 'MRR', name: 'MRR' },
  { id: 'SALES', name: 'Sales' },
  { id: 'ADD_CASH', name: 'Add Cash' },
  { id: 'ADVANCE', name: 'Advance' },
  { id: 'FUEL', name: 'Fuel' },
  { id: 'PICKUP', name: 'Pickup' },
  { id: 'MISC', name: 'Others' },
];

export const COLLECTION_TYPE = {
  HOUSE: 'HOUSES',
  COMMERCIAL: 'COMMERCIAL',
};

export const DATE_KEYS = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const CashTypes = [
  { name: 'Advance Payment', id: 'ADVANCE' },
  { name: 'Cash Added', id: 'CASH_ADDED' },
  { name: 'Fuel Expense', id: 'EXPENSE' },
  { name: 'MRR', id: 'MRR' },
  { name: 'Miscellaneous', id: 'MISC' },
];

export const EMP_TYPE = {
  CONTRACT: 'CONTRACT',
  PERMANENT: 'PERMANENT',
};

export const ORDER_TYPE_MAP = {
  DROP: { id: 'DROP', title: 'Drop' },
  PICKUP: { id: 'PICKUP', title: 'Pickup' },
  BAGS: { id: 'BAGS', title: 'Smart Skan' },
};

export const OUTBOUND_ORDER_STATUS_MAP = {
  IN_REVIEW: { id: 'IN_REVIEW', title: 'Confirm In-Review' },
  CREATED: { id: 'CREATED', title: 'Created' },
  VERIFIED: { id: 'VERIFIED', title: 'Verified' },
  REJECTED: { id: 'REJECTED', title: 'Rejected' },
  PICKUP_SCHEDULED: { id: 'PICKUP_SCHEDULED', title: 'Dispatched' },
  MATERIAL_RECEIVED: { id: 'MATERIAL_RECEIVED', title: 'Delivered' },
  APPROVAL_PENDING: { id: 'APPROVAL_PENDING', title: 'Approval Pending' },
  AWAITING_PICKUP_CONFIRMATION: { id: 'AWAITING_PICKUP_CONFIRMATION', title: '' },
  ORDER_COMPLETED: { id: 'ORDER_COMPLETED', title: 'Delivered' },
  PICKUP_DENIED: { id: 'PICKUP_DENIED', title: '' },
  CANCELLED: { id: 'CANCELLED', title: 'Cancelled' },
  HOLD: { id: 'HOLD', title: 'On-Hold' },
  REVERSED: { id: 'REVERSED', title: 'Reversed' },
  SETTLED: { id: 'SETTLED', title: 'Settled' },
  MATERIAL_PICKED: { id: 'MATERIAL_PICKED', title: 'Picked' },
  OUT_FOR_PICKUP: { id: 'OUT_FOR_PICKUP', title: 'Out For Pickup' },
  REACHED_LOCATION: { id: 'REACHED_LOCATION', title: 'Reached to Location' },
  TO_BE_RESCHEDULED: { id: 'TO_BE_RESCHEDULED', title: 'To be Rescheduled' },
  MATERIAL_LOADED: { id: 'MATERIAL_LOADED', title: 'Material Loaded' },
  PART_PAYMENT_DONE: { id: 'PART_PAYMENT_DONE', title: 'Payment Done' },
  SETTLEMENT_DONE: { id: 'SETTLEMENT_DONE', title: 'Final Settlement Done' },
};

export const INBOUND_ORDER_STATUS_MAP = {
  IN_REVIEW: { id: 'IN_REVIEW', title: 'Confirm In-Review' },
  CREATED: { id: 'CREATED', title: 'Created' },
  VERIFIED: { id: 'VERIFIED', title: 'Verified' },
  REJECTED: { id: 'REJECTED', title: 'Rejected' },
  PICKUP_SCHEDULED: { id: 'PICKUP_SCHEDULED', title: 'Scheduled' },
  MATERIAL_RECEIVED: { id: 'MATERIAL_RECEIVED', title: 'Received' },
  APPROVAL_PENDING: { id: 'APPROVAL_PENDING', title: 'Approval Pending' },
  AWAITING_PICKUP_CONFIRMATION: { id: 'AWAITING_PICKUP_CONFIRMATION', title: '' },
  ORDER_COMPLETED: { id: 'ORDER_COMPLETED', title: 'Completed' },
  PICKUP_DENIED: { id: 'PICKUP_DENIED', title: '' },
  CANCELLED: { id: 'CANCELLED', title: 'Cancelled' },
  HOLD: { id: 'HOLD', title: 'On-Hold' },
  REVERSED: { id: 'REVERSED', title: 'Reversed' },
  SETTLED: { id: 'SETTLED', title: 'Settled' },
  MATERIAL_PICKED: { id: 'MATERIAL_PICKED', title: 'Picked' },
  OUT_FOR_PICKUP: { id: 'OUT_FOR_PICKUP', title: 'Out For Pickup' },
  REACHED_LOCATION: { id: 'REACHED_LOCATION', title: 'Reached to Location' },
  TO_BE_RESCHEDULED: { id: 'TO_BE_RESCHEDULED', title: 'To be Rescheduled' },
  MATERIAL_LOADED: { id: 'MATERIAL_LOADED', title: 'Material Loaded' },
  PART_PAYMENT_DONE: { id: 'PART_PAYMENT_DONE', title: 'Payment Done' },
  SETTLEMENT_DONE: { id: 'SETTLEMENT_DONE', title: 'Final Settlement Done' },
};

export const ID_PROOF_TYPES = [
  { id: 'AADHAR_CARD', name: 'Aadhar Card' },
  { id: 'PAN_CARD', name: 'PAN Card' },
  { id: 'PASSPORT', name: 'Passport' },
  { id: 'VOTER_ID', name: 'Voter ID' },
];

export const ORDER_CANCEL_REASONS = [
  'We could not connect with the customer',
  'Customer wants to give more items',
  'Customer is looking for better rates',
  'Customer was just testing the app',
  'Customer requested for cancellation',
  'Customer is not interested anymore',
  'Others',
];

export const ORDER_HOLD_REASONS = [
  'We could not connect with the customer',
  'Customer requested to put on hold since (s)he was not available',
  'Our pickup executive was at your location and could not contact you',
  'Customer wanted to give more items and requested to come later',
  'Others',
];

export const DOC_PREFIX = { AGGR: 'AGGR', RCYCL: 'RCYL', TRANS: 'TRANS', ACCNT: 'ACCNT' };

export const MARITAL_STATUSES = [
  { name: 'Single', id: 'SINGLE' },
  { name: 'Married', id: 'MARRIED' },
  { name: 'Divorced', id: 'DIVORCED' },
  { name: 'Widow', id: 'WIDOW' },
];

export const RELATIONS_LIST = [
  { name: 'Father', id: 'Father' },
  { name: 'Mother', id: 'Mother' },
  { name: 'Spouse', id: 'Spouse' },
  { name: 'Son', id: 'Son' },
  { name: 'Daughter', id: 'Daughter' },
];

export const GENDER_OPTIONS = [
  { name: 'Male', id: 'Male' },
  { name: 'Female', id: 'Female' },
  { name: 'Other', id: 'Other' },
];

export const HOLIDAYS_LIST = [
  { name: 'Festival', id: 'Festival' },
  { name: 'National', id: 'National' },
  { name: 'Weekly', id: 'Weekly' },
  { name: 'Sick Leaves', id: 'Sick Leaves' },
  { name: 'NA', id: 'NA' },
];

export const WEIGHT_QUANTITIES = [
  { name: '0 - 10 Kg', id: '0-10' },
  { name: '10 - 25 Kg', id: '10-25' },
  { name: '25 - 50 Kg', id: '25-50' },
  { name: '50 - 75 Kg', id: '50-75' },
  { name: '75 - 100 Kg', id: '75-100' },
  { name: '> 100 Kg', id: '100-1000' },
];

export const BUSINESS_TYPE = [
  {
    name: 'Commercial Establishments',
    id: '25',
  },
  { name: 'Gated Residential Communities, RWA', id: '2' },
  { name: 'Corporate Offices', id: '4' },
  { name: 'Hotels & Restaurants', id: '3' },
  { name: 'Educational Institutes', id: '5' },
  { name: 'Others', id: '11' },
];
