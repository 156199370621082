/* eslint-disable max-len */
import React from 'react';

const CatalogIcon = () => {
  return (
    <svg width="20" height="26" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.72726C0 1.22103 1.1939 0 2.66665 0H15.1112C15.6021 0 16 0.407102 16 0.90919C16 1.41128 15.6021 1.81822 15.1112 1.81822H2.66665C2.17572 1.81822 1.77782 2.22517 1.77782 2.72726C1.77782 3.22935 2.17572 3.63645 2.66665 3.63645H13.3333C14.806 3.63645 15.9999 4.85748 15.9999 6.36371V17.2727C15.9999 18.779 14.806 20 13.3333 20H4.44455C1.98993 20 0 17.9649 0 15.4544V2.72726ZM1.77782 15.4546C1.77782 16.9608 2.97172 18.1819 4.44447 18.1819H13.3332C13.8241 18.1819 14.222 17.7749 14.222 17.2728V6.36378C14.222 5.8617 13.8241 5.45459 13.3332 5.45459H2.66658C2.35497 5.45459 2.05572 5.39996 1.77774 5.29951L1.77782 15.4546Z"
      />
    </svg>
  );
};

export default CatalogIcon;
