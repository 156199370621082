import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Loader } from '../shared/components';

const PageContainer = (props) => {
  const { children, className = '', loading } = props;
  return (
    <Card
      className={classNames('PageContainer animated fadeIn', className, {
        'justify-content-center': loading,
      })}>
      {loading ? <Loader /> : children}
    </Card>
  );
};

PageContainer.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.element,
  className: PropTypes.string,
};

export default PageContainer;
